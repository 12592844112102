import React from 'react';
import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { Button, Icon } from 'semantic-ui-react';

const Count = styled.div`
    display: inline-block;
    text-align: center;
    padding: 0 10px;
`;

const StyledButton = styled(Button)`
    &&&& {
        background-color: ${(props) => (props.disabled ? 'none' : '#fff !important')};
        height: 32px;
        width: 32px;
        padding: 0;
    }
`;

const StyledIcon = styled(Icon)`
    margin: 0 !important;
`;

interface Props {
    value?: number;
    min?: number;
    max?: number;
    onDecrement?: (e: MouseEvent) => void;
    onIncrement?: (e: MouseEvent) => void;
}

const Counter: React.FC<Props> = (props: Props) => {
    const { value, min, max, ...rest } = props;
    return (
        <Flex alignItems="center" {...rest}>
            <StyledButton
                data-testid="decrement"
                type="Button"
                icon={<i className='vismaicon vismaicon-sm vismaicon-dynamic vismaicon-remove-circle icon-neutral ' aria-hidden='true' />}
                size="tiny"
                disabled={typeof min !== 'undefined' && (!value || value <= min)}
                basic
                onClick={props.onDecrement}
            >
                <StyledIcon name="minus" />
            </StyledButton>
            <Count>{value}</Count>
            <StyledButton
                data-testid="increment"
                type="Button"
                size="tiny"
                disabled={
                    typeof max !== 'undefined' && typeof value !== 'undefined' && value >= max
                }
                basic
                onClick={props.onIncrement}
            >
                <StyledIcon name="plus" />
            </StyledButton>
        </Flex>
    );
};

export default Counter;
