import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { Flex } from 'grid-styled';
import { ApplicationState } from '@/store';
import { compose } from 'redux';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';

interface CorrelationIdsProps extends LocalizeContextProps,ConnectedProps<typeof connector> {}

const CorrelationIds: React.FC<CorrelationIdsProps> = ({ correlationIds, translate }) => {
    const [errorExpanded, setErrorExpanded] = useState(false);
    const toggleExpanded = () => setErrorExpanded(!errorExpanded);

    return correlationIds.length > 0 ? (
        <>
            <p onKeyDown={toggleExpanded} onClick={toggleExpanded} style={{ cursor: 'pointer' }}>
                {translate('correlationIds.clickToShow')}{' '}
                <Icon name={errorExpanded ? 'chevron up' : 'chevron down'} />
            </p>
            {errorExpanded ? (
                <p className="small">
                    <ul>
                        {correlationIds.map((c, i) => {
                            return (
                                <li key={i}>
                                    <Flex style={{ flexDirection: 'column' }}>
                                        <div>Correlation id: {c.correlationId}</div>
                                        <div>
                                            endpoint: {c.endpoint?.method.toUpperCase()}{' '}
                                            {c.endpoint?.url}
                                        </div>
                                    </Flex>
                                </li>
                            );
                        })}
                    </ul>
                </p>
            ) : null}
        </>
    ) : null;
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        correlationIds: state.correlationId.entity,
    };
};
export const connector = connect(mapStateToProps);

export default compose(
    connector,
    withLocalize,
)(CorrelationIds) as React.FC;
