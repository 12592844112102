import { combineReducers, AnyAction } from 'redux';
import { createLoadingReducer, createListLoadingReducer, listInitialState  } from './utils';
import * as actions from '../actions';
import reduceReducers from 'reduce-reducers';


const initialState = {
    customerData: { ...listInitialState },
    inactiveCustomers: {}
}

const reducer = (state = listInitialState, action: AnyAction) => {
    switch (action.type) {
        case actions.DELETE_INACTIVE_CUSTOMERS_IN_PROGRESS: {
            return {
                ...state,
                inactiveCustomers: {
                    // @ts-ignore
                    ...state.inactiveCustomers,
                    isLoading: true,
                    isLoaded: false,
                    hasError: false
                }
            };
        }
        default:
            return state;
    }
}

const combinedReducers =  combineReducers({
    customerData: createLoadingReducer('GDPR'),
    inactiveCustomers: createListLoadingReducer('INACTIVE_CUSTOMERS'),
})

export default reduceReducers<any>(initialState, combinedReducers, reducer );

