import { createSlice, PayloadAction, AnyAction, createListenerMiddleware, isAnyOf, TypedStartListening } from '@reduxjs/toolkit';
import { createLoadingReducer, createEntityState } from './utils';
import * as actions from '../actions';
import reduceReducers from 'reduce-reducers';
import { ArticlePrice, CreateInvoiceResponse, Price, ServicePriceMapping, UpdateEaccountingSettingsParams } from '@/types';
import { produce } from 'immer'
import bmApi from '@/services/bmApi';
import { setSubmitFailed, stopSubmit, startSubmit, setSubmitSucceeded, getFormValues } from 'redux-form';
import { convertErrorToFormError } from '@/utils';
import { ApplicationDispatch, ApplicationState } from '@/store';
import { getCompanyId } from './newAuth';
import Notifications from 'react-notification-system-redux';
import { getTranslate } from 'react-localize-redux';

import { generateContentForModals } from '@/utils/generateContentForModals';

// TODO: register separate listeners instead of registering then all at once

export const eaccountingListener = createListenerMiddleware();

export type EaccountingStartListening = TypedStartListening<ApplicationState, ApplicationDispatch>

const startEaccountiningListening = eaccountingListener.startListening as EaccountingStartListening;

export type UpdatedPrices = {
    entities: ArticlePrice[],
    changed: boolean
}

const eaccountingSlice = createSlice({
    name: 'eaccounting',
    initialState: {
        auth: false,
        createInvoice: {
            modal: false,
            bookingId: 0,
            data: null as CreateInvoiceResponse | null
        },
        // TODO: how to model the eaccounting slice so that it has the response of the create too?
        updatePrices: {
            entities: [] as ArticlePrice[],
            changed: false
        } as UpdatedPrices,
    },
    reducers: {
        // @ts-ignore
        mapArticlePrices: reduceReducers(
            createEntityState([]),
            createLoadingReducer('EACCOUNTING_PRICE_MAPPINGS'),
            (state: any, action: AnyAction) => {
                switch (action.type) {
                    case actions.removePriceArticleMapping.type: {
                        const { entities, priceId } = action.payload;

                        const data = produce(entities, (draftState) => {
                            const index = entities.findIndex(
                                (e: ServicePriceMapping) => e.PriceId == priceId
                            );
                            draftState.splice(index, 1);
                        });

                        return {
                            ...state,
                            data,
                        };
                    }
                    default:
                        return state;
                }
            }
        ),
        updatePrices: (state, action: PayloadAction<ArticlePrice[]>) => {
            state.updatePrices.entities = action.payload;

            state.updatePrices.changed = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actions.resetUpdatedPrices, (state) => {
            state.updatePrices.changed = false;
            state.updatePrices.entities = [];
        });

        builder.addCase(actions.startInvoiceCreate, (state, action) => {
            state.createInvoice.modal = true;
            state.createInvoice.bookingId = action.payload;
        });

        builder.addCase(actions.CREATE_EACCOUNTING_INVOICE_CANCEL, (state) => {
            state.createInvoice.modal = false;
            state.createInvoice.bookingId = 0;
        });

        builder.addCase(actions.fullfillnvoiceCreate, (state) => {
            state.createInvoice.modal = false;
            state.createInvoice.bookingId = 0;
        });

        builder.addMatcher(bmApi.endpoints.createEaccountingInvoice.matchFulfilled, (state, action) => {
            state.createInvoice.data = action.payload;
            state.createInvoice.modal = false;
        });

        builder.addMatcher(bmApi.endpoints.createEaccountingInvoiceDraft.matchFulfilled, (state, action) => {
            // NOTE: consider showing draft invoice?
            state.createInvoice.modal = false;
        });

        builder.addMatcher(actions.hideInvoiceSuccessModal.match, (state) => {
            state.createInvoice.data = null;
        });

        builder.addMatcher(bmApi.endpoints.checkEaccountingAuth.matchFulfilled, (state) => {
            state.auth = true;
        });

        builder.addMatcher(bmApi.endpoints.eaccountingLogout.matchFulfilled, (state) => {
            state.auth = false;
        });
    }
});


// Update eaccounting settings
startEaccountiningListening({
    matcher: isAnyOf(
        bmApi.endpoints.updateEaccountingSettings.matchPending,
        bmApi.endpoints.updateEaccountingSettings.matchFulfilled,
        bmApi.endpoints.updateEaccountingSettings.matchRejected,
        // @ts-ignore
        (a) => actions.updateEaccountingSettings.REQUEST.match(a.type),
    ),
    effect: async (action, api) => {
        if(actions.updateEaccountingSettings.REQUEST.match(action.type)) {
            const formValues = getFormValues('easettings')(api.getState()) as UpdateEaccountingSettingsParams;
            const companyId = api.getState().company.data.Id;

            api.dispatch(bmApi.endpoints.updateEaccountingSettings.initiate({
                // @ts-ignore
                EAccountingIntegrationActive: formValues.Active,
                CompanyId: companyId,
                DefaultCreateType: formValues.DefaultCreateType,
                DefaultArticle6PercentVat: formValues.DefaultArticle6PercentVat,
                DefaultArticle12PercentVat: formValues.DefaultArticle12PercentVat,
                DefaultArticle25PercentVat: formValues.DefaultArticle25PercentVat,
                DefaultTermsOfPaymentId: formValues.DefaultTermsOfPaymentId,
            }));
        }

        if(bmApi.endpoints.updateEaccountingSettings.matchFulfilled(action)) {
            api.dispatch(actions.updateEaccountingSettings.success(action.payload));
        }

        if(bmApi.endpoints.updateEaccountingSettings.matchRejected(action)) {
            api.dispatch(actions.updateEaccountingSettings.failure(action.payload));
        }
    },
});

// We want to make sure to fetch the eaccounting settings after logout
startEaccountiningListening({
    matcher: isAnyOf(
        bmApi.endpoints.eaccountingLogout.matchFulfilled
    ),
    effect: async (action, api) => {
        if(bmApi.endpoints.eaccountingLogout.matchFulfilled(action)) {
            api.dispatch(bmApi.endpoints.getEaccountingSettings.initiate({}));
        }

    },
});

// price article mappings
startEaccountiningListening({
    matcher: isAnyOf(
        // @ts-ignore
        (a) => actions.POST_EACCOUNTING_PRICES.REQUEST.match(a.type),
    ),
    effect: async (action, api) => {
        api.dispatch(bmApi.util.invalidateTags(['ServicePriceMappings']));
    },
});

startEaccountiningListening({
    matcher: isAnyOf(
        bmApi.endpoints.createEaccountingInvoice.matchPending,
        bmApi.endpoints.createEaccountingInvoice.matchFulfilled,
        bmApi.endpoints.createEaccountingInvoice.matchRejected,
        bmApi.endpoints.createEaccountingInvoiceDraft.matchPending,
        bmApi.endpoints.createEaccountingInvoiceDraft.matchFulfilled,
        bmApi.endpoints.createEaccountingInvoiceDraft.matchRejected,
        // TODO: write a typesafe matcher
        // @ts-ignore
        (a) => a.type === actions.UPDATE_EACCOUNTING_PRICE_EXTERNAL_REF,
        (a) => a.type === actions.POST_EACCOUNTING_PRICES.REQUEST,
        bmApi.endpoints.updateEaccountingPriceArticleMappings.matchRejected,
        bmApi.endpoints.updateEaccountingPriceArticleMappings.matchFulfilled,
        actions.removePriceArticleMapping.match,
        actions.hideInvoiceSuccessModal,
        // actions.updateEaccountingSettings,
        bmApi.endpoints.deletePriceArticleMapping.matchRejected,
        bmApi.endpoints.deletePriceArticleMapping.matchFulfilled,
    ),
    effect: async (action, api) => {
        const companyId = getCompanyId(api.getState());
        const translate = getTranslate(api.getState().localize);

        if(actions.removePriceArticleMapping.match(action)) {
            let { data: prices } = bmApi.endpoints.getEaccountingPriceMappings.select({CompanyId: companyId})(
                api.getState() as ApplicationState
            );

            
            const updatedPrices = api.getState().eaccounting.updatePrices.entities;


            const updatedPricesForDeletionIndex = updatedPrices
                // @ts-ignore
                .findIndex(p => p.PriceId == action.payload);
            const priceForDeletion = prices &&  prices.find(p => p.PriceId == action.payload);


            if(priceForDeletion?.Id) {
                api.dispatch(bmApi.endpoints.deletePriceArticleMapping.initiate(priceForDeletion.Id))
            } else if (updatedPricesForDeletionIndex !== -1) {
                let _updatedPrices = [...updatedPrices];
                
                _updatedPrices.splice(updatedPricesForDeletionIndex, 1);
                
                api.dispatch(updatePrices(_updatedPrices));
            } else {
                const state = api.getState() as ApplicationState;
                // @ts-ignore
                const updatedPrices: ServicePriceMapping[] = state.eaccounting.updatePrices.entities

                api.dispatch(eaccountingSlice.actions.updatePrices(
                    // @ts-ignore
                    updatedPrices.filter(up => up.PriceId != action.payload)
                ));

                api.dispatch(actions.resetUpdatedPrices());
            }
        }
        
        if(bmApi.endpoints.deletePriceArticleMapping.matchRejected(action)) {
            api.dispatch(Notifications.show({
                title: action.error.message
            }, 'error'));
        }

        if(bmApi.endpoints.deletePriceArticleMapping.matchFulfilled(action)) {
            api.dispatch(Notifications.show({
                title: translate('form.submitSuccessMessage') as string
            }, 'success'));
        }

        if(bmApi.endpoints.updateEaccountingPriceArticleMappings.matchFulfilled(action)) {
            api.dispatch(Notifications.show({
                title: translate('form.submitSuccessMessage') as string
            }, 'success'));

            api.dispatch(actions.resetUpdatedPrices());
        }

        if(bmApi.endpoints.updateEaccountingPriceArticleMappings.matchRejected(action)) {
            let error: any = action.payload?.status.toString();
            if(action.payload?.data) {
                error = convertErrorToFormError({ response: { data: action.payload.data } })._error;
            } 

            api.dispatch(Notifications.show({
                title: typeof error === 'string' ? error : error.message
            }, 'error'));
        }

        if(action.type === actions.POST_EACCOUNTING_PRICES.REQUEST) {
            const updatedPrices: ServicePriceMapping[] = action.payload;

            const serviceMappings: ServicePriceMapping[] = updatedPrices
            .filter(p => p.PriceId)
            .map(p => ({
                CompanyId: companyId,
                ExternalReference: p.ExternalReference,
                ReferenceType: 'EAccountingArticle',
                Id: '',
                PriceId: p.PriceId
            }));

            api.dispatch(bmApi.endpoints.updateEaccountingPriceArticleMappings.initiate({
                CompanyId: companyId,
                ServicePriceMappings: serviceMappings
            }))
        }

        if(action.type === actions.UPDATE_EACCOUNTING_PRICE_EXTERNAL_REF) {
            const state = api.getState() as ApplicationState;

            let { data: prices } = bmApi.endpoints.getEaccountingPriceMappings.select({CompanyId: companyId})(
                api.getState() as ApplicationState
            );

            if(state.eaccounting.updatePrices.entities.length > 0) {
                // @ts-ignore
                prices = state.eaccounting.updatePrices.entities;
            }

            if(prices && prices.find(p => p.PriceId === action.payload.Id)) {
                const updatedPrices = prices.map((p) => {
                    if(p.PriceId == action.payload.Id) {
                        return {
                            ...p,
                            ExternalReference: action.payload.ExternalReference
                        }
                    }

                    return p;
                });

                // @ts-ignore
                api.dispatch(eaccountingSlice.actions.updatePrices(updatedPrices));
            } else if (prices) {
                const payload: Price & { ExternalReference: string } = action.payload;
                // payload is price, and should be ServicePriceMapping
                const servicePriceMapping: ServicePriceMapping = {
                    CompanyId: payload.CompanyId,
                    ExternalReference: payload.ExternalReference,
                    PriceId: payload.Id,
                    Id: '',
                    ReferenceType: 'EAccountingArticle'
                }

                const updatedPrices = [...prices, servicePriceMapping];
                // @ts-ignore
                api.dispatch(eaccountingSlice.actions.updatePrices(updatedPrices));
            }
        }

        if (bmApi.endpoints.createEaccountingInvoice.matchPending(action)) {
            api.dispatch(startSubmit('createInvoice'));
        } else if(bmApi.endpoints.createEaccountingInvoice.matchFulfilled(action)) {
            const translate = getTranslate(api.getState().localize)
            
            api.dispatch(stopSubmit('createInvoice'));
            api.dispatch(setSubmitSucceeded('createInvoice'));
            api.dispatch(actions.fullfillnvoiceCreate());
            api.dispatch(Notifications.show({
                title: translate('createInvoice.successfullyCreatedTheInvoice') as string
            }, 'success'));
            
            api.dispatch(actions.showInvoiceCreateModal());
            api.dispatch(actions.FETCH_BOOKING_DETAILS.request({ Id: action.payload.Invoice.BookingId }));
        } else if (bmApi.endpoints.createEaccountingInvoice.matchRejected(action) && action.payload) {
            // TODO: add 426 to show what the api client is showing
            const formError = convertErrorToFormError({ response: { data: action.payload.data } });
    
            api.dispatch(stopSubmit('createInvoice', formError));
            api.dispatch(setSubmitFailed('createInvoice'));
        } else if (bmApi.endpoints.createEaccountingInvoiceDraft.matchPending(action)) {
            api.dispatch(startSubmit('createInvoice'));
        } else if(bmApi.endpoints.createEaccountingInvoiceDraft.matchFulfilled(action)) {
            api.dispatch(stopSubmit('createInvoice'));
            api.dispatch(setSubmitSucceeded('createInvoice'));
            api.dispatch(actions.fullfillnvoiceCreate());
            api.dispatch(Notifications.show({
                title: translate('createInvoice.successfullyCreatedTheInvoice') as string
            }, 'success'));    
        } else if (bmApi.endpoints.createEaccountingInvoiceDraft.matchRejected(action) && action.payload) {
            const formError = convertErrorToFormError({ response: { data: action.payload.data } });
    
            api.dispatch(stopSubmit('createInvoice', formError));
            api.dispatch(setSubmitFailed('createInvoice'));
        }
    },
});

// Settings form
startEaccountiningListening({
    matcher: isAnyOf(
        bmApi.endpoints.updateEaccountingSettings.matchPending,
        bmApi.endpoints.updateEaccountingSettings.matchFulfilled,
        bmApi.endpoints.updateEaccountingSettings.matchRejected
    ),
    effect: async (action, api) => {
        if (bmApi.endpoints.updateEaccountingSettings.matchPending(action)) {
            api.dispatch(startSubmit('easettings'));
        } else if(bmApi.endpoints.updateEaccountingSettings.matchFulfilled(action)) {
            const translate = getTranslate(api.getState().localize)
            
            api.dispatch(stopSubmit('easettings'));
            api.dispatch(setSubmitSucceeded('easettings'));     
        } else if (bmApi.endpoints.updateEaccountingSettings.matchRejected(action) && action.payload) {
            const formError = convertErrorToFormError({ response: { data: action.payload.data } });
    
            api.dispatch(stopSubmit('easettings', formError));
            api.dispatch(setSubmitFailed('easettings'));
        }
    }
});

// 426 and 402 license modals
startEaccountiningListening({
    matcher: isAnyOf(
        bmApi.endpoints.updateEaccountingPriceArticleMappings.matchRejected,
        bmApi.endpoints.createEaccountingInvoice.matchRejected,
        bmApi.endpoints.createEaccountingInvoiceDraft.matchRejected,
        bmApi.endpoints.updateEaccountingSettings.matchRejected
    ),
    effect: async (action, api) => {
        if(action.payload.status === 426 || action.payload.status === 402) {
            const payload = generateContentForModals(
                action.payload,
                action.payload,
                api.getState()
            );

            api.dispatch({
                type: actions.SHOW_LICENSES_MODAL,
                payload,
                meta: { status: action?.payload?.data?.Header },
            });

        }
    }
});



export const { updatePrices } = eaccountingSlice.actions;

export default eaccountingSlice;