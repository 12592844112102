import React from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { connect, ConnectedProps } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '@/store';
import PageHeader from '@/components/PageHeader';
import HelpButton from '@/components/HelpButton';
import WebhookSettings from './WebhookSettings';
import Endpoints from './Endpoints';
import Messages from './Messages';
import { compose } from 'redux';
import tabsHOC, { tabsHOCProps } from '@/components/tabsHOC';

interface Props extends tabsHOCProps, LocalizeContextProps, RouteComponentProps, ConnectedProps<typeof connector> {

}

const WebhookSettingsLoader: React.FC<Props> = (props) => {
    const { translate, location, handleTabChange } = props;
    const { hash } = location;

    const panes = [
        {
            menuItem: {
                content: <div>{translate('webhookSettings.general')}</div>,
                key: 'general',
            },
            render: () => <Tab.Pane><WebhookSettings {...props} /></Tab.Pane>,
        },
        {
            menuItem: {
                content: <div>{translate('webhookSettings.endpoints')}</div>,
                key: 'endpoints',
            },
            render: () => <Tab.Pane><Endpoints {...props} /></Tab.Pane>,
        },
        {
            menuItem: {
                content: <div>{translate('webhookSettings.messages')}</div>,
                key: 'messages',
            },
            render: () => <Tab.Pane><Messages {...props} /></Tab.Pane>,
        }
    ]

    let activeHashIndex = panes.findIndex((pane) => pane.menuItem.key === hash.slice(1));
    activeHashIndex = activeHashIndex !== -1 ? activeHashIndex : 0;

    return (
        <>
            <PageHeader
                breadcrumbs={[{ to: '/webhook-settings', content: translate('webhookSettings.title') }]}
                description={
                    <div>
                        <HelpButton linkKey="webhookSettings.helpLink" />
                        <div />
                    </div>
                }
            />
            <Tab onTabChange={handleTabChange} panes={panes} activeIndex={activeHashIndex} />
        </>
    );
};

const connector = connect((state: ApplicationState) => {
    return {
        webhookSettings: state.webhookSettings,
    };
});

export default compose(
    connector,
    withLocalize,
    tabsHOC()
)(WebhookSettingsLoader) as typeof WebhookSettingsLoader;
