import { put, select, call } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';

import * as actions from '@/store/actions';
import { getCompanyId } from '@/store/reducers/newAuth';
import { AnyAction } from 'redux';
import { apiClient, throwSubmissionError } from '@/utils';
import { getRangeForLabel, startOfDay, endOfDay, toDate } from '@/utils/datetime';
import exportToXLSX from '@/utils/exportToXLSX';
import downloadPDF from '@/utils/downloadPDF';
import { confirmSaga } from './confirm';
import { AxiosResponse } from 'axios';
import { getAllItems } from '@/utils/common';
import { Booking } from '@/types';
import { addHours } from 'date-fns';
import { RootState } from 'MyTypes';
import { getTranslate } from 'react-localize-redux';

const TIME_OFFSET_TO_UTC = -120;    // in minutes (-120 is finnish time offset to UTC);
                                    // this is what getTimezoneOffset returns
                                    // -120 is UTC+2

export function* bookings({ payload = {}, meta = {} }: AnyAction) {
    try {
            const CompanyId: string = yield select(getCompanyId);
            const localizeState: RootState['localize'] = yield select((state) => state.localize);
            const translate = getTranslate(localizeState);

            let { start: BookingStart, end: BookingEnd } = getRangeForLabel(payload._Date);
            let { start: CreatedFrom, end: CreatedTo } = getRangeForLabel(payload._CreatedAt);

            // Option 1. User can set manual dates
            if (payload.BookingStart) {
                BookingStart = startOfDay(payload.BookingStart);
            }
            if (payload.BookingEnd) {
                BookingEnd = endOfDay(payload.BookingEnd);
            }


            // Option 1. User can set predefined dates (like "Past" or "Today")
            if (payload._Date === 'custom' && payload._CustomDate) {
                const date = toDate(payload._CustomDate);
                BookingStart = startOfDay(date);
                BookingEnd = endOfDay(date);
            }

            if (payload._CreatedAt === 'custom' && payload._CustomCreatedAt) {
                const date = toDate(payload._CustomCreatedAt);

                CreatedFrom = startOfDay(date);
                CreatedTo = endOfDay(date);
            }

            const response: AxiosResponse = yield apiClient.get('/bookings', {
                params: {
                    CompanyId,
                    CompanyBookings: true,
                    IncludeCustomFieldValues: true,
                    IncludeBookedResourceTypes: true,
                    IncludeServiceInformation: true,
                    IncludeCustomerInformation: true,
                    IncludeStatusInformation: true,
                    CreatedFrom,
                    CreatedTo,
                    ...payload,
                    BookingStart,
                    BookingEnd,
                },
            });

            if (meta.toXLSX && meta.columns) {
                const bookings: {total: number, data: Booking[] } = yield select((s: any) => s.bookings);
                if(bookings.total > 300) {
                    yield put(Notifications.show({ title: translate('bookings.pleaseWait') as string}, 'info'));
                }
                yield put({ type: actions.EXPORT_BOOKINGS.REQUEST });
                const allResults: Booking[] = yield getAllItems(response);
                exportToXLSX(response.data && allResults, meta.columns);
                yield put({ type: actions.EXPORT_BOOKINGS.SUCCESS });
            }

            yield put({ type: actions.FETCH_BOOKINGS.SUCCESS, payload: response.data, meta });
        } catch (error) {
        yield put(actions.FETCH_BOOKINGS.failure(error));
    }
}

export function* sortBookings() {
    yield put(actions.FETCH_CUSTOMERS.request());
}

export function* bookingDetails({ payload: { Id } }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/bookings', {
            params: {
                CompanyBookings: true,
                Id,
                IncludeBookedResourceTypes: true,
                IncludeCheckoutLog: true,
                IncludeCustomerInformation: true,
                IncludeCustomFields: true,
                IncludeCustomFieldValues: true,
                IncludePaymentLog: true,
                IncludeQuantityInformation: true,
                IncludeServiceInformation: true,
                IncludeStatusInformation: true,
                IncludeExternalReferences: true
            },
        });

        const obj = response.data.Results[0];

        yield put(actions.FETCH_BOOKING_DETAILS.success(obj));

        if (obj) {
            yield put(
                actions.FETCH_SERVICES.request({
                    Id: obj.Service.Id,
                    IncludeResources: true,
                    IncludePrices: true,
                })
            );

            if (obj.Customer.Id) {
                yield put(
                    actions.FETCH_CUSTOMERS.request({
                        CustomerId: obj.Customer.Id,
                        IncludeComments: true,
                        IncludeCustomFieldValues: true,
                    })
                );
            }
        }
    } catch (error) {
        yield put(actions.FETCH_BOOKING_DETAILS.failure(error));
    }
}

export function* bookingLogs({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/bookinglog', {
            params: payload,
        });

        yield put(actions.FETCH_BOOKING_LOGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_BOOKING_LOGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchBookingReportOptions({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/bookings/reports');

        yield put(actions.FETCH_BOOKING_REPORT_OPTIONS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_BOOKING_REPORT_OPTIONS.failure(throwSubmissionError(error)));
    }
}

export function* printReceipt({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(
            `/bookings/${payload.bookingId}/reports/${payload.reportOptionId}?SendReceiptMethod=2`,
            { responseType: 'blob' }
        );

        downloadPDF(response.data);

        yield put(actions.PRINT_RECEIPT.success());
    } catch (error) {
        yield put(actions.PRINT_RECEIPT.failure(error));
    }
}

export function* sendReceipt({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(
            `/bookings/${payload.bookingId}/reports/${payload.reportOptionId}?SendReceiptMethod=1`,
            { responseType: 'blob' }
        );

        yield put(actions.SEND_RECEIPT.success());
    } catch (error) {
        yield put(actions.SEND_RECEIPT.failure(error));
    }
}

export function* editBooking({ payload }: AnyAction) {
    try {
        const isAllResourcesSelected = payload.Resources && Array.isArray(payload.Resources) ? payload.Resources.filter((resource: any) => resource.ResourceId === 'allResources').length > 0 : false;

        const body = {
            ...payload,
            Resources: {
                AutoSelectNotDefinedResources: isAllResourcesSelected ? true : false,
                Resources:   payload.Resources && Array.isArray(payload.Resources) ? payload.Resources.filter((resource: any) => resource.ResourceId !== 'allResources') : undefined,
            },
            AllowBookingOutsideSchedules: true,
        };

        delete body.AutoSelectNotDefinedResources;

        const response: AxiosResponse = yield apiClient.put(`/bookings/${payload.Id}`, body);

        yield put(actions.EDIT_BOOKING.success(response));
        yield put(actions.FETCH_BOOKING_DETAILS.request({ Id: payload.Id }));
    } catch (error) {
        yield put(actions.EDIT_BOOKING.failure(throwSubmissionError(error)));
    }
}

export function* approveBooking({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(`/bookings/${payload.Id}/approve`, payload);

        yield put(actions.APPROVE_BOOKING.success(response));
        yield put(actions.FETCH_BOOKING_DETAILS.request(payload));
    } catch (error) {
        yield put(actions.APPROVE_BOOKING.failure(throwSubmissionError(error)));
    }
}

export function* declineBooking({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(`/bookings/${payload.Id}/decline`, payload);

        yield put(actions.DECLINE_BOOKING.success(response));
        yield put(actions.FETCH_BOOKING_DETAILS.request(payload));
    } catch (error) {
        yield put(actions.DECLINE_BOOKING.failure(throwSubmissionError(error)));
    }
}

export function* cancelBooking({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.delete(`/bookings/${payload.Id}`, { params: payload });

        yield put(actions.CANCEL_BOOKING.success(response));
        yield put(actions.FETCH_BOOKING_DETAILS.request(payload));
    } catch (error) {
        yield put(actions.CANCEL_BOOKING.failure(throwSubmissionError(error)));
    }
}

export function* markBookingAsPaid({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(`/bookings/${payload.Id}/markaspaid`, payload);

        yield put(actions.MARK_BOOKING_AS_PAID.success(response.data));
        yield put(actions.FETCH_BOOKING_DETAILS.request(payload));
        yield put(
            Notifications.show({
                level: 'info',
                title: response.data.ResponseStatus.Message,
            })
        );
    } catch (error) {
        yield put(actions.MARK_BOOKING_AS_PAID.failure(throwSubmissionError(error)));
    }
}

export function* bookingStatuses({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/bookings/status');

        yield put(actions.FETCH_BOOKING_STATUSES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_BOOKING_STATUSES.failure(error));
    }
}

export function* createBookedResource({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post(`/bookings/${payload.BookingId}/resource`, payload);

        yield put(actions.CREATE_BOOKED_RESOURCE.success(response.data));
        yield put(actions.FETCH_BOOKING_DETAILS.request({ Id: payload.BookingId }));
    } catch (error) {
        yield put(actions.CREATE_BOOKED_RESOURCE.failure(throwSubmissionError(error)));
    }
}

export function* editBookedResource({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(`/bookings/${payload.BookingId}/resource`, payload);

        yield put(actions.EDIT_BOOKED_RESOURCE.success(response.data));
        yield put(actions.FETCH_BOOKING_DETAILS.request({ Id: payload.BookingId }));
    } catch (error) {
        yield put(actions.EDIT_BOOKED_RESOURCE.failure(throwSubmissionError(error)));
    }
}

export function* deleteBookedResource({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/bookings/${payload.BookingId}/resource`, {
                params: payload,
            });

            yield put(actions.DELETE_BOOKED_RESOURCE.success(response.data));
            yield put(actions.FETCH_BOOKING_DETAILS.request({ Id: payload.BookingId }));
        } catch (error) {
            yield put(actions.DELETE_BOOKED_RESOURCE.failure(error));
        }
    }
}

export function* editBookedQuantities({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(`/bookings/${payload.BookingId}/quantity`, payload);

        yield put(actions.EDIT_BOOKED_QUANTITIES.success(response.data));
        yield put(actions.FETCH_BOOKING_DETAILS.request({ Id: payload.BookingId }));
    } catch (error) {
        yield put(actions.EDIT_BOOKED_QUANTITIES.failure(throwSubmissionError(error)));
    }
}

export function* fetchAvailableResources({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(`/bookings/${payload.Id}/resources/available`, {
            params: payload,
        });

        yield put(actions.FETCH_AVAILABLE_RESOURCES.success({ Results: response.data.Resources }));
    } catch (error) {
        yield put(actions.FETCH_AVAILABLE_RESOURCES.failure(error));
    }
}

export function* refundPayment({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(
            `/bookings/${payload.Id}/refund/${payload.PaymentLogId}`,
            payload
        );

        yield put(actions.REFUND_PAYMENT.success({ Results: response.data.Resources }));
        yield put(actions.FETCH_BOOKING_DETAILS.request({ Id: payload.Id }));
    } catch (error) {
        yield put(actions.REFUND_PAYMENT.failure(throwSubmissionError(error)));
    }
}

export function* sendPaymentRequest({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(
            `/payment/payson/v2/checkout/${payload.Id}/sendpaymentrequest`,
            payload
        );

        yield put(actions.SEND_PAYMENT_REQUEST.success(response.data));
        yield put(
            actions.FETCH_BOOKING_DETAILS.request({ Id: payload.BookingId, requestIsHidden: true })
        );
    } catch (error) {
        yield put(actions.SEND_PAYMENT_REQUEST.failure(throwSubmissionError(error)));
    }
}

export function* reassignBookedResource({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.put('/bookings/resources/move', payload);

            yield put(actions.REASSIGN_BOOKED_RESOURCE.success(response.data));
        } catch (error) {
            yield put(actions.REASSIGN_BOOKED_RESOURCE.failure(throwSubmissionError(error)));
        }
    }
}
