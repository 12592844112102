import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, compose } from 'redux';
import { useLocation, withRouter } from 'react-router-dom';

import { KeycloakInstance } from 'keycloak-js';
import keycloak from '@/keycloak';
import { RootState } from 'MyTypes';
import { INITIAL_ROUTE_KEY, IS_LOADING_STORAGE_KEY } from '@/utils/constants';

interface AuthenticationProviderProps {
    children: any;
    dispatch: Dispatch;
    keycloak: KeycloakInstance
    authenticate: any
}


export const AuthenticationContext = React.createContext('');

export const useAuthentication = () => useContext(AuthenticationContext);

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({ children, dispatch, authenticate }) => {

    return (
        <AuthenticationContext.Provider value="">{children}</AuthenticationContext.Provider>
    );
};

export default compose<React.ComponentType>(
    connect(({authenticate }: RootState) => ({ authenticate })),
    withRouter,
)(AuthenticationProvider);
