import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { generateContentForModals } from '@/utils/generateContentForModals';
import * as actions from './actions';


export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
     
        if (
            (action?.payload?.status === 426 || action.payload?.status === 402) &&
            !action?.payload?.data?.CurrentTrialPeriod?.Active
        ) {
            const payload = generateContentForModals(
                action.payload,
                action.payload,
                api.getState()
            );

            api.dispatch({
                type: actions.SHOW_LICENSES_MODAL,
                payload,
                meta: { status: action?.payload?.data?.Header },
            });

        }
    }

    return next(action)
  }