import {
    createListenerMiddleware,
    createSlice,
    isAnyOf,
    PayloadAction,
    TypedStartListening,
} from '@reduxjs/toolkit';
import { destroy, stopSubmit, submit,  } from 'redux-form';
import { createEntityState } from './utils';
import * as actions from '../actions';
import { ApplicationDispatch, ApplicationState } from '@/store';
import { apiClient, convertErrorToFormError } from '@/utils';
import qs from 'qs';
import { setSubmitSucceeded } from 'redux-form';
import { getTranslate } from 'react-localize-redux';
import { go } from 'connected-react-router';

type InitialState = {
    modal: boolean
    success: boolean
    skip: boolean
    data: {
        [key in string]: any
    }
};

const initialState: InitialState = {
    modal: false,
    success: false,
    skip: false,
    data: {}
}

export type FormStartListening = TypedStartListening<
  ApplicationState,
  ApplicationDispatch
>;

export const formsListener = createListenerMiddleware();

const startFormsListening = formsListener.startListening as FormStartListening;

const formsSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actions.cancelQuestionaireProceed, (state, action) => {
            state.modal = false;
            state.success = true;
            state.data = action.payload;
        });

        builder.addCase(actions.cancelQuestionaireOpenModal, (state, action) => {
            state.modal = true;
        });

        builder.addCase(actions.cancelQuestionaireCloseModal, (state, action) => {
            state.modal = false;
            state.success = false;
            state.skip = false;
            state.data = {};
        });

        builder.addCase(actions.cancelQuestionaireSkip, (state, action) => {
            state.modal = false;
            state.skip = true;
        });
    },
});

startFormsListening({
    predicate: (action) => action.type === actions.sendCancelQuestionaire.REQUEST,
    effect: async ({ payload }: {[key in string]: any}, api) => {
        const formFields = Object.entries(payload).filter(([key, value]) => {
            return !key.includes('main') && !key.includes('comments');
        });

        // Select at least one option
        if(formFields.length < 1 ) {
            const localizeState = api.getState().localize;
            const translate = getTranslate(localizeState);
            const error = new Error(translate('cancelLicenseQuestionaireModal.selectOptionFromEachSection') as string)
            api.dispatch(stopSubmit('cancelLicenseQuestionaire', convertErrorToFormError(error)));
            return;
        }


        api.dispatch(actions.cancelQuestionaireProceed(payload));
    }
});

export default formsSlice;
