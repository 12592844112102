import { AnyAction } from 'redux';
import { put, select, call } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { push } from 'connected-react-router';
import { apiClient, convertErrorToFormError, throwSubmissionError } from '@/utils';
import { confirmSaga } from './confirm';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';
import { Service } from '@/types';
import { ApplicationState } from '@/store';

export function* messages({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/messages/log', {
            params: payload,
        });

        yield put(actions.FETCH_MESSAGE_LOG.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_MESSAGE_LOG.failure(throwSubmissionError(error)));
    }
}

export function* dashboardMessages({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/messages/dashboard', {
            params: { IncludeMessageTypeInformation: true, ...payload },
        });

        yield put(actions.FETCH_MESSAGE_DASHBOARD.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_MESSAGE_DASHBOARD.failure(throwSubmissionError(error)));
    }
}

export function* resendMessage({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(`/messages/log/${payload.Id}/resend`);

        yield put(actions.RESEND_MESSAGE.success(response.data));

        const messageLogState: RootState['messages']['log'] = yield select((state) => state.messages.log);

        // @ts-ignore
        const { requestActionPayload } = messageLogState;
        yield put(actions.FETCH_MESSAGE_LOG.request(requestActionPayload));
    } catch (error) {
        yield put(actions.RESEND_MESSAGE.failure(throwSubmissionError(error)));
    }
}

export function* messageTemplates({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/messages/templates', {
            params: { IncludeMessageTypeInformation: true, ...payload },
        });

        yield put(actions.FETCH_MESSAGE_TEMPLATES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_MESSAGE_TEMPLATES.failure(error));
    }
}

const transformFormData = (formData: any, allServices: Service[]) => {
    const payload = {...formData};
    const _services = [...formData._services];

    delete payload._services;

    const payloadServices: (Service & { selected?: boolean})[] = payload.Services;
    let services: number[] = [];

    if (payload._ApplyForAllServices) {
        services = allServices.map(s => s.Id);
    } else {
        services = payload.Services
            ? payloadServices
                  .map((s, index) => ({ ..._services[index], ...s }))
                  .filter((s) => s.selected)
                  .map((s) => s.Id)
            : [];
    }

    // 1. Don't add it for sms template (TOD)
    const typeId = payload?.MessageType?.SendMethodId !==2 ? payload?.MessageType?.Id : undefined;

    return {
        ...payload,
        // 2. NOTE: when it's fixed on the backend - add it for any typeid
        ...(typeId ? { TypeId: typeId } : {}),
        Services: services
    }
}

export function* createMessageTemplate(action: AnyAction) {
    const create = actions.CREATE_MESSAGE_TEMPLATE;
    const allServices: Service[] = yield select((state: ApplicationState) => state.services?.data);

    try {
        const response: AxiosResponse = yield apiClient.post(`/messages/templates`, transformFormData(action.payload, allServices));

        yield put(create.success(response));
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* editMessageTemplate(action: AnyAction) {
    const edit = actions.EDIT_MESSAGE_TEMPLATE;
    const allServices: Service[] = yield select((state: ApplicationState) => state.services?.data);

    try {
        const response: AxiosResponse = yield apiClient.put(
            `/messages/templates/${action.payload.Id}`,
            transformFormData(action.payload, allServices)
        );

        yield put(edit.success(response));
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* deleteMessageTemplate({ payload, meta: { confirmationMessage } }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga, confirmationMessage);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/messages/templates/${payload.Id}`, payload);

            yield put(actions.DELETE_MESSAGE_TEMPLATE.success(response));
            yield put(push('/messages/templates'));
        } catch (error) {
            yield put(actions.DELETE_MESSAGE_TEMPLATE.failure(error));
        }
    }
}

export function* testMessageTemplate({ payload }: AnyAction) {
    try {
        const tranformedPayload = {
            ...payload,
            Services: payload.Services.filter((s: any) => s.selected).map((s: any) => s.Id)
        };
        
        const response: AxiosResponse = yield apiClient.post('/messages/templates/test', tranformedPayload);

        yield put(actions.TEST_MESSAGE_TEMPLATE.success(response));
    } catch (error) {
        yield put(actions.TEST_MESSAGE_TEMPLATE.failure(convertErrorToFormError(error)));
    }
}

export function* templateTypes({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/messages/templates/types');

        yield put(actions.FETCH_MESSAGE_TEMPLATE_TYPES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_MESSAGE_TEMPLATE_TYPES.failure(error));
    }
}

export function* messageFieldTranslations({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/messages/fieldtranslations');

        yield put(actions.FETCH_MESSAGE_FIELD_TRANSLATIONS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_MESSAGE_FIELD_TRANSLATIONS.failure(error));
    }
}
