import React from 'react';
import { Button, Segment, Grid, Header} from 'semantic-ui-react';

import Field from '@/components/Field';
import { FormProps } from '@/components/Form';
import { Translate } from 'react-localize-redux';
import { Box, Flex } from 'grid-styled';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import InfoImgSv from '@/pngs/resource-group-example-1-sv.png';
import InfoImgEn from '@/pngs/resource-group-example-1-en.png';
import styled from 'styled-components';
import { getCurrentLanguageCode } from '@/utils/i18n';

const Img = styled.img`
    max-width: 100%;
    border: 1px solid var(--gray-200);
    border-radius: 12px;
    /* height: 15px; */
`;

interface Props extends FormProps, RouteComponentProps<any> {}

const ResourceGroupForm: React.FC<Props> = (props) => {
    const { translate, history, submitting, formValues, stepFullfilled } = props;

    return (
        <>
            <Segment attached="top">
                <Grid>
                    <Grid.Column computer={8} mobile={16}>
                        <Field
                            required
                            name="Name"
                            type="text"
                            label={translate(
                                'CreateResourceWizard.resourceGroupForm.field.name'
                            )}
                        />
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16}>
                        <p>
                            <Translate id="CreateResourceWizard.resourceGroupForm.explanation1" options={{ renderInnerHtml: true, renderToStaticMarkup: false }} />
                        </p>
                        <p>
                            <Translate id="CreateResourceWizard.resourceGroupForm.explanation2" options={{ renderInnerHtml: true, renderToStaticMarkup: false }} />
                        </p>

                        <Box mb="3" width={[1, 1, 1/2]}>
                            <Img
                                src={getCurrentLanguageCode() === 'sv' ?  InfoImgSv : InfoImgEn}
                                alt={
                                    translate(
                                        'CreateResourceWizard.resourceGroupForm.explanation3'
                                    ) as string
                                }
                            />
                        </Box>

                        <p>
                            <Translate id="CreateResourceWizard.resourceGroupForm.explanation3" options={{ renderInnerHtml: true, renderToStaticMarkup: false }} />
                        </p>
                        <p>
                            <Translate id="CreateResourceWizard.resourceGroupForm.explanation4" options={{ renderInnerHtml: true, renderToStaticMarkup: false }} />
                        </p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached="bottom">
                <Flex justifyContent={'end'}>
                    <Button
                        disabled={submitting || !stepFullfilled}
                        loading={submitting}
                        onClick={() => {
                            const { search, pathname } = props.location;
                            history.push({
                                pathname: pathname,
                                search: search,
                                hash: '#resources',
                            });
                        }}
                        primary
                        content={translate('form.next')}
                    />
                </Flex>
            </Segment>
        </>
    );
};

export default withRouter(ResourceGroupForm);
