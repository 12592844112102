import React, { useEffect, useState } from 'react';
import { CopyToClipboard as _CopyToClipboard } from 'react-copy-to-clipboard';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import styled from 'styled-components';

interface Props extends LocalizeContextProps {
    text: string;
}

const Main = styled.div`
    position: relative;

    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
`;

const Notification = styled.span`
    position: fixed;
    min-width: 145px;
    padding: 4px;
    background: white;
    border: 1px solid var(--gray-100);
    border-radius: 2px;
    color: var(--blue);
    z-index: 1000;
    animation: fadeIn 0.2s;
`;

const HIDE_TIMEOUT = 1500;

const CopyToClipboard: React.FC<Props> = (props) => {
    const { text, translate } = props;
    const [copied, setCopied] = useState(false);
    const [positionClicked, setPositionClicked] = useState<{ x: number; y: number }>();

    useEffect(() => {
        let intervalId: number;
        if (copied && positionClicked) {
            intervalId = setTimeout(() => {
                setCopied(false);
            }, HIDE_TIMEOUT);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [copied, positionClicked]);

    return (
        <Main
            title={translate('copyToClipboard.clickToCopy') as string}
            onClick={(evt) => {
                setPositionClicked({ x: evt.clientX, y: evt.clientY });
            }}
        >
            {copied ? (
                <Notification
                    style={{
                        left: positionClicked?.x ? `${positionClicked.x + 15}px` : '0px',
                        top: positionClicked?.y ? `${positionClicked.y - 30}px` : '0px',
                    }}
                >
                    {translate('copyToClipboard.successfullyCopied')}
                </Notification>
            ) : null}
            <_CopyToClipboard text={text} onCopy={() => setCopied(true)}>
                <span>{props.children}</span>
            </_CopyToClipboard>
        </Main>
    );
};

export default withLocalize(CopyToClipboard);
