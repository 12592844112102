import { createSelector } from 'reselect';
import { createListLoadingReducer, listInitialState } from './utils';
import reduceReducers from 'reduce-reducers';
import * as actions from '../actions';
import { Customer } from '../../types';
import { AnyAction } from 'redux';

export const getCustomers = (state: any) => state.customers;

export const getCustomerById = (id: string) =>
    createSelector(getCustomers, (customers) =>
        customers.isLoaded
            ? customers.data.find((customer: Customer) => !!customer.Id && customer.Id === id)
            : null
    );

// With normalized CustomFieldValues array
export const getPlainCustomerList = createSelector([getCustomers], (customers) =>
    customers.data.map((customer: Customer) => {
        return {
            ...customer,
            ...customer.CustomFieldValues.reduce(
                (acc, field) => ({ ...acc, [field.Id]: field.Value }),
                {}
            ),
        };
    })
);

const listReducer = createListLoadingReducer('CUSTOMERS', (payload: any) =>
    // remove 'no customer selected' option
    payload.Results.filter((customer: Customer) => !!customer.Id)
);

const initialState = {
    ...listInitialState,
    searchResults: [],
    sort: { by: null, ascending: true },
    total: 100,
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.SORT_CUSTOMERS: {
            return {
                ...state,
                sort: {
                    by: action.payload,
                    ascending: action.payload !== state.sort.by ? true : !state.sort.ascending,
                },
            };
        }
        case actions.FETCH_CUSTOMERS.REQUEST: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                hasError: false,

                error: initialState.data,
            };
        }
        case actions.SEARCH_CUSTOMERS.SUCCESS: {
            return {
                ...state,
                searchResults: action.payload.Results.map((customer: Customer) => ({
                    id: customer.Id,
                    Id: customer.Id,
                    key: customer.Id,
                    value: customer.Id,
                    title: `${customer.Firstname} ${customer.Lastname}`,
                    label: `${customer.Firstname} ${customer.Lastname}`,
                    descriptionDict: {
                        Phone: customer.Phone || '-',
                        Email: customer.Email || '-',
                        ...(customer.CustomFieldValues
                            ? customer.CustomFieldValues.reduce(
                                  (acc, item) => ({ ...acc, [item.Name]: item.Value || '-' }),
                                  {}
                              )
                            : {}),
                    },
                    meta: {
                        ...customer,
                        CustomFields: customer.CustomFieldValues.map((field) => ({ meta: field })),
                    },
                    phone: customer.Phone,
                    email: customer.Email,
                    image: customer.ImageUrl,
                })),
            };
        }
        case actions.SEARCH_CUSTOMERS.FAILURE: {
            return { ...state, searchResults: [] };
        }
        default:
            return state;
    }
};

export default reduceReducers<any>(initialState, reducer, listReducer);
