import React from 'react';
import { Image, Icon, SemanticICONS, SemanticSIZES } from 'semantic-ui-react';
import styled from 'styled-components';

const ImageFrame = styled.div`
    border: 1px solid ${(props) => props.theme.gray300};
    padding: 5px;
    border-radius: 4px;
    position: relative;
`;

const ImageMenu = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`;

const MenuItem = styled.div`
    cursor: pointer;
    padding: 2px 6px;
    background-color: rgb(0, 0, 0, 0.6);
    color: ${(props) => props.theme.gray100};

    :hover {
        color: #fff;
        background-color: rgb(0, 0, 0, 0.75);
    }

    :not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.gray200};
    }
`;

interface MenuItem {
    icon: Readonly<SemanticICONS>;
    content: React.ReactNode;
    onClick: (e: React.MouseEvent) => void;
}

interface Props {
    src?: string;
    menuItems?: MenuItem[];
    imageSize?: SemanticSIZES;
    onClick?: Function;
}

export const ImageUploader: React.FC<Props> = (props) => {
    const { src, imageSize = 'small', menuItems, onClick } = props;

    return (
        <ImageFrame>
            <Image
                src={src}
                size={imageSize}
                onClick={onClick}
                style={{ cursor: onClick ? 'pointer' : undefined }}
            />
            {menuItems && (
                <ImageMenu>
                    {menuItems.map(({ icon, content, onClick }, index) => (
                        <MenuItem key={index} style={{ cursor: 'pointer' }} onClick={onClick}>
                            {icon && <Icon name={icon} />} {content}
                        </MenuItem>
                    ))}
                </ImageMenu>
            )}
        </ImageFrame>
    );
};

export default ImageUploader;
