import React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getTranslate, withLocalize, LocalizeContextProps } from 'react-localize-redux';
import Loader from '@/components/Loader';
import * as actions from '@/store/actions';
import { selectNormalizedCompanyLicenses } from '@/store/reducers/licenses';
import Licenses from './Licenses';
import { TrialType, ListState, LicenseType, LicensesInformation, License } from '@/types';

interface OwnProps {
    suggestedTrialType?: TrialType;
    suggestedLicenseId?: number;
    currentTrialPeriod?: any;
    suggestedLicense?: any;
    onContinueTrial?: () => void;
    onSubmitSuccess?: () => void;
    onSelectPlan?: () => void;
    onGoBack?: () => void;
    autofill?: boolean
}

interface Props extends OwnProps, LocalizeContextProps {
    dispatch: Dispatch;
    companyLicenses: ListState<License>;
    types: ListState<LicenseType>;
    licenseInformationTypes: ListState<any>
    information: ListState<LicensesInformation>;
    addTrial: any;
    modal: any;
}

const LicensesLoader: React.FC<Props> = (props) => {
    return (
        <Loader
            requirements={
                props.suggestedTrialType
                    ? []
                    : [
                          {
                              action: actions.FETCH_LICENSE_TYPES.request({
                                  ExtraLicenseOption: false,
                              }),
                              data: props.types,
                          },
                          {
                              action: actions.FETCH_LICENSE_INFO.request(),
                              data: props.information,
                          },
                          {
                              action: actions.FETCH_COMPANY_LICENSES.request(),
                              data: props.companyLicenses,
                          },
                          {
                              action: actions.FETCH_LICENSE_INFORMATION_TYPES.request(),
                              data: props.licenseInformationTypes,
                          }
                      ]
            }
        >
            <Licenses {...props} />
        </Loader>
    );
};

const mapStateToProps = (state: any) => {
    const { billing } = state;
    const { information, types, creditCard, modal, addTrial, licenseInformationTypes } = state.licenses;

    return {
        addTrial,
        billing,
        information,
        licenseInformationTypes,
        modal,
        types,
        companyLicenses: selectNormalizedCompanyLicenses(state),
        creditCard,
        translate: getTranslate(state.localize),
    };
};

export default compose<React.ComponentType<OwnProps>>(
    connect(mapStateToProps),
    withLocalize
)(LicensesLoader);
