import { Reducer } from 'redux';
import { EntityState, ListState } from '@/types';
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';
import { ActionReducerMapBuilder, AsyncThunk } from '@reduxjs/toolkit';

type Payload = {
    Results: any[];
    Total?: number;
    Take?: number;
    Skip?: number;
    // Used to reload an action
    requestActionPayload: any;
    requestIsHidden?: boolean;
};

type Action = {
    type: string;
    payload: Payload;
    meta: any;
};

const generateRequestFlags = () => ({
    isLoading: true,
    isLoaded: false,
    hasError: false,
});

const generateSuccessFlags = () => ({
    isLoading: false,
    isLoaded: true,
    hasError: false,
});

const generateFailureFlags = () => ({
    isLoading: false,
    isLoaded: false,
    hasError: true,
});

export const entityInitialState = {
    isLoading: false,
    isLoaded: false,
    hasError: false,
    data: null,
    requestIsHidden: false,
    error: undefined,
};

export function createEntityState<T>(data?: T): EntityInitialState<T> {
    return {
        ...entityInitialState,
        requestIsHidden: false,
        data: data ? data : null
    }
}

export type EntityInitialState<T> = {
    isLoading: boolean;
    isLoaded: boolean;
    hasError: boolean;
    data: T | any;
    requestIsHidden: boolean;
    error: any;
}

export function createLoadingReducerToolkit<T = any>(
thunk: AsyncThunk<any, any, any>,
builder: ActionReducerMapBuilder<any>,
name = thunk.name,
transform: (data: any) => any = (data) => data
) {
    builder.addCase(thunk.pending, (state, action: any) => {
        if (action.meta && action.meta.toXLSX) {
            return state;
        }
        
        return {
            ...state,
            [name]: {
                ...generateRequestFlags(),
                error: undefined,
                data: action.meta.requestIsHidden ? state[name].data : null,
                requestActionPayload: action.payload,
                // Don't show loader if request is hidden
                requestIsHidden: !!action?.meta?.requestIsHidden,
            },
        };
    });

    builder.addCase(thunk.fulfilled, (state, action: any) => {
        if (action.meta && action.meta.toXLSX) {
            return state;
        }

        return {
            ...state,
            [name]: {
                ...generateSuccessFlags(),
                data: transform(action.payload),
                requestIsHidden: false,
            }
        }
    });

    builder.addCase(thunk.rejected, (state, action: any) => {
        if (action.meta && action.meta.toXLSX) {
            return state;
        }
        
        return {
            ...state,
            [name]: {
                ...generateFailureFlags(),
                error: action.payload,
                data: null,
                requestIsHidden: false,
            }
        }
    });
}

export function createListLoadingReducerToolkit<T = any>(
    thunk: AsyncThunk<any, any, any>,
    builder: ActionReducerMapBuilder<any>,
    name = thunk.name,
    transform: (data: any) => any = (data) => data?.Results ? data.Results : []
    ) {
        builder.addCase(thunk.pending, (state, action: any) => {
            if (action.meta && action.meta.toXLSX) {
                return state;
            }
            
            const { requestIsHidden } = action.meta;

            return {
                ...state,
                [name]: {
                    ...generateRequestFlags(),
                    error: undefined,
                    data: requestIsHidden ? state.data : [],
                    take: action.payload ? action.payload.Take : undefined,
                    skip: action.payload ? action.payload.Skip : undefined,
                    requestActionPayload: action.payload,
                    // Don't show loader if requset is hidden
                    requestIsHidden,
                },
            };
        });
    
        builder.addCase(thunk.fulfilled, (state, action: any) => {
            if (action.meta && action.meta.toXLSX) {
                return state;
            }
    
            return {
                ...state,
                [name]: {
                    ...generateSuccessFlags(),
                    data: transform(action.payload),
                    total: action.payload?.Total,
                    take: action.payload?.Take,
                    skip: action.payload?.Skip,
                    requestIsHidden: null,
                }
            }
        });
    
        builder.addCase(thunk.rejected, (state, action: any) => {
            if (action.meta && action.meta.toXLSX) {
                return state;
            }

            return {
                ...state,
                [name]: {
                    ...generateFailureFlags(),
                    error: action.payload,
                    data: [],
                    requestIsHidden: null,
                }
            }
        });
    }

export function createLoadingReducer<T = any>(
    entity: string,
    transform: (data: any) => any = (data) => data
): Reducer<EntityState<T>, any> {
    return (state: any = entityInitialState, { type, payload, meta = {} }: Action) => {
        if (meta && meta.toXLSX) {
            return state;
        }

        switch (type) {
            case `FETCH_${entity}_REQUEST`:
                return {
                    ...state,
                    ...generateRequestFlags(),
                    error: undefined,
                    data: meta.requestIsHidden ? state.data : null,
                    requestActionPayload: payload,
                    // Don't show loader if request is hidden
                    requestIsHidden: meta.requestIsHidden,
                };
            case `FETCH_${entity}_SUCCESS`:
                return {
                    ...state,
                    ...generateSuccessFlags(),
                    data: transform(payload),
                    requestIsHidden: false,
                };
            case `FETCH_${entity}_FAILURE`:
                return {
                    ...state,
                    ...generateFailureFlags(),
                    error: payload,
                    data: null,
                    requestIsHidden: false,
                };
            default:
                return state;
        }
    };
}

export const listInitialState: ListState<any> = {
    isLoading: false,
    isLoaded: false,
    hasError: false,
    data: [],
    total: undefined,
    take: undefined,
    skip: undefined,
    error: undefined,
    requestIsHidden: false,
};

export function createListLoadingReducer<T = any>(
    entity: string,
    transform: (data: any) => any = (data) => data?.Results ? data.Results : []
): Reducer<ListState<T>, any> {
    return (state: any = listInitialState, { type, payload, meta = {} }: Action) => {
        if (meta && meta.toXLSX) {
            return state;
        }

        switch (type) {
            case `FETCH_${entity}_REQUEST`:
                // Preserve data for datatables or if meta.requestIsHidden is specified
                const { requestIsHidden } = meta;

                return {
                    ...state,
                    ...generateRequestFlags(),
                    error: undefined,
                    data: requestIsHidden ? state.data : [],
                    take: payload ? payload.Take : undefined,
                    skip: payload ? payload.Skip : undefined,
                    requestActionPayload: payload,
                    // Don't show loader if requset is hidden
                    requestIsHidden,
                };
            case `FETCH_${entity}_SUCCESS`:
                return {
                    ...state,
                    ...generateSuccessFlags(),
                    data: transform(payload),
                    total: payload?.Total,
                    take: payload?.Take,
                    skip: payload?.Skip,
                    requestIsHidden: null,
                };
            case `FETCH_${entity}_FAILURE`:
                return {
                    ...state,
                    ...generateFailureFlags(),
                    error: payload,
                    data: [],
                    requestIsHidden: null,
                };
            default:
                return state;
        }
    };
}
