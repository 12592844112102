import * as actions from '../actions';
import { createLoadingReducer, entityInitialState } from './utils';
import reduceReducers from 'reduce-reducers';
import { Reducer, AnyAction } from 'redux';
import { Booking, EntityState } from '@/types';

interface UIState {
    cancelBookingModalOpen: boolean;
    receiptLoading: boolean;
    paymentRequestLoading: boolean;
}

interface BookingDetailsState extends EntityState<Booking | null>, UIState {}

const dataReducer = (createLoadingReducer('BOOKING_DETAILS') as unknown) as Reducer<
    BookingDetailsState
>;

const initialState = {
    ...entityInitialState,
    cancelBookingModalOpen: false,
    receiptLoading: false,
    sendReceiptLoading: false,
    paymentRequestLoading: false,
};

const UIReducer: Reducer<BookingDetailsState, AnyAction> = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case actions.EDIT_CUSTOMER.SUCCESS:
            return {
                ...state,
                data: { ...state.data, Customer: payload } as Booking,
            };
        case actions.SHOW_CANCEL_BOOKING_MODAL:
            return {
                ...state,
                cancelBookingModalOpen: true,
            };
        case actions.HIDE_CANCEL_BOOKING_MODAL:
            return {
                ...state,
                cancelBookingModalOpen: false,
            };
        case actions.PRINT_RECEIPT.REQUEST:
            return {
                ...state,
                receiptLoading: true,
            };
        case actions.SEND_RECEIPT.REQUEST:
            return {
                ...state,
                sendReceiptLoading: true,
            };
        case actions.SEND_RECEIPT.SUCCESS:
        case actions.SEND_RECEIPT.FAILURE:
            return {
                ...state,
                sendReceiptLoading: false,
            };
        case actions.PRINT_RECEIPT.SUCCESS:
        case actions.PRINT_RECEIPT.FAILURE:
            return {
                ...state,
                receiptLoading: false,
            };
        case actions.SEND_PAYMENT_REQUEST.REQUEST:
            return {
                ...state,
                paymentRequestLoading: true,
            };
        case actions.SEND_PAYMENT_REQUEST.SUCCESS:
        case actions.SEND_PAYMENT_REQUEST.FAILURE:
            return {
                ...state,
                paymentRequestLoading: false,
            };
        default:
            return state;
    }
};

export default (reduceReducers(initialState, UIReducer, dataReducer) as unknown) as Reducer<
    BookingDetailsState
>;
