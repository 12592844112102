import { FormErrors } from 'redux-form';

export interface ServerError {
    FieldName: string;
    Message: string;
}

// Prefix is useful when you have to validate namespaced form sections
export default function convertErrorToFormError(error: any, prefix?: string): FormErrors {
    if (error.response && error.response.status === 426) {
        return {};
    } else if (error.response && error.response.status === 402) {
        return {};
    }

    const options: FormErrors<any, any> = {
        _error: { message: 'Unknown error', errors: [] },
    };

    const responseData = error.response ? error.response.data : null;

    if (responseData && responseData.ResponseStatus && responseData.ResponseStatus.Message) {
        options['_error'].message = responseData.ResponseStatus.Message;
    } else if (error._error) {
        options['_error'].message = error._error;
    } else if (error.error) {
        options['_error'].message = error.error;
    } else if (error.message) {
        options['_error'].message = error.message;
    }

    if (responseData?.ResponseStatus?.Errors && responseData?.ResponseStatus?.Errors.length > 1) {
        responseData.ResponseStatus.Errors.forEach((error: ServerError) => {
            // Form message errors
            options['_error'].errors.push(error.Message);
            // Field level validation

            if (prefix) {
                options[`${prefix}.${error.FieldName}`] = error.Message;
            } else {
                options[error.FieldName] = error.Message;
            }
        });
    } 
    
    else if (responseData?.ResponseStatus?.Errors && responseData?.ResponseStatus?.Errors.length === 1) {
        options._error.message = responseData?.ResponseStatus?.Message;
    }

    return options;
}
