import React from 'react';
import { Route, Switch, withRouter, RouteProps } from 'react-router-dom';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from 'react-redux';
import { Button, Grid, Loader as Spinner } from 'semantic-ui-react';

import theme from '@/theme';

import 'semantic-ui-less/semantic.less'
import 'font-awesome/css/font-awesome.min.css';

import 'react-day-picker/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-quill/dist/quill.snow.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-modal-video/css/modal-video.min.css';
import 'outdated-browser-rework/dist/style.css';
import '../main.css';

import Login from '@/containers/Login/Loadable';
import Verify from '@/containers/Verify/Loadable';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import Full from './Full';
import { ThemeProvider } from 'styled-components';

import { enUS as en, nb as no, sv, fi } from 'date-fns/locale';
import { getCurrentLanguageCode } from '@/utils/i18n';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../AppInsights';
import Notifications from 'react-notification-system-redux';
import { RootState } from 'MyTypes';
import { KeycloakInstance } from 'keycloak-js';
import { compose, Dispatch } from 'redux';
import { Dimmer } from 'semantic-ui-react';
import IdentityProvider from '@/containers/IdentityProvider';
import keycloak from '@/keycloak';
import { Pushable } from './Full/styled';
import LoadingPage from '@/components/LoadingPage';

import { isAuthenticated } from '@/store/reducers/newAuth';
import { ApplicationState, store } from '@/store';
import { Flex } from 'grid-styled';
import Timeout from '@/components/Timeout';
import { authLogout } from '@/store/actions';
import { appBroadcast } from '@/broadcast';

const locales: { [key: string]: Locale } = {
    en,
    no,
    sv,
    fi,
};

Object.keys(locales).forEach((key) => {
    registerLocale(key, locales[key]);
});

setDefaultLocale('sv');

interface AppProps extends LocalizeContextProps, RouteProps {
    notifications: any;
    authenticate: RootState['authenticate'];
    keycloak: KeycloakInstance;
    authenticated: boolean;
    dispatch: Dispatch;
}

const NO_AUTH_PATHS = [''];

class App extends React.Component<AppProps> {
    constructor(props: AppProps) {
        super(props);

        const languages = [
            { name: 'Svenska', code: 'sv' },
            { name: 'English', code: 'en' },
            { name: 'Suomi', code: 'fi' },
            { name: 'Norsk', code: 'no' },
        ];

        this.props.initialize({
            languages,
            options: { renderToStaticMarkup },
        });

        let activeLanguage = 'sv';

        // @ts-ignore
        const browserLanguage = navigator.language || navigator.userLanguage;

        if (getCurrentLanguageCode()) {
            activeLanguage = getCurrentLanguageCode();
        } else if (languages.find((language) => language.code === browserLanguage)) {
            activeLanguage = browserLanguage;
        }

        import(`../locales/${activeLanguage}.json`).then((translations) => {
            this.props.addTranslationForLanguage(translations, activeLanguage);
        });

        appBroadcast.onmessage = (ev) => {
            if(ev.data === 'logout') {
                this.props.dispatch(authLogout());
            }
        }

        this.props.setActiveLanguage(activeLanguage);
        window.localStorage.setItem('language', activeLanguage);
        document.documentElement.setAttribute("lang", activeLanguage);
    }

    render() {
        const { notifications, location, authenticated, translate } = this.props;

        const isNoAuthPath = NO_AUTH_PATHS.some((path) => path === location?.pathname);

        return (
            <ThemeProvider theme={theme}>
                <Notifications notifications={notifications} />
                {isNoAuthPath ? (
                    // Puth non auth routes here, also add them to 
                    // NO_AUTH_PATHS array
                    null
                ) : (
                    <IdentityProvider>
                        <Route path="/auth" component={LoadingPage} />
                        <Route
                            render={() => {
                                return authenticated ? (
                                    <Switch>
                                        <Route path="/" name="Home" component={Full} />
                                        <Route path="/login" name="Login" component={Login} />
                                    </Switch>
                                ) : (
                                    <Timeout
                                        appearComponent={
                                            <Flex
                                                justifyContent="center"
                                                alignItems="center"
                                                flexDirection="column"
                                                style={{
                                                    flexGrow: 1,
                                                    height: '100%'
                                                }}
                                            >
                                                <Flex
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    flexDirection="column"
                                                    style={{
                                                        flexGrow: 1
                                                    }}
                                                >
                                                    <p>
                                                        {translate('common.takingTooLong')}
                                                    </p>
                                                    <Flex style={{ gap: '10px', flexDirection: 'column' }}>
                                                        <Button
                                                            primary
                                                            type="button"
                                                            content={translate('common.reload')}
                                                            onClick={() => {
                                                                window.location.reload();
                                                            }}
                                                        />
                                                        <Button
                                                            type="button"
                                                            content={translate('common.logout')}
                                                            onClick={() => {
                                                                store.dispatch(authLogout())
                                                            }}
                                                        />
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        }
                                    >
                                        <Spinner active />
                                    </Timeout>
                                );
                            }}
                        />
                    </IdentityProvider>
                )}
            </ThemeProvider>
        );
    }
}

const withAITrackingApplied = (Component: any) => {
    return withAITracking(reactPlugin, Component);
};

export default compose(
    withAITrackingApplied,
    withLocalize,
    withRouter,
    connect((state: ApplicationState) => {
        return {
            authenticated: isAuthenticated(state),
            authError: state.authenticate.hasError,
            notifications: state.notifications
        };
    })
)(App);
