import React from 'react';
import Slider from 'rc-slider';
import { range } from 'lodash';

import { convertToFormatOptions, formatMinutesToWords } from '@/utils/datetime';
import { SliderWrapper } from './styled';

import 'rc-slider/assets/index.css';

export const FlexibleSlider: React.FC<{
    unit: 'minutes' | 'hours' | 'days';
    maxDuration: number;
    minDuration: number;
    durationInterval: number;
    defaultValue: number;
    marks?: { [x: string]: any };
    onChange?: (val: number | number[]) => void;
}> = ({
    unit,
    minDuration,
    maxDuration,
    durationInterval,
    defaultValue,
    marks,
    onChange = (val) => {},
}) => {
    let _marks =
        !isNaN(minDuration) && !isNaN(maxDuration) && !isNaN(durationInterval)
            ? range(minDuration, maxDuration + 1, durationInterval)
            : [];

    let marksFormatted = _marks.reduce(
        (acc, curr) => {
            acc[curr] = formatMinutesToWords(curr, {
                abbreaviated: _marks.length >= 7 ? true : false,
                unit: convertToFormatOptions(unit),
            });

            return acc;
        },
        {} as {
            [key in string]: any;
        }
    );

    return (
        <SliderWrapper data-testid="flexible-slider">
            <Slider
                min={minDuration}
                max={maxDuration}
                step={durationInterval}
                defaultValue={defaultValue}
                marks={marks || marksFormatted}
                onChange={onChange}
            />
        </SliderWrapper>
    );
};
