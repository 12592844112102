import { AnyAction } from 'redux';
import { put, select } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import { getTranslate } from 'react-localize-redux';
import * as actions from '@/store/actions';
import { RootState } from 'MyTypes';

const typesToIgnore = [
    actions.CHECK_AUTH.SUCCESS,
    actions.LOG_ERRORS.SUCCESS,
    actions.AUTHENTICATE.SUCCESS,
    actions.CREATE_COMPANY_LICENSE.SUCCESS,
    actions.CREATE_BOOKING.SUCCESS,
    actions.MARK_BOOKING_AS_PAID.SUCCESS,
    actions.DELETE_ICAL_TOKEN_CONFIRMED,
    actions.DELETE_GOOGLE_CALENDAR_TOKEN_CONFIRMED,
    actions.TEST_NEWSLETTER_TEMPLATE.SUCCESS,
    actions.DELETE_PROMO_CODE.FAILURE,
    actions.DELETE_CUSTOMER.FAILURE,
    actions.SEND_PROMOCODE_RECEIPT.REQUEST
];

export function* notificationSaga({ type, payload }: AnyAction) {
    if (typesToIgnore.includes(type)) return;

    const localizeState: RootState['localize'] = yield select((state) => state.localize);
    const translate = getTranslate(localizeState);
    
    if (!type.startsWith('FETCH') && !type.startsWith('SEARCH') && type.endsWith('_SUCCESS') && !type.includes('TOKEN') && !type.includes(actions.AUTHENTICATE_RE_LOGIN.SUCCESS)) {
        
        if (type.startsWith('CREATE')) {
            yield put(
                Notifications.show(
                    // @ts-ignore
                    {
                        title: translate('notifications.created') as string,
                    },
                    'success'
                )
            );
        } else if (type.startsWith('EDIT')) {
            // @ts-ignore
            yield put(Notifications.show({ title: translate('notifications.updated') }, 'info'));
        } else if (type.startsWith('DELETE')) {
            // @ts-ignore
            yield put(Notifications.show({ title: translate('notifications.deleted') }, 'info'));
        } else if (
            type === actions.CANCEL_BOOKING.SUCCESS ||
            type === actions.DECLINE_BOOKING.SUCCESS
        ) {
            // @ts-ignore
            yield put(Notifications.show({ title: translate('notifications.unbooked') }, 'info'));
        } else {
            // @ts-ignore
            yield put(Notifications.show({ title: translate('notifications.success') }, 'success'));
        }
    }

    if (type.startsWith('DELETE') && type.endsWith('FAILURE')) {
        yield put(
            Notifications.show(
                {
                    title: 'Error occured during deleting the record',
                },
                'error'
            )
        );
    }

    if (type === 'AUTHENTICATE_FAILURE') {
        yield put(
            Notifications.show(
                {
                    title: payload?.errors?._error?.message || payload?.message ||'Something went wrong',
                },
                'error'
            )
        );
    }
}
