import React, { Component } from 'react';
import { Tab, TabProps, Label, Button } from 'semantic-ui-react';
import { LocalizeContextProps } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { ListState, Newsletter, NewsletterTemplate, NewsletterEmailTemplate } from '@/types';
import NewsletterLog from '../NewsletterLog/NewsletterLog';
import NewsletterTemplates from '../NewsletterTemplates/NewsletterTemplates';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import qs from 'qs';
import Loader from '@/components/Loader';

interface Props extends LocalizeContextProps, RouteComponentProps{
    dispatch: Dispatch;
    newslettersTemplates: ListState<NewsletterTemplate>;
    newslettersLog: ListState<Newsletter>;
    newslettersEmailTemplates: ListState<NewsletterEmailTemplate>;
    createFetchNewsletterTemplatesAction: (args: any) => void;
    createFetchNewsletterEmailTemplatesAction: (args: any) => void;
    createFetchNewsletterLogAction: (args: any) => void;
    receiverContains?: string;
    sent?: boolean;
}


class Newsletters extends Component<Props> {

    componentDidMount() {
        const { location, history } = this.props;
        const { search } = location;
        const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
        let { activeTab } = parsedSearch;

        if(activeTab) {
            delete parsedSearch.activeTab;
            history.push(`${location.pathname}?${qs.stringify(parsedSearch)}#${activeTab}`);
        }
    }

    render() {
        const { translate, location ,newslettersLog, newslettersTemplates, newslettersEmailTemplates } = this.props;

        
        const { hash } = location;

        const panes = [
            {
                menuItem: {
                    content: (
                        <div>
                            {translate('newsletters.tabs.log')}&nbsp;
                            {typeof this.props.newslettersLog.total !== 'undefined' ? (
                                <Label basic>{newslettersLog.total}</Label>
                            ) : null}
                        </div>
                    ),
                    key: 'newsletterlog',
                },
                render: () => (
                    <Tab.Pane attached={false}>
                        <Loader
                            requirements={[
                                {
                                    action: (listParams) =>
                                        this.props.createFetchNewsletterLogAction(listParams),
                                    data: this.props.newslettersLog,
                                },
                            ]}
                        >
                            {/* @ts-ignore */}
                            <NewsletterLog {...this.props} newsletters={newslettersLog} />
                        </Loader>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    content: (
                        <div>
                            {translate('newsletters.tabs.tamplates')}&nbsp;
                            {typeof this.props.newslettersTemplates.total !== 'undefined' ? (
                                <Label basic>{newslettersTemplates.total}</Label>
                            ) : null}
                        </div>
                    ),
                    key: 'newslettertemplates',
                },
                render: () => (
                    <Tab.Pane attached={false}>
                        <Loader
                            requirements={[
                                {
                                    action: (listParams) =>
                                        this.props.createFetchNewsletterTemplatesAction(listParams),
                                    data: this.props.newslettersTemplates,
                                },
                                {
                                    action: (listParams) =>
                                        this.props.createFetchNewsletterEmailTemplatesAction(
                                            listParams
                                        ),
                                    data: this.props.newslettersEmailTemplates,
                                },
                            ]}
                        >
                            {/* @ts-ignore */}
                            <NewsletterTemplates {...this.props} templates={newslettersTemplates} />
                        </Loader>
                    </Tab.Pane>
                ),
            },
        ];

        let activeHashIndex = panes.findIndex((pane) => pane.menuItem.key === hash.slice(1));
        activeHashIndex = activeHashIndex !== -1 ? activeHashIndex : 0;

        return (
            <>
                {activeHashIndex === 0 ? (
                    <Button
                        floated="right"
                        positive
                        content={translate('sendNewsletter.title')}
                        as={Link}
                        to="/newsletters/send"
                        size="large"
                    />
                ) : activeHashIndex === 1 ? (
                    <Button
                        floated="right"
                        positive
                        content={translate('newsletterTemplates.newTemplate')}
                        as={Link}
                        to="/newsletters/new"
                        size="large"
                    />
                ) : null}
                <Tab
                    onTabChange={this.handleTabChange}
                    panes={panes}
                    activeIndex={activeHashIndex}
                    menu={{ secondary: true }}
                />
            </>
        );
    }
    
    handleTabChange = (e: React.MouseEvent, { activeIndex, panes }: TabProps) => {
        const { history, location } = this.props;
        
        // @ts-ignore
        history.push(
            // Check if activeIndex property is valid
            // @ts-ignore
            `${location.pathname}#${panes[activeIndex].menuItem.key}`
        );
    };
}


const mapStateToProps = (state: RootState, props: any) => {

    return {
        newslettersLog: state.newsletters.log,
        newslettersTemplates: state.newsletters.templates,
        newslettersEmailTemplates: state.newsletters.emailTemplates
    };
};

export default connect(mapStateToProps)(withRouter(Newsletters));
