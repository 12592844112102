import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose, Dispatch } from 'redux';
import { TabProps } from 'semantic-ui-react';

interface Props extends RouteComponentProps {
    dispatch: Dispatch;
}

export interface tabsHOCProps {
    handleTabChange: (e: React.MouseEvent, { activeIndex, panes }: TabProps) => void
}

const tabsHOC = () => (
    WrappedComponent: React.ComponentType<{
        handleTabChange: (e: React.MouseEvent, { activeIndex, panes }: TabProps) => void
    }>
) => {
    class Inner extends React.Component<Props> {
        constructor(props: any) {
            super(props);

            this.handleTabChange = this.handleTabChange.bind(this);
        }

        componentDidUpdate(prevProps: Props, prevState: any) {
            const { history, location } = this.props;
            const parsedSearch = qs.parse(location.search, { ignoreQueryPrefix: true });
            const { activeTab } = parsedSearch;

            if(activeTab) {
                delete parsedSearch.activeTab;
                history.push(`${location.pathname}?${qs.stringify(parsedSearch)}#${activeTab}`);
            }
        }

        handleTabChange(e: React.MouseEvent, { activeIndex, panes }: TabProps) {
            const { history, location } = this.props;
            // console.log(this)
            history.push(
                // Check if activeIndex property is valid
                // @ts-ignore
                `${location.pathname}#${panes[activeIndex].menuItem.key}`
            );
        }

        render() {
            return <WrappedComponent {...this.props} handleTabChange={this.handleTabChange} />;
        }
    }


    return compose(
        connect(),
        withRouter,
    )(Inner);
};

export default tabsHOC;
