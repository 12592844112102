import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withLocalize } from 'react-localize-redux';

import { OPEN_IMAGE_UPLOAD_MODAL, RESET_IMAGE_UPLOADER } from '../store/actions';
import UploadImageModal from './UploadImageModal';
import ImageThumbnail from './ImageThumbnail';
import { WrappedFieldProps } from 'redux-form';

export const FolderTypes = {
    LogoType: 1,
    Homepage: 2,
    Newsletter: 3,
    Resource: 4,
    Service: 5,
    CustomerComments: 6,
    SupportCase: 7,
};

interface Props extends WrappedFieldProps {
    src?: string;
    folderType: number;
    isLoading: boolean;
    dispatch: Function;
    translate: Function;
    form: string;
    field: string;
    label: string;
}

type State = {};

export class ImageUploader extends Component<Props, State> {
    componentDidMount() {
        this.props.dispatch({ type: RESET_IMAGE_UPLOADER });
    }

    componentWillUnmount() {
        this.props.dispatch({ type: RESET_IMAGE_UPLOADER });
    }

    openModal = () => {
        this.props.dispatch({
            type: OPEN_IMAGE_UPLOAD_MODAL,
        });
    };

    removeImage = () => {
        this.props.dispatch({ type: RESET_IMAGE_UPLOADER });
        this.props.input.onChange(null);
    };

    handleSelectImage = (fileUrl: string) => {
        this.props.input.onChange(fileUrl);
    };

    render() {
        const { folderType, translate, label = null, input } = this.props;
        const { value } = input;

        return (
            <div className="ui field">
                {label && <label>{label}</label>}

                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <ImageThumbnail
                        src={value || 'assets/image.png'}
                        menuItems={
                            value
                                ? [
                                      {
                                          icon: 'upload',
                                          content: translate('form.imageUpload.changeImage'),
                                          onClick: this.openModal,
                                      },
                                      {
                                          icon: 'remove',
                                          content: translate('form.imageUpload.removeImage'),
                                          onClick: this.removeImage,
                                      },
                                  ]
                                : [
                                      {
                                          icon: 'upload',
                                          content: translate('form.imageUpload.uploadNewImage'),
                                          onClick: this.openModal,
                                      },
                                  ]
                        }
                    />
                </div>
                <UploadImageModal folderType={folderType} onSave={this.handleSelectImage} />
            </div>
        );
    }
}

export default compose(
    connect(({ images }: any) => ({ isLoading: images.isLoading })),
    withLocalize
)(ImageUploader);
