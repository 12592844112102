import { AnyAction } from 'redux';
import { put, select } from 'redux-saga/effects';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { push } from 'connected-react-router';
import { ApplicationState } from '@/store';

export function* fetchBookingSettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/settings', {
            params: {
                IncludeScheduleViewOptions: true,
                IncludeWeekNumberOptions: true,
                IncludeBookingTemplateOptions: true,
                IncludeBookingStatusOptions: true,
                IncludeCalendarTypeOptions: true,
                IncludeFreeSpotTextOptions: true,
            },
        });

        yield put(actions.FETCH_BOOKING_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_BOOKING_SETTINGS.failure(error));
    }
}

export function* editBookingSettings(action: AnyAction) {
    const edit = actions.EDIT_BOOKING_SETTINGS;
    const location: Location = yield select((s: ApplicationState) => s.router.location);
    try {
        const response: AxiosResponse = yield apiClient.put('/settings', action.payload);

        yield put(edit.success(response));

        const query = qs.parse(location.search.slice(1));

        if (query.afterSuccess) {
            yield put(push(query.afterSuccess as string))
        }
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}
