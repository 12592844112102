import React, { useState, useEffect, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { replace } from 'connected-react-router';
import qs from 'qs';
import Div100vh from 'react-div-100vh';

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Dimmer, Grid } from 'semantic-ui-react';
import LoadingBar from 'react-redux-loading-bar';

import ErrorBoundary from '@/components/ErrorBoundary';
import Header from '@/components/Header';
import AppSidebar from '@/components/Sidebar';
import Breadcrumbs from '@/components/Breadcrumbs';
import LicensesModal from '@/containers/LicensesModal';
import LoadingPage from '@/components/LoadingPage';
import Confirm from '@/components/Confirm';
import Notifications from 'react-notification-system-redux';
import * as actions from '@/store/actions';
import { Pushable } from './styled';
import { RootState } from 'MyTypes';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, compose } from 'redux';
import useTranslate from '@/hooks/useTranslate';
import AgreementModal from '../AgreementModal';
import BillingSettingsModal from '../BillingSettingsModal';
import { getCompanyId, isAuthorised, selectAuthorised } from '@/store/reducers/newAuth';
import { ApplicationDispatch, ApplicationState } from '@/store';
import CreateInvoiceSuccessModal from '../CreateInvoice/CreateInvoiceSuccessModal';
import bmApiService from '@/services/bmApi';
import CorrelationIdModal from '../CorrelationIdModal';

declare global {
    interface Window {
        Tawk_API: any;
        Tawk_LoadStart?: Date;
    }
}

const initializeFreshwork = (url: string) => {
    var s1 = document.createElement('script'),
    s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = url;
    s1.setAttribute('crossorigin', '*');
        // @ts-ignore
    s0.parentNode.insertBefore(s1, s0);
};

interface Props extends ConnectedProps<typeof connector>, RouteComponentProps {
    dispatch: ApplicationDispatch;
}

const MainLayout: React.FC<Props> = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { translate, activeLanguage } = useTranslate();

    const {
        authorised,
        auth,
        browser,
        location,
        dispatch,
        licenses,
        notifications,
        agreements,
        company,
        billing,
        companyId,
        eaccounting,
        correlationId
    } = props;

    useEffect(() => {
        setSidebarOpen(!browser.lessThan.small);

        if (location.search) {
            const { status } = qs.parse(location.search, { ignoreQueryPrefix: true });

            if (status === 'billing-success') {
                dispatch(
                    Notifications.show({
                        level: 'success',
                        title: translate('licenses.successfullyUpgradedLicenseTitle') as string,
                        message: translate('licenses.successfullyUpgradedLicense') as string,
                    })
                );
            } else if (status === 'billing-cancel') {
                dispatch(
                    Notifications.show({
                        level: 'info',
                        title: translate('licenses.abortedLicenseUpgradeTitle') as string,
                        message: translate('licenses.abortedLicenseUpgrade') as string,
                    })
                );
            }
        }
    }, []);

    useEffect(() => {
        if (authorised && !company.isLoading && !company.data) {
            dispatch(actions.FETCH_COMPANY.request({ Id: companyId }));
            
        }
        if(companyId) {
            dispatch(bmApiService.endpoints.getEaccountingSettings.initiate({}));

        }
    }, [authorised, auth.isLoading, auth.data, company, companyId])


    useEffect(() => {
        setSidebarOpen(!browser.lessThan.small);
    }, [browser.lessThan.small]);

    const toggleSidebarOpen = useCallback(() => {
        setSidebarOpen(!sidebarOpen);
    }, [sidebarOpen]);

    const openSubscriptionModal = useCallback(() => {
        dispatch({
            type: actions.SHOW_LICENSES_MODAL,
            meta: { status: 426 }
        });

    }, [])

    const isMobile = browser.lessThan.small;
    const isScheduler = props.location.pathname === '/scheduler';

    if (auth.isLoading || !authorised) return <LoadingPage />;

    return (
        <div className="app">
            <Div100vh style={{ paddingBottom: '61px'}}>
                <Notifications notifications={notifications} />
                {licenses.modal.show ? <LicensesModal {...props} /> : null}
                {correlationId.modal ? <CorrelationIdModal {...props} /> : null}
                {agreements.modal.show ? <AgreementModal /> : null}
                {billing.modal.show ? <BillingSettingsModal /> : null}
                {eaccounting.createInvoice.data ? <CreateInvoiceSuccessModal /> : null}
                <Confirm />
                {/* @ts-ignore */}
                <Header sidebarOpened={sidebarOpen} openSubscriptionModal={openSubscriptionModal} toggleSidebarOpen={toggleSidebarOpen} />

                <AppSidebar
                    visible={sidebarOpen}
                    showSidebarToggle={isMobile}
                    toggleSidebarOpen={toggleSidebarOpen}
                    onItemClick={isMobile ? toggleSidebarOpen : undefined}
                />
                <Pushable
                    className={`pusher pushable ${sidebarOpen ? 'open' : ''}`}
                    style={{ position: 'relative' }}
                    pushed={!isMobile && sidebarOpen}
                    dimmed={isMobile && sidebarOpen}
                >
                    <ErrorBoundary>
                        
                            {props.children}
                        
                    </ErrorBoundary>
                </Pushable>
            </Div100vh>
        </div>
    );
};



const mapStateToProps = (state: ApplicationState) => {
    const {
        browser,
        notifications,
        company,
        licenses,
        agreements,
        billing,
        authenticate,
        eaccounting,
        correlationId
    } = state;

    const authorised = selectAuthorised(state);
    
    return {
        browser,
        company,
        notifications,
        auth: authenticate,
        authorised,
        companyId: getCompanyId(state),
        licenses,
        agreements,
        billing,
        eaccounting,
        correlationId
    };
};

const connector = connect(mapStateToProps);

export default compose<React.ComponentType>(connector, withRouter)(MainLayout);
