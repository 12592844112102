import { AnyAction } from 'redux';
import { put, take, select } from 'redux-saga/effects';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { AxiosResponse } from 'axios';

// Codelock (general) settings
export function* fetchCodelockSettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/codelock/settings', {
            params: { IncludeCodeLockSystemOptions: true },
        });

        yield put(actions.FETCH_CODELOCK_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_CODELOCK_SETTINGS.failure(error));
    }
}

export function* editCodelockSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/codelock/settings', payload);

        yield put(actions.EDIT_CODELOCK_SETTINGS.success(response));
    } catch (error) {
        yield take(actions.CREATE_COMPANY_LICENSE.SUCCESS);
        try {
            const response: AxiosResponse = yield apiClient.put('/codelock/settings', payload);
            yield put(actions.EDIT_CODELOCK_SETTINGS.success(response));
            
        } catch (error) {
            yield put(actions.EDIT_CODELOCK_SETTINGS.failure(throwSubmissionError(error)));
        }
    }
}

export function* createCodelockSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/codelock/settings', payload);

        yield put(actions.CREATE_CODELOCK_SETTINGS.success(response));
    } catch (error) {
        yield put(actions.CREATE_CODELOCK_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchSiedleSettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/codelock/siedle/settings');

        yield put(actions.FETCH_SIEDLE_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_SIEDLE_SETTINGS.failure(error));
    }
}

export function* editSiedleSettings({ payload }: AnyAction) {
    try {
        let modifiedPayload = {...payload};
        
        delete modifiedPayload.CloudApi;
        delete modifiedPayload.LocalApi;

        const response: AxiosResponse = yield apiClient.put('/codelock/siedle/settings', modifiedPayload);

        yield put(actions.EDIT_SIEDLE_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.EDIT_SIEDLE_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* createSidleSettings({ payload }: AnyAction) {
    try {
        let modifiedPayload = {...payload};
        
        delete modifiedPayload.CloudApi;
        delete modifiedPayload.LocalApi;

        const response: AxiosResponse = yield apiClient.post('/codelock/siedle/settings', modifiedPayload);

        yield put(actions.CREATE_SIEDLE_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_SIEDLE_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchAxemaSettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/codelock/axema/settings');

        yield put(actions.FETCH_AXEMA_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_AXEMA_SETTINGS.failure(error));
    }
}

export function* editAxemaSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/codelock/axema/settings', payload);

        yield put(actions.EDIT_AXEMA_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.EDIT_AXEMA_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* createAxemaSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/codelock/axema/settings', payload);

        yield put(actions.CREATE_AXEMA_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_AXEMA_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchVanderbiltSettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/codelock/vanderbilt/settings');

        yield put(actions.FETCH_VANDERBILT_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_VANDERBILT_SETTINGS.failure(error));
    }
}

export function* editVanderbiltSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/codelock/vanderbilt/settings', payload);

        yield put(actions.EDIT_VANDERBILT_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.EDIT_VANDERBILT_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* createVanderbiltSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/codelock/vanderbilt/settings', payload);

        yield put(actions.CREATE_VANDERBILT_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_VANDERBILT_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchAmidoSettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/codelock/amido/dax/settings');

        yield put(actions.FETCH_AMIDO_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_AMIDO_SETTINGS.failure(error));
    }
}

export function* editAmidoSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/codelock/amido/dax/settings', payload);

        yield put(actions.EDIT_AMIDO_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.EDIT_AMIDO_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* createAmidoSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/codelock/amido/dax/settings', payload);

        yield put(actions.CREATE_AMIDO_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_AMIDO_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchParakeySettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/codelock/parakey/settings');

        yield put(actions.FETCH_PARAKEY_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_PARAKEY_SETTINGS.failure(error));
    }
}

export function* editParakeySettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/codelock/parakey/settings', payload);

        yield put(actions.EDIT_PARAKEY_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.EDIT_PARAKEY_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* createParakeySettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/codelock/parakey/settings', payload);

        yield put(actions.CREATE_PARAKEY_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_PARAKEY_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchRcoM5Settings(action: AnyAction) {
    // TODO: uncomment when API for rco is ready
    // try {
    //     const response: AxiosResponse = yield apiClient.get('/codelock/rco/settings');
    //     yield put(actions.FETCH_RCO_M5_SETTINGS.success(response.data));
    // } catch (error) {
    //     yield put(actions.FETCH_RCO_M5_SETTINGS.failure(error));
    // }

    // TODO: delete this when API for rco is ready
    yield put(
        actions.FETCH_RCO_M5_SETTINGS.success({
            Created: '2021-02-01T09:00:43',
            Updated: '2021-02-01T09:00:43',
        })
    );
}

export function* editRcoM5Settings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/codelock/rco/settings', payload);

        yield put(actions.EDIT_RCO_M5_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.EDIT_RCO_M5_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* createRcoM5Settings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/codelock/rco/settings', payload);

        yield put(actions.CREATE_RCO_M5_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_RCO_M5_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchRcoEnablaSettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/codelock/rcoenabla/settings');
        yield put(actions.FETCH_RCO_ENABLA_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_RCO_ENABLA_SETTINGS.failure(error));
    }
}

export function* editRcoEnablaSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/codelock/rcoenabla/settings', payload);

        yield put(actions.EDIT_RCO_ENABLA_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.EDIT_RCO_ENABLA_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* createRcoEnablaSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/codelock/rcoenabla/settings', payload);

        yield put(actions.CREATE_RCO_ENABLA_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_RCO_ENABLA_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchTelkeySettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/codelock/telkey/settings');

        yield put(actions.FETCH_TELKEY_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_TELKEY_SETTINGS.failure(error));
    }
}

export function* editTelkeySettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/codelock/telkey/settings', payload);

        yield put(actions.EDIT_TELKEY_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.EDIT_TELKEY_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* createTelkeySettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/codelock/telkey/settings', payload);

        yield put(actions.CREATE_TELKEY_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_TELKEY_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchAccessySettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/codelock/accessy/settings');

        yield put(actions.FETCH_ACCESSY_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_ACCESSY_SETTINGS.failure(error));
    }
}

export function* editAccessySettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/codelock/accessy/settings', payload);

        yield put(actions.EDIT_ACCESSY_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.EDIT_ACCESSY_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* createAccessySettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/codelock/accessy/settings', payload);

        yield put(actions.CREATE_ACCESSY_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_ACCESSY_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchZesecSettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/codelock/zesec/settings');

        yield put(actions.FETCH_ZESEC_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_ZESEC_SETTINGS.failure(error));
    }
}

export function* editZesecSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/codelock/zesec/settings', payload);

        yield put(actions.EDIT_ZESEC_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.EDIT_ZESEC_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* createZesecSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/codelock/zesec/settings', payload);

        yield put(actions.CREATE_ZESEC_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_ZESEC_SETTINGS.failure(throwSubmissionError(error)));
    }
}