import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import { AxiosResponse } from 'axios';

export function* coordinates({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/companies/coordinates', { params: payload });

        yield put(actions.FETCH_COORDINATES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_COORDINATES.failure(error));
    }
}
