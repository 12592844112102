import React from 'react';
import { Button } from 'semantic-ui-react';
import useTranslate from '@/hooks/useTranslate';

interface Props extends React.ComponentProps<typeof Button> {
    linkKey: string;
}

const onMissingTranslation = () => '';

const HelpButton: React.FC<Props> = ({ linkKey, style, className }) => {
    const { translate } = useTranslate();

    const link = translate(linkKey, {}, { onMissingTranslation });

    if (!link) return null;

    return (
        <Button
            style={style}
            className={className}
            basic
            circular
            icon="help"
            size="mini"
            as="a"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            type="button"
        />
    );
};

export default HelpButton;
