import { put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actions from '@/store/actions';
import Notifications from 'react-notification-system-redux';
import { apiClient, throwSubmissionError } from '@/utils';
import downloadPDF from '@/utils/downloadPDF';
import { confirmSaga } from './confirm';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

export function* bookingPrintoutTemplates({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/reports/booking/printout', {
            params: payload,
        });

        yield put(actions.FETCH_BOOKING_PRINTOUT_TEMPLATES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_BOOKING_PRINTOUT_TEMPLATES.failure(throwSubmissionError(error)));
    }
}

export function* createBookingPrintoutTemplate(action: AnyAction) {
    const create = actions.CREATE_BOOKING_PRINTOUT_TEMPLATE;
    try {
        const response: AxiosResponse = yield apiClient.post(`/reports/booking/printout`, action.payload);

        yield put(create.success(response));
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* editBookingPrintoutTemplate(action: AnyAction) {
    const edit = actions.EDIT_BOOKING_PRINTOUT_TEMPLATE;
    try {
        const response: AxiosResponse = yield apiClient.put(
            `/reports/booking/printout/${action.payload.Id}`,
            action.payload
        );

        yield put(edit.success(response));
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* deleteBookingPrintoutTemplate({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(
                `/reports/booking/printout/${payload.Id}`,
                payload
            );

            yield put(actions.DELETE_BOOKING_PRINTOUT_TEMPLATE.success(response));
            yield put(push('/booking-printout'));
        } catch (error) {
            yield put(actions.DELETE_BOOKING_PRINTOUT_TEMPLATE.failure(error));
        }
    }
}

export function* testBookingPrintoutTemplate({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post(`/reports/booking/printout/test`, payload, {
            responseType: 'blob',
        });

        yield put(actions.TEST_BOOKING_PRINTOUT_TEMPLATE.success(response));

        downloadPDF(response.data, `printout-test.pdf`);
    } catch (error: any) {
        const responseRaw: string = yield error.response.data.text();
        const responseObj = JSON.parse(responseRaw);
        yield put(
            Notifications.error({
                title: responseObj.ResponseStatus.Message
            })
        );
        yield put(actions.TEST_BOOKING_PRINTOUT_TEMPLATE.failure());
    }
}

export function* bookingPrintoutFieldTranslations({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/reports/booking/printout/fieldtranslations', {
            params: payload,
        });

        yield put(actions.FETCH_BOOKING_PRINTOUT_FIELD_TRANSLATIONS.success(response.data));
    } catch (error) {
        yield put(
            actions.FETCH_BOOKING_PRINTOUT_FIELD_TRANSLATIONS.failure(throwSubmissionError(error))
        );
    }
}
