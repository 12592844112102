import React, { useEffect } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Menu, Grid, Tab, TabProps } from 'semantic-ui-react';

import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import qs from 'qs';

import NewBooking from '@/containers/Booking/NewBooking/Loadable';
import Bookings from '@/containers/Bookings/Loadable';
import BookingsReport from '@/containers/BookingsReport/Loadable';
import MessageLog from '@/containers/MessageLog/Loadable';
import MessageTemplates from '@/containers/MessageTemplates/Loadable';
import PageHeader from '@/components/PageHeader';


interface Props extends RouteComponentProps, LocalizeContextProps {
    dispatch: Dispatch;
}

const BookingsLoader: React.FC<Props> = (props) => {
    const { translate, location, history } = props;
    const { hash, search } = location;

    const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
    let { activeTab } = parsedSearch;

    useEffect(() => {
        if(activeTab) {
            delete parsedSearch.activeTab;
            history.push(`${location.pathname}?${qs.stringify(parsedSearch)}#${activeTab}`);
        }
    }, [activeTab])
    

    const handleTabChange = (e: React.MouseEvent, { activeIndex, panes }: TabProps) => {
        history.push(
            // Check if activeIndex property is valid
            // @ts-ignore
            `${location.pathname}#${panes[activeIndex].menuItem.key}`
        );
    };

    const panes = [
        {
            menuItem: {
                content: (
                    <div>
                        {translate('bookings.tab.book')}
                    </div>
                ),
                key: 'book',
            },
            render: () => (
                <Tab.Pane>
                    <NewBooking {...props} withHeader={false} selectableServiceDuration={true} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                content: (
                    <div>
                        {translate('bookings.tab.bookings')}
                    </div>
                ),
                key: 'bookings',
            },
            render: () => (
                <Tab.Pane>
                    <Bookings {...props} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                content: (
                    <div>
                        {translate('bookings.tab.bookingsReport')}
                    </div>
                ),
                key: 'bookings-report',
            },
            render: () => (
                <Tab.Pane>
                    <BookingsReport {...props} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                content: (
                    <div>
                        {translate('bookings.tab.messageLog')}
                    </div>
                ),
                key: 'message-log',
            },
            render: () => (
                <Tab.Pane>
                    <MessageLog {...props} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                content: (
                    <div>
                        {translate('bookings.tab.messageTemplates')}
                    </div>
                ),
                key: 'message-templates',
            },
            render: () => (
                <Tab.Pane>
                    <MessageTemplates {...props} />
                </Tab.Pane>
            ),
        }
    ];

    let activeHashIndex = panes.findIndex((pane) => pane.menuItem.key === hash.slice(1));
    activeHashIndex = activeHashIndex !== -1 ? activeHashIndex : 0;

    return (
        <>
            <PageHeader
                visible={false}
                breadcrumbs={[{ to: '/', content: translate('bookings.title') }]}
            />
            <Tab
                onTabChange={handleTabChange}
                panes={panes}
                activeIndex={activeHashIndex}
            />
        </>
    );
};


export default withLocalize(withRouter(BookingsLoader));
