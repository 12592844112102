import { take, put, race } from 'redux-saga/effects';
import { SHOW_CONFIRMATION, HIDE_CONFIRMATION, CONFIRM_YES, CONFIRM_NO } from '@/store/actions';

// type ConfirmSagaAction = {
//     type: string,
//     payload: {
//         confirmationMessage: string,
//         onConfirm: Function,
//         onCancel: Function
//     }
// };

export function* confirmSaga(confirmationMessage?: string, meta?: any) {
    // Cause the dialog to be shown (reducer will put the message
    // in the store; the main shell UI component will receive the
    // message in its props and then display the dialog)
    yield put({ type: SHOW_CONFIRMATION, payload: confirmationMessage, meta });
    // Wait for either a yes or a no.
    // The dialog UI component receives yes and no event handlers
    // in its props that dispatch these actions.
    const { yes } = yield race({
        yes: take(CONFIRM_YES),
        no: take(CONFIRM_NO),
    });
    // Tell a reducer to hide the dialog
    yield put({ type: HIDE_CONFIRMATION });
    // Returns true if the 'yes' action was received
    return !!yes;
}
