import React from 'react';
import {
    TextAreaField,
    CheckboxField,
    CheckboxGroupField,
    SelectField,
    Dropdown,
    CounterField,
    InputField,
} from './Input';
import ImageUploader from '../ImageUploader';
import TimeField from './TimeField';
import RichTextField from './RichTextField';

// TODO. Seems like switch-approach is not really true way
// especially when using TypeScript

class Input extends React.Component<any> {
    render() {
        const { type } = this.props;

        if (type === 'textarea') {
            // @ts-ignore
            return <TextAreaField {...this.props} />;
        }

        if (type === 'checkbox') {
            // @ts-ignore
            return <CheckboxField {...this.props} />;
        }

        if (type === 'checkbox-group') {
            // @ts-ignore
            return <CheckboxGroupField {...this.props} />;
        }

        if (type === 'select') {
            // @ts-ignore
            return <SelectField {...this.props} />;
        }

        if (type === 'dropdown') {
            // @ts-ignore
            return <Dropdown {...this.props} />;
        }

        if (type === 'time') {
            // @ts-ignore
            return <TimeField {...this.props} />;
        }

        if (type === 'rich') {
            // @ts-ignore
            return <RichTextField {...this.props} />;
        }

        if (type === 'counter') {
            // @ts-ignore
            return <CounterField {...this.props} />;
        }

        if (type === 'file') {
            return <input type="file" {...this.props} />;
        }

        if (type === 'image') {
            // @ts-ignore
            return <ImageUploader {...this.props} />;
        }

        // @ts-ignore
        return <InputField {...this.props} />;
    }
}

export default Input;
export { Checkbox } from './Input';
export {
    TextAreaField,
    CheckboxField,
    CheckboxGroupField,
    SelectField,
    Dropdown,
    CounterField,
    ImageUploader,
    InputField,
    RichTextField,
};
export { default as FieldLabel } from './Label';
