import { createSelector } from 'reselect';
import { createListLoadingReducer } from './utils';
import { CustomField } from '@/types';

export const selectCustomFields = (state: any) => state.customFields;

const createSelectorForTable = (table: any) =>
    createSelector(selectCustomFields, (customFields) =>
        customFields.data.filter((field: any) => field.Table === table)
    );

export const getServiceCustomFields = createSelectorForTable('Event');
export const selectCustomerCustomFields = createSelectorForTable('RegisteredUsers');
export const selectBookingCustomFields = createSelectorForTable('BookedEvent');

export default createListLoadingReducer<CustomField>('CUSTOM_FIELDS');
