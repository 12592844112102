import { put, call, select, all, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actions from '../actions';
import { apiClient, throwSubmissionError } from '../../utils';
import { confirmSaga } from './confirm';
import { AnyAction } from 'redux';
import { Price } from '@/types';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';

export function* prices({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/services/prices', {
            params: { Active: true, ...payload },
        });

        yield put(
            actions.FETCH_PRICES.success({
                ...response.data,
                Results: response.data.Results.map((result: Price) => ({
                    ...result,
                    DaysOfWeek: result.DaysOfWeek.map((day: any) => day.DayOfWeekId),
                })),
            })
        );
    } catch (error) {
        yield put(actions.FETCH_PRICES.failure(error));
    }
}

const normalizeFormData = (formValues: any) => {
    let Category;
    let Price = Math.round(formValues.Price);

    if (formValues.withCategory) {
        if (formValues.Category === '_new') {
            Category = formValues.NewCategory;
        } else {
            Category = formValues.Category;
        }
    }

    let FromTime = formValues.FromTime;
    let ToTime = formValues.ToTime;

    if(FromTime && FromTime.split(':').length > 2) {
        let fromTime = FromTime.split(':');
        fromTime.pop();
        FromTime = fromTime.join(':');
    }

    if(ToTime && ToTime.split(':').length > 2) {
        let toTime = ToTime.split(':');
        toTime.pop();
        ToTime = toTime.join(':');
    }
    
    return { ...formValues, FromTime, ToTime, Category, Price };
};

export function* createPrice(action: AnyAction) {
    const create = actions.CREATE_PRICE;
    try {
        const response: AxiosResponse = yield apiClient.post(
            `/services/prices`,
            normalizeFormData(action.payload)
        );

        yield put(create.success(response));
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* editPrice(action: AnyAction) {
    const edit = actions.EDIT_PRICE;
    try {
        const response: AxiosResponse = yield apiClient.put(
            `/services/prices/${action.payload.Id}`,
            normalizeFormData(action.payload)
        );

        yield put(edit.success(response));
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* deletePrice({ payload, meta = {} }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/services/prices/${payload.Id}`, payload);

            yield put(actions.DELETE_PRICE.success(response));

            if (meta.redirectOnSuccess) {
                yield put(push('/prices'));
            }

            const pricesState: RootState['prices'] = yield select((state) => state.prices);
            // @ts-ignore
            const { requestActionPayload } = pricesState;
            yield put({
                type: actions.FETCH_PRICES.REQUEST,
                payload: requestActionPayload,
                meta: { requestIsHidden: true },
            });
        } catch (error) {
            yield put(actions.DELETE_PRICE.failure(throwSubmissionError(error)));
        }
    }
}

export default function* pricesSaga() {
    yield all([
        takeLatest(actions.FETCH_PRICES.REQUEST, prices),
        takeLatest(actions.CREATE_PRICE.REQUEST, createPrice),
        takeLatest(actions.DELETE_PRICE.REQUEST, deletePrice),
        takeLatest(actions.EDIT_PRICE.REQUEST, editPrice),
    ]);
}
