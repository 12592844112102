import styled from 'styled-components';

export const Pushable = styled.div<{ pushed: boolean; dimmed?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    margin-left: ${(props) => (props.pushed ? 'var(--pushable-width)' : 'none')};
    transition: all 0.5s ease, -webkit-transform 0.5s ease;
`;
