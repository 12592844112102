import React from 'react';
import { Field as ReduxFormField } from 'redux-form';
import Input from './Input';

// format=null???
// https://redux-form.com/7.4.2/docs/api/field.md/#-code-format-value-name-gt-formattedvalue-code-optional-
const Field: React.FC<any> = (props) => {
    return <ReduxFormField component={Input} format={null} {...props} />;
};

export default Field;
