import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Header, Modal, Button } from 'semantic-ui-react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import * as actions from '@/store/actions';
import { ApplicationState } from '@/store';
import CopyToClipboard from '@/components/CopyToClipboard';
import en from '@/locales/fallback-en.json';
import sv from '@/locales/fallback-sv.json';
import no from '@/locales/fallback-no.json';
import fi from '@/locales/fallback-fi.json';

interface Props extends LocalizeContextProps, ConnectedProps<typeof connector> {}

const CorrelationIdModal: React.FC<Props> = ({
    dispatch,
    translate,
    errors,
    addTranslationForLanguage,
    activeLanguage,
}) => {
    useEffect(() => {
        if (activeLanguage.code === 'en') {
            addTranslationForLanguage(en, activeLanguage.code);
        } else if (activeLanguage.code === 'sv') {
            addTranslationForLanguage(sv, activeLanguage.code);
        } else if (activeLanguage.code === 'no') {
            addTranslationForLanguage(no, activeLanguage.code);
        } else if (activeLanguage.code === 'fi') {
            addTranslationForLanguage(fi, activeLanguage.code);
        } else {
            addTranslationForLanguage(sv, 'sv');
        }
    }, [activeLanguage.code]);
    
    const formattedErrors = errors.map(
        (error) => `${error.text}${error.correlationId ? ` - ${error.correlationId}` : ''}`
    );

    return (
        <Modal
            open
            onClose={() => {
                dispatch(actions.hideCorrelationIdModal());
            }}
            size="small"
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <Header icon="warning" content={translate('correlationIdModal.header')} />
                <p>{translate('correlationIdModal.followingError')}</p>
                <p className="error-msg">
                    <code>
                        <CopyToClipboard text={formattedErrors.join('\n')}>
                            {formattedErrors.join('\n')}
                        </CopyToClipboard>
                    </code>
                </p>

                <p>{translate('correlationIdModal.content')}</p>
                <ul>
                    <li>{translate('correlationIdModal.content.1')}</li>
                    <li>
                        {translate(
                            'correlationIdModal.content.2',
                            { link: window.location.origin },
                            { renderInnerHtml: true }
                        )}
                    </li>
                    <li>
                        {translate(
                            'correlationIdModal.content.3',
                            {
                                subject: encodeURIComponent(`"${formattedErrors.join('\n').substring(0, 23).concat('...')}"`),
                                body: encodeURIComponent(formattedErrors.join('\n')),
                            },
                            { renderInnerHtml: true }
                        )}
                    </li>
                </ul>

                <Button
                    floated="right"
                    onClick={() => {
                        window.location.href = '/';
                    }}
                >
                    {translate('correlationModal.reload')}
                </Button>
            </Modal.Content>
        </Modal>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        errors: state.correlationId.entity,
    };
};

const connector = connect(mapStateToProps);

export default connector(withLocalize(CorrelationIdModal));
