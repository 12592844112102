import React, { Component, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
    Grid,
    Form as UIForm,
    Header,
    Icon,
    Dropdown,
    Segment,
    List,
    ListItem,
    ListContent,
    ListIcon,
    AccordionTitle,
    AccordionContent,
    Accordion,
    AccordionTitleProps,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { LocalizeContextProps, TranslateFunction, withLocalize } from 'react-localize-redux';

import { FieldLabel } from '@/components/Input';
import CustomFields from '@/components/CustomFields';
import Form, { FormProps } from '@/components/Form';
import Field from '@/components/Field';
import Truncate from '@/components/Truncate';
import { FolderTypes } from '@/utils/constants';
import * as actions from '@/store/actions';
import { ListState, Service, ServiceGroup, CustomField } from '@/types';

import { EditModeToggle, SliderWrapper } from './styled';
import { Box, Flex } from 'grid-styled';
import { range } from 'lodash';
import {
    getDurationComputed,
    convertToFormatOptions,
    formatMinutesToWords,
    getDurationBaseUnit,
    formatTimeToWords,
} from '@/utils/datetime';
import Option from './Option';

import { FlexibleSlider } from './FlexibleSlider';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { operations } from '@/apitypes';
import { ServiceDurationFormPartial } from './ServiceInformation';

const ServiceName = styled.p`
    font-size: 1.6em;
`;
const ServiceGroupEl = styled.div`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.gray};
`;
const Description = styled.p`
    color: ${({ theme }) => theme.secondary};
`;

const CozyInputFieldVariantContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 767px) {
        flex-grow: 1;
    }
    & .input {
        min-width: 100%;
        @media only screen and (min-width: 768px) {
            min-width: 320px;
        }
    }
`;

const SmallNumberInput = styled.div`
    .ui.action input[type='number'] {
        max-width: 96px;
        width: auto !important;
    }
`;

const { Row, Column } = Grid;

interface OuterProps {
    service: Service;
    serviceDurationTypes: ListState<
        Array<operations['DurationTypeQuerydurationtypes_Get']['responses']['200']['schema']>
    >;
    onToggleEdit: () => void;
    serviceGroups: ListState<ServiceGroup>;
    onSubmitSuccess: (result: any) => void;
}

interface Props extends OuterProps, LocalizeContextProps, RouteComponentProps<{ id: string }> {}

interface State {
    groupNameOpen: boolean;
}

class ServiceInformationForm extends Component<Props> {
    state: Readonly<State> = {
        groupNameOpen: false,
    };

    render() {
        const { translate } = this.props;

        return (
            <>
                <Header as="h2" content={translate('service.createAService')} />
                <Grid>
                    <Column computer={10} tablet={16}>
                        <Segment>{this.renderForm()}</Segment>
                    </Column>
                    <Column computer={6} tablet={16}>
                        <Segment>
                            <Header as="h3" content={translate('service.tips')} />
                            <TipsAccordion translate={translate} />
                        </Segment>
                    </Column>
                </Grid>
            </>
        );
    }

    renderForm = () => {
        const {
            onToggleEdit,
            translate,
            serviceGroups,
            service,
            onSubmitSuccess,
            serviceDurationTypes,
        } = this.props;
        
        let _DurationBaseUnit = getDurationBaseUnit(service.Duration);
        let _Duration = getDurationComputed(service.Duration, _DurationBaseUnit);

        const serviceDurationTypesOptions = serviceDurationTypes.data.map((sd) => ({
            value: sd.Id,
            text: Option(sd.Name!, sd.Description!),
        }));

        const initialValues: any = {
            ...service,
            ResourceTypes: [],
            Schedules: {
                RecurringSchedules: [],
                DateSchedules: []
            },
            _DurationBaseUnit,
            _Duration,
            _MinDuration: getDurationComputed(
                service.MinDuration || service.Duration,
                _DurationBaseUnit
            ),
            _MaxDuration: getDurationComputed(
                service.MaxDuration || service.Duration * 3,
                _DurationBaseUnit
            ),
            _DurationInterval: getDurationComputed(
                service.DurationInterval || service.Duration,
                _DurationBaseUnit
            ),
            _FlexibleHours: service.DurationTypeId || 1,
        };

        return (
            <Form
                name="serviceGeneral"
                initialValues={initialValues}
                action={actions.CREATE_SERVICE}
                attachActionBar={false}
                enableReinitialize={false}
                basic
                onCancel={onToggleEdit}
                customOnSubmitSuccess={onSubmitSuccess}
                confirmOnLeave
            >
                {({ formValues, change }: FormProps<any>) => {
                    const sliderMinDuration = parseInt(formValues._MinDuration);
                    const sliderDurationInterval = parseInt(formValues._DurationInterval);
                    const serviceDuration = parseInt(formValues.Duration);

                    const sliderMaxDuration =
                        (parseInt(formValues._MaxDuration) - sliderMinDuration) %
                            sliderDurationInterval ===
                        0
                            ? parseInt(formValues._MaxDuration)
                            : parseInt(formValues._MaxDuration) -
                              ((parseInt(formValues._MaxDuration) - sliderMinDuration) %
                                  sliderDurationInterval);

                    let _marks =
                        !isNaN(sliderMinDuration) &&
                        !isNaN(sliderMaxDuration) &&
                        !isNaN(sliderDurationInterval)
                            ? range(
                                  sliderMinDuration,
                                  sliderMaxDuration + 1,
                                  sliderDurationInterval
                              )
                            : [];

                    // TODO: convert curr to minutes
                    let marks = _marks.reduce(
                        (acc, curr) => {
                            acc[curr] = formatMinutesToWords(curr, {
                                abbreaviated: _marks.length >= 7 ? true : false,
                                unit: convertToFormatOptions(formValues._DurationBaseUnit),
                            });

                            return acc;
                        },
                        {} as {
                            [key in string]: any;
                        }
                    );

                    let defaultSliderValue = 0;

                    if (
                        isNaN(sliderMaxDuration) &&
                        isNaN(sliderDurationInterval) &&
                        !isNaN(serviceDuration)
                    ) {
                        defaultSliderValue = serviceDuration;
                    } else if (
                        !isNaN(sliderMaxDuration) &&
                        !isNaN(sliderDurationInterval) &&
                        !isNaN(serviceDuration)
                    ) {
                        defaultSliderValue =
                            serviceDuration < parseInt(formValues._MaxDuration)
                                ? serviceDuration
                                : sliderDurationInterval;
                    }

                    const filteredServiceGroups = serviceGroups.data.filter(
                        (group) =>
                            !formValues.Group ||
                            (group &&
                                formValues.Group &&
                                group.Name.toLowerCase().includes(formValues.Group.toLowerCase()))
                    );

                    const dropdownMarks = _marks.map((mark) => ({
                        value: mark,
                        text: formatTimeToWords(
                            mark,
                            (convertToFormatOptions(formValues._DurationBaseUnit) as any) ||
                                'minute',
                            translate
                        ),
                    }));

                    return (
                        <Grid>
                            <Grid.Column computer={8}>
                                <Field
                                    required
                                    name="Name"
                                    label={translate('service.Name.text')}
                                    type="text"
                                />
                                <Field
                                    required
                                    name="Description"
                                    label={translate('service.Description.text')}
                                    type="textarea"
                                />
                            </Grid.Column>

                            <Grid.Column computer={8}>
                                <Box pt="4">
                                    <Flex style={{ justifyContent: 'center' }}>
                                        <Field
                                            name="ImageUrl"
                                            type="image"
                                            folderType={FolderTypes.SERVICE}
                                        />
                                    </Flex>
                                </Box>
                            </Grid.Column>

                            <Grid.Column computer={16}>
                                <CustomFields name="CustomFields" />
                                <UIForm.Group>
                                    <Field
                                        data-cy="flexible-hours-select"
                                        name="_FlexibleHours"
                                        type="select"
                                        label={translate('service.FlexibleHours.text')}
                                        options={serviceDurationTypesOptions}
                                        style={{ width: '320px'}}
                                    />
                                </UIForm.Group>
                                <UIForm.Group>
                                    {formValues._FlexibleHours !== 1 ? (
                                        <Flex flexDirection={'column'} style={{ flex: 1 }}>
                                            <Flex flexDirection={'column'} style={{ gap: '8px', paddingLeft: '0.5em', paddingRight: '0.5em' }}>
                                                <UIForm.Field>
                                                    <SmallNumberInput>
                                                        <Field
                                                            icon="time"
                                                            iconPosition="left"
                                                            name="_Duration"
                                                            type="number"
                                                            min={1}
                                                            style={{ marginBottom: '8px' }}
                                                            label={translate(
                                                                'service.DefaultDuration.text'
                                                            )}
                                                            required
                                                            action={
                                                                <Field
                                                                    data-cy="duration-base-unit-dropdown"
                                                                    name="_DurationBaseUnit"
                                                                    type="dropdown"
                                                                    withoutWrapper
                                                                    button
                                                                    compact
                                                                    options={[
                                                                        {
                                                                            value: 'minutes',
                                                                            text: translate(
                                                                                'common.minutes'
                                                                            ),
                                                                        },
                                                                        {
                                                                            value: 'hours',
                                                                            text: translate(
                                                                                'common.hours'
                                                                            ),
                                                                        },
                                                                        {
                                                                            value: 'days',
                                                                            text: translate(
                                                                                'common.days'
                                                                            ),
                                                                        },
                                                                    ]}
                                                                />
                                                            }
                                                        />
                                                    </SmallNumberInput>
                                                </UIForm.Field>
                                            </Flex>
                                            <Flex
                                                style={{
                                                    flexWrap: 'wrap',
                                                    flex: 1,
                                                    paddingLeft: '0.5em',
                                                    paddingRight: '0.5em',
                                                }}
                                            >
                                               <ServiceDurationFormPartial translate={translate} />
                                            </Flex>
                                            <Box style={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}>
                                                {Object.keys(marks).length > 0 &&
                                                formValues._FlexibleHours === 2 ? (
                                                    <>
                                                        <p>
                                                            {translate('service.previewFlexibleHours')}
                                                            {_DurationBaseUnit ? (
                                                                <span>
                                                                    {' '}
                                                                    (
                                                                    {translate(
                                                                        'service.previewFlexibleHours.shownIn'
                                                                    )}{' '}
                                                                    {convertToFormatOptions(
                                                                        formValues._DurationBaseUnit
                                                                    ) === 'day'
                                                                        ? translate('common.days')
                                                                        : convertToFormatOptions(
                                                                            formValues._DurationBaseUnit
                                                                        ) === 'hour'
                                                                        ? translate('common.hours')
                                                                        : convertToFormatOptions(
                                                                            formValues._DurationBaseUnit
                                                                        ) === 'minute'
                                                                        ? translate('common.minutes')
                                                                        : null}
                                                                    )
                                                                </span>
                                                            ) : null}
                                                        </p>
                                                        <FlexibleSlider
                                                            maxDuration={sliderMaxDuration}
                                                            minDuration={parseInt(
                                                                formValues._MinDuration
                                                            )}
                                                            durationInterval={parseInt(
                                                                formValues._DurationInterval
                                                            )}
                                                            defaultValue={defaultSliderValue}
                                                            marks={marks}
                                                            unit={_DurationBaseUnit}
                                                        />
                                                    </>
                                                ) : null}
                                                {Object.keys(marks).length > 0 &&
                                                formValues._FlexibleHours === 3 ? (
                                                        <SliderWrapper style={{ maxWidth: '320px', padding: 0 }}>
                                                            <p>
                                                                {translate(
                                                                    'service.previewFlexibleHours'
                                                                )}
                                                                {_DurationBaseUnit ? (
                                                                    <span>
                                                                        {' '}
                                                                        (
                                                                        {translate(
                                                                            'service.previewFlexibleHours.shownIn'
                                                                        )}{' '}
                                                                        {convertToFormatOptions(
                                                                            formValues._DurationBaseUnit
                                                                        ) === 'day'
                                                                            ? translate('common.days')
                                                                            : convertToFormatOptions(
                                                                                formValues._DurationBaseUnit
                                                                            ) === 'hour'
                                                                            ? translate('common.hours')
                                                                            : convertToFormatOptions(
                                                                                formValues._DurationBaseUnit
                                                                            ) === 'minute'
                                                                            ? translate(
                                                                                'common.minutes'
                                                                            )
                                                                            : null}
                                                                        )
                                                                    </span>
                                                                ) : null}
                                                            </p>
                                                            <select
                                                                data-cy="flexible-hours-dropdown"
                                                                style={{
                                                                    width: 'auto',
                                                                    paddingRight: '30px'
                                                                }}
                                                            >
                                                                {dropdownMarks.map(
                                                                    (mark: any, i: number) => {
                                                                        return (
                                                                            <option
                                                                                key={i}
                                                                                value={mark.value}
                                                                            >
                                                                                {mark.text}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </select>
                                                        </SliderWrapper>
                                                    
                                                ) : null}
                                            </Box>
                                        </Flex>
                                    ) : (
                                        <UIForm.Field>
                                            <SmallNumberInput>
                                                <Field
                                                    icon="time"
                                                    iconPosition="left"
                                                    name="_Duration"
                                                    required
                                                    type="number"
                                                    min={1}
                                                    label={translate(
                                                        'service.DefaultDuration.text'
                                                    )}
                                                    action={
                                                        <Field
                                                            name="_DurationBaseUnit"
                                                            type="dropdown"
                                                            withoutWrapper
                                                            button
                                                            compact
                                                            options={[
                                                                {
                                                                    value: 'minutes',
                                                                    text: translate('common.minutes'),
                                                                },
                                                                {
                                                                    value: 'hours',
                                                                    text: translate('common.hours'),
                                                                },
                                                                {
                                                                    value: 'days',
                                                                    text: translate('common.days'),
                                                                },
                                                            ]}
                                                        />
                                                    }
                                                />
                                            </SmallNumberInput>
                                        </UIForm.Field>
                                    )}
                                </UIForm.Group>

                                <UIForm.Group>
                                    <Field
                                        label={translate('service.SortOrder.text')}
                                        addon="icon-pin"
                                        name="SortOrder"
                                        type="number"
                                    />
                                </UIForm.Group>
                                <UIForm.Group>
                                    <UIForm.Field>
                                        <CozyInputFieldVariantContainer>
                                            <Field
                                                data-testid="group-search"
                                                autoComplete="off"
                                                name="Group"
                                                type="input"
                                                icon="dropdown"
                                                iconPosition="right"
                                                clearable
                                                label={translate('service.Group.existing.text')}
                                                onFocus={() => {
                                                    this.setState({ groupNameOpen: true });
                                                }}
                                                onBlur={(ev: any) => {
                                                    setTimeout(() => {
                                                        this.setState({ groupNameOpen: false });
                                                    }, 200);
                                                }}
                                            />
                                            <Dropdown
                                                data-testid="group-select-dropdown"
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '-20px',
                                                    width: 'calc(100% - 1em)',
                                                }}
                                                scrolling
                                                icon={null}
                                                open={this.state.groupNameOpen}
                                                onClick={(ev) => {
                                                    this.setState({ groupNameOpen: false });
                                                }}
                                            >
                                                <Dropdown.Menu
                                                    style={{
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom:
                                                            filteredServiceGroups.length < 5
                                                                ? `-${
                                                                      filteredServiceGroups.length *
                                                                          36 -
                                                                      16
                                                                  }px`
                                                                : '-200px',
                                                    }}
                                                >
                                                    {filteredServiceGroups.map((group) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={`${group.Name}`}
                                                                value={group.Name}
                                                                text={group.Name}
                                                                onClick={(e) => {
                                                                    change('Group', group.Name);
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </CozyInputFieldVariantContainer>
                                    </UIForm.Field>
                                </UIForm.Group>
                                <UIForm.Group>
                                    <UIForm.Field>
                                        <CozyInputFieldVariantContainer>
                                            <Field
                                                label={translate('service.Group.new.text')}
                                                addon="icon-pin"
                                                name="newGroup"
                                                type="text"
                                            />
                                        </CozyInputFieldVariantContainer>
                                    </UIForm.Field>
                                </UIForm.Group>
                            </Grid.Column>
                        </Grid>
                    );
                }}
            </Form>
        );
    };
}

const TipsAccordion: React.FC<{ translate: TranslateFunction }> = ({ translate }) => {
    const [activeIndex, setActiveIndex] = useState<undefined | number | string>();

    const handleClick = (e: any, titleProps: AccordionTitleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        setActiveIndex(newIndex);
    };

    return (
        <Accordion className='show-strong'>
            <AccordionTitle index={0} active={activeIndex === 0} onClick={handleClick}>
                <Icon name="dropdown" />
                {translate('service.tip1.heading')}
            </AccordionTitle>
            <AccordionContent active={activeIndex === 0}>{translate('service.tip1.content', {}, { renderInnerHtml: true })}</AccordionContent>
        </Accordion>
    );
};

export default compose(
    withRouter,
    withLocalize,
    connect()
)(ServiceInformationForm) as React.ComponentType<OuterProps>;
