import React, { useEffect, useMemo } from 'react';
import { Header, SemanticICONS } from 'semantic-ui-react';
import styled from 'styled-components';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Box } from 'grid-styled';

// This component do 3 functions:
// 1. Adds breadcrumbs with help of react-breadcrumbs-dynamic.
//    Breadcrumbs are proxied to an external component
// 2. Adds header for the page with additional content
// 3. Adds document title

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 600px) {
        display: block;
    }
`;

interface Breadcrumb {
    content?: React.ReactNode;
    to?: string;
}

interface Props {
    icon?: SemanticICONS | React.ReactElement;
    breadcrumbs: Array<Breadcrumb>;
    content?: React.ReactNode;
    additionalContent?: React.ReactNode;
    description?: React.ReactNode;
    visible?: boolean;
    as?: any;
}

const PageHeader: React.FC<Props> = (props) => {
    const { icon, breadcrumbs = [], description, content, visible = true, as = 'h2', additionalContent } = props;

    const title = useMemo(() => breadcrumbs[0] && breadcrumbs[breadcrumbs.length - 1].content, [
        breadcrumbs,
    ]);

    useEffect(() => {
        document.title = `${!!title ? `${title} - ` : ''}BokaMera`;
    }, [breadcrumbs, title]);

    //TRANSLATE the page header using common.BokaMera
    return (
        <div>
            {breadcrumbs.map((breadcrumb, index) => (
                <BreadcrumbsItem key={index} to={breadcrumb.to || '#'}>
                    {breadcrumb.content}
                </BreadcrumbsItem>
            ))}
            {visible && (
                <Container>
                    <Box style={{ minWidth: 0 }} mt={36} mb={24}>
                        <Header style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} icon={icon} content={title} subheader={description} as={as} />
                    </Box>
                    <Box mt={36} mb={24} flex="1 1 auto" className="clearfix">
                        {content}
                        {additionalContent}
                    </Box>
                </Container>
            )}
        </div>
    );
};

export default PageHeader;
