import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { isEqual } from 'lodash';
import DataTable, { Column, Action } from '@/components/DataTable';
import { format, toDate, setISODay, setHours, setMinutes, setSeconds } from '@/utils/datetime';
import { ArticlePrice, EaccountingArticle, Paging, Price, ServicePriceMapping } from '@/types';
import { Button, Container, Segment, Select } from 'semantic-ui-react';
import classnames from 'classnames/bind';
import styles from './PriceArticleMappingsTable.module.css';
import { UpdatedPrices } from '@/store/reducers/eaccounting';
import ArticleOption from './ArticleOption';

const cx = classnames.bind(styles);

const renderPercent = (number: number) => `${number * 100}%`;

const renderVAT = (row: any) => renderPercent(row.VAT);

const vatPercentRenderer = (row: Price) => (
    `${row.VAT * 100}%`
);

export const parseTime = (time: string) => {
    const strs = time.split(':');
    let dateObj = new Date();

    dateObj = setHours(dateObj, parseInt(strs[0]));
    dateObj = setMinutes(dateObj, parseInt(strs[1]));
    dateObj = setSeconds(dateObj, parseInt(strs[2]));

    return dateObj;
};

interface Props extends LocalizeContextProps {
    articles: EaccountingArticle[];
    prices: Price[];
    priceMappings: ServicePriceMapping[];
    updatedPrices: UpdatedPrices;
    onUpdateMapping: (price: ArticlePrice) => void;
    onRemoveMapping: (id: string) => void
    renderSaveButton: () => React.ReactElement
}


const descriptionRenderer = (row: ArticlePrice & { _serviceName: string}) => (
    row._serviceName ?  row._serviceName : ''
)

function PriceArticleMappingsTable(props: Props) {
    const { translate, onUpdateMapping, onRemoveMapping, articles, priceMappings ,updatedPrices, renderSaveButton } = props;
    let columns: Column[] = [];

    const rendererEaccountingArticle = (row: ArticlePrice, rowIndex: number) => {
        
        const matchedPrice = updatedPrices.changed 
            ? updatedPrices.entities.find(p => p.PriceId === row.Id)
            : priceMappings.find(p => p.PriceId === row.Id)

        let matchedArticle = articles.find(a => a.ArticleId === matchedPrice?.ExternalReference)

        let selectValueProp;
        
        if(matchedArticle?.ArticleId && updatedPrices.entities.length > 0 && updatedPrices.entities.map(p => p.ExternalReference).includes(matchedArticle.ArticleId)) {
            selectValueProp = {value: matchedArticle?.ArticleId}
        }
        
        else if (matchedArticle?.ArticleId && updatedPrices.entities.length > 0 && !updatedPrices.entities.map(p => p.ExternalReference).includes(matchedArticle.ArticleId)) {
            selectValueProp = { value: undefined }
        }
        
        else if (matchedArticle?.ArticleId && updatedPrices.entities.length === 0) {
            selectValueProp = {value: matchedArticle?.ArticleId}
        }

        return row.PriceText ? (
            <div className={cx('dropdown-container')}>
                <Select
                    defaultUpward={rowIndex === props.prices.length}
                    // @ts-ignore
                    value={matchedArticle?.ArticleId}
                    // @ts-ignore
                    key={`${row.Id}-${selectValueProp?.value}-${row.ExternalReference}`}
                    data-cy={`select-mapping-article-${rowIndex}`}
                    options={articles
                        .filter((a) => {
                            // FIXME: type row correctly (with Price)
                            // @ts-ignore
                            return parseFloat(a.VatRatePercent) === parseFloat(row.VAT)
                        })
                        .map((a) => ({
                            value: a.ArticleId,
                            text: ArticleOption(a, translate, true),
                        }))}
                    clearable
                    placeholder={
                        translate('priceArticleMappingsTable.selectArticlePlaceholder') as string
                    }
                    onChange={(e, data) => {
                        // here we should discern add/change/remove
                        if (!data.value) {
                            onRemoveMapping(row.Id.toString());
                        } else if (typeof data.value === 'string') {
                            // debugger;
                            onUpdateMapping({
                                ...row,
                                ExternalReference: data.value,
                            });
                        }
                    }}
                />
            </div>
        ) : null;
    };

    columns = [
        ...columns,
        { name: 'Id', attribute: 'Id' },
        { name: translate('prices.serviceName'),renderer: descriptionRenderer },
        {
            name: translate('prices.Price'),
            attribute: 'PriceText',
        },
        {
            name: translate('prices.VAT'),
            renderer: vatPercentRenderer
        },
        { name: translate('prices.eaccountingArticle'), renderer: rendererEaccountingArticle  },
    ];

    const rowActions: Action[] = [];

    return (
            <Segment>
                <DataTable style={{ minHeight: '600px'}} columns={columns} data={props.prices} actions={rowActions} />
                {renderSaveButton()}
            </Segment>
    );
}




export default withLocalize(PriceArticleMappingsTable);
