import { AnyAction } from 'redux';
import { put, call, select } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { stopSubmit } from 'redux-form';
import { confirmSaga } from './confirm';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { push } from 'connected-react-router';
import { ApplicationState } from '@/store';

export function* homepageSettings() {
    try {
        const response: AxiosResponse = yield apiClient.get('/homepage/settings', {
            params: {
                IncludeHomePageTemplateOptions: true,
                IncludeHomePageHeroSectionStyleOptions: true,
            },
        });

        yield put(actions.FETCH_HOMEPAGE_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_HOMEPAGE_SETTINGS.failure(error));
    }
}

export function* editHomepageSettings({ payload }: AnyAction) {
    try {
        const location: Location = yield select((s: ApplicationState) => s.router.location);
        
        const response: AxiosResponse = yield apiClient.put('/homepage/settings', payload);

        yield put(actions.EDIT_HOMEPAGE_SETTINGS.success(response));

        const query = qs.parse(location.search.slice(1));

        if (query.afterSuccess) {
            yield put(push(query.afterSuccess as string))
        }
    } catch (error) {
        yield put(actions.EDIT_HOMEPAGE_SETTINGS.failure(throwSubmissionError(error)));
    }
}

const transformSchedulerFormData = (formData: any) => ({
    ...formData,
    Services: formData.Services.filter((item: any) => item.selected),
    Resources: formData.Resources.filter((item: any) => item.selected),
    Fields: formData.Fields.filter((item: any) => item.selected),
});

const areSomeCustomerFieldsSelected = (fields: any[] = []) => {
    return fields
        .filter((field) => [12, 13, 14, 15].includes(field.FieldId))
        .some((field) => field.selected);
};

export function* homepageWidgetSettings() {
    try {
        const response: AxiosResponse = yield apiClient.get('/homepage/widget/settings', {
            params: {
                IncludeServiceLayoutOptions: true,
                IncludeTimeLayoutOptions: true,
                IncludeBookingLayoutOptions: true,
                IncludeBookingMethodOptions: true,
            },
        });

        yield put(actions.FETCH_HOMEPAGE_WIDGET_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_HOMEPAGE_WIDGET_SETTINGS.failure(error));
    }
}

export function* editHomepageWidgetSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/homepage/widget/settings', payload);

        yield put(actions.EDIT_HOMEPAGE_WIDGET_SETTINGS.success(response));
    } catch (error) {
        yield put(actions.EDIT_HOMEPAGE_WIDGET_SETTINGS.failure(throwSubmissionError(error)));
    }
}
