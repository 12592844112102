import { AnyAction } from 'redux';
import { call, put, select } from 'redux-saga/effects';
import { getTranslate } from 'react-localize-redux';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { AxiosResponse } from 'axios';
import { SyncmailchimpQuery, SyncMailchimpResponse } from '@/types';
import { PayloadAction } from '@reduxjs/toolkit';
import bmApi from '@/services/bmApi';
import { confirmSaga } from './confirm';
import { ApplicationState } from '@/store';

export function* fetchMailchimpSettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/mailchimp/profile');

        yield put(actions.FETCH_MAILCHIMP_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_MAILCHIMP_SETTINGS.failure(error));
    }
}

export function* editMailchimpSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/mailchimp/profile', payload);

        yield put(bmApi.util.invalidateTags(['mailchimp']));

        yield put(actions.EDIT_MAILCHIMP_SETTINGS.success(response));
    } catch (error) {
        yield put(actions.EDIT_MAILCHIMP_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* syncMailchimpUsers({ payload }: PayloadAction<SyncmailchimpQuery>) {
    const localizeState: ApplicationState['localize'] = yield select((state) => state.localize);
    const translate = getTranslate(localizeState);

    const confirmed: boolean = yield call(confirmSaga);

    if (confirmed) {
        try {
            const response: AxiosResponse<SyncMailchimpResponse> = yield apiClient.put(
                '/mailchimp/member/company/customer/sync',
                payload
            );

            yield put(actions.SYNC_MAILCHIMP_USERS.success(response));

            yield call(
                confirmSaga,
                translate('mailchimpSettings.successfulSyncReport', {
                    successCount: response.data.SuccessSyncedMembers?.length,
                    failCount: response.data.FailedSyncedMembers?.length,
                }) as string,
                { justOk: true },
            );
        } catch (error) {
            yield put(actions.SYNC_MAILCHIMP_USERS.failure(throwSubmissionError(error)));
        }
    } else {
        yield put(actions.SYNC_MAILCHIMP_USERS.failure())
    }
}

