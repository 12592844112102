import { combineReducers, Reducer, AnyAction } from 'redux';
import { createSelector } from 'reselect';
import { createListLoadingReducer } from './utils';
import groupBy from 'lodash/groupBy';
import * as actions from '../actions';

export const selectNewsletterFieldTranslations = (state: any) =>
    state.newsletters.fieldTranslations;

export const getGroupedNewsletterFieldTranslations = createSelector(
    selectNewsletterFieldTranslations,
    (translations) => groupBy(translations.data, 'Group')
);

const test: Reducer<any, AnyAction> = (
    state = { isLoading: false, error: null },
    { type, payload }
) => {
    switch (type) {
        case actions.TEST_NEWSLETTER_TEMPLATE.REQUEST:
            return { ...state, isLoading: true, error: null };
        case actions.TEST_NEWSLETTER_TEMPLATE.SUCCESS:
            return { ...state, isLoading: false, error: null };
        case actions.TEST_NEWSLETTER_TEMPLATE.FAILURE:
            return { ...state, isLoading: false, error: payload };
        default:
            return state;
    }
};

export interface SendNewsletterResponse {
    Total: number;
    SendMethodId: 1 | 2;
}

const sent: Reducer<SendNewsletterResponse, AnyAction> = (
    state = { Total: 0, SendMethodId: 1 },
    { type, payload }
) => {
    switch (type) {
        case actions.SEND_NEWSLETTER.REQUEST:
        case actions.SEND_NEWSLETTER.FAILURE:
            return { Total: 0, SendMethodId: 1 };
        case actions.SEND_NEWSLETTER.SUCCESS:
            return { Total: payload.Total, SendMethodId: payload.SendMethodId };
        default:
            return state;
    }
};

export default combineReducers({
    log: createListLoadingReducer('NEWSLETTER_LOG'),
    customers: createListLoadingReducer('NEWSLETTER_CUSTOMERS'),
    templates: createListLoadingReducer('NEWSLETTER_TEMPLATES'),
    fieldTranslations: createListLoadingReducer('NEWSLETTER_FIELD_TRANSLATIONS'),
    sendMethods: createListLoadingReducer('NEWSLETTER_SEND_METHODS'),
    emailTemplates: createListLoadingReducer('NEWSLETTER_EMAIL_TEMPLATES'),
    test,
    sent,
});
