import React from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Segment, Grid, Message, Icon } from 'semantic-ui-react';

import Field from '@/components/Field';
import { FormProps } from '@/components/Form';
import { useGetMailchimpAudienceQuery } from '@/services/bmApi';

type Props = FormProps & LocalizeContextProps;

const WebhookSetttingsForm: React.FC<Props> = (props) => {
    const { translate } = props;
    
    return (
        <Segment attached="top">
            <Grid>
                <Grid.Column computer={8} mobile={16}>
                    <Field
                        name="Enabled"
                        type="checkbox"
                        toggle
                        slider
                        label={translate('webhookSettings.enabled')}
                    />
                </Grid.Column>
            </Grid>
            
        </Segment>
    );
};

export default withLocalize(WebhookSetttingsForm);
