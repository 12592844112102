import { createAction, createAsyncThunk, createListenerMiddleware, createSelector, createSlice, TypedStartListening } from '@reduxjs/toolkit';
import { definitions } from '@/apitypes';
import { CalculatePriceQuery, RebateCodePrice, RebateGetBySignResponse, Time, TotalPriceInformationResponse } from '@/types';
import { AxiosResponse } from 'axios';
import { apiClient, throwSubmissionError } from '@/utils';
import { reset as resetForm } from 'redux-form';
import { ApplicationDispatch, ApplicationState } from '@/store';
import Notifications from 'react-notification-system-redux';
import { getTranslate } from 'react-localize-redux';
import { toDate } from '@/utils/datetime';
import { DATE_QUANTITIES_PREFIX } from '@/containers/Booking/NewBooking/DateQuantities';

export interface PartialQuantity {
    Quantity?: number
    PriceId?: number
}
export type CalculatedPriceEntityElement = TotalPriceInformationResponse & PartialQuantity;
interface State {
    entity?: {
        calculatedPrice?: CalculatedPriceEntityElement[];
        appliedCodes?: RebateCodePrice[];
    };
    loading: boolean;
    error: any;
}

const initialState: State = {
    entity: undefined,
    loading: false,
    error: null,
};



interface CalculatePriceArgument {
    interval: Time,
    quantities: { Quantity: number, PriceId: number }[],
    customerEmail?: string,
    serviceId: number,
    rebateCodeIds?: number[]
}

export const calculatePriceListener = createListenerMiddleware();
export type CalculatePriceStartListening = TypedStartListening<ApplicationState, ApplicationDispatch>;
const startCalculatePriceListener = calculatePriceListener.startListening as CalculatePriceStartListening;

// Thunks

export const applyRebateCode = createAsyncThunk(
    '/services/calculatePrice/APPLY_REBATE_CODE',
    async ({
        rebateCodeSign,
        interval,
        quantities = [],
        customerEmail,
        serviceId,
    } : {
        rebateCodeSign: string,
        interval: Time,
        quantities: { Quantity: number; PriceId: number }[],
        customerEmail?: string,
        serviceId: number
    }, thunkApi) => {
        try {
            const state = thunkApi.getState() as ApplicationState;
            const translate = getTranslate(state.localize);
            const companyId = state.company.data?.Id;

            const alreadyAppliedCode =
                state.calculatePrice.entity?.calculatedPrice &&
                Object.values(state.calculatePrice.entity.calculatedPrice[0].AppliedCodes).some(p => 
                    p.RebateCodeSign === rebateCodeSign
                );
            
            if(alreadyAppliedCode) {
                return thunkApi.rejectWithValue(throwSubmissionError({
                    _error: translate('newBookingForm.codeAlreadyApplied')
                }));
            }

            const getbysignResponse: AxiosResponse<RebateGetBySignResponse> = await apiClient.get('/rebatecodes/getbysign', {
                params: {
                    RebateCodeSign: rebateCodeSign,
                    Date: toDate(new Date(interval.From)).toISOString(),
                    CustomerEmail: customerEmail,
                    ServiceId: serviceId,
                    CompanyId: companyId,
                },
            });

            const calculatePriceResponse: AxiosResponse<
                definitions['TotalPriceInformationResponse']
            > = await apiClient.put(`/services/${serviceId}/calculateprice`, {
                Id: serviceId,
                Interval: interval,
                Quantities: quantities || [],
                RebateCodeIds: [getbysignResponse.data.Id],
                CustomerEmail: customerEmail,
            });

            return thunkApi.fulfillWithValue(calculatePriceResponse.data as TotalPriceInformationResponse);
        } catch (error: any) {
            thunkApi.dispatch(
                Notifications.show(
                    { title: error.response?.data?.ResponseStatus?.Message as string },
                    'error'
                )
            );
            return thunkApi.rejectWithValue(throwSubmissionError(error));
        }
    }
);

export const removeRebateCode = createAsyncThunk(
    '/services/calculatePrice/REMOVE_REBATE_CODE',
    async ({
        rebateCodeSign,
        interval,
        quantities = [],
        customerEmail,
        serviceId
    } : {
        rebateCodeSign: string,
        interval: Time,
        quantities: { Quantity: number; PriceId: number }[],
        customerEmail: string,
        serviceId: number
    }, thunkApi) => {
        try {
            const state = thunkApi.getState() as ApplicationState;

            const calculatePriceResponse: AxiosResponse<
                definitions['TotalPriceInformationResponse']
            > = await apiClient.put(`/services/${serviceId}/calculateprice`, {
                Id: serviceId,
                Interval: interval,
                Quantities: quantities || [],
                RebateCodeIds: [],
                CustomerEmail: customerEmail,
            });

            return thunkApi.fulfillWithValue(calculatePriceResponse.data as TotalPriceInformationResponse);
        } catch (error: any) {
            thunkApi.dispatch(
                Notifications.show(
                    { title: error.response?.data?.ResponseStatus?.Message as string },
                    'error'
                )
            );
            return thunkApi.rejectWithValue(throwSubmissionError(error));
        }
    }
);

export const calculatePrice = createAsyncThunk(
    '/services/calculatePrice/CALCULATE_PRICE',
    async ({
        interval,
        quantities = [],
        customerEmail,
        serviceId,
        rebateCodeIds = []
    } : CalculatePriceArgument, thunkApi) => {
        

        const calculatePriceResponse: AxiosResponse<
            definitions['TotalPriceInformationResponse']
        > = await apiClient.put(`/services/${serviceId}/calculateprice`, {
            Id: serviceId,
            Interval: interval,
            Quantities: quantities || [],
            CustomerEmail: customerEmail,
            RebateCodeIds: rebateCodeIds
        } as CalculatePriceQuery);

        return thunkApi.fulfillWithValue(calculatePriceResponse.data as TotalPriceInformationResponse);

})

export const calculatePrices = createAsyncThunk(
    '/services/calculatePrice/CALCULATE_PRICES',
    async (priceDates : CalculatePriceArgument[], thunkApi) => {
        const response = await Promise.all(priceDates.map(priceDate => {
            return apiClient.put(`/services/${priceDate.serviceId}/calculateprice`, {
                Id: priceDate.serviceId,
                Interval: priceDate.interval,
                Quantities: priceDate.quantities || [],
                CustomerEmail: priceDate.customerEmail,
                RebateCodeIds: priceDate.rebateCodeIds
            } as CalculatePriceQuery)
        }));

        return thunkApi.fulfillWithValue(response.map(r => r.data) as TotalPriceInformationResponse[]);
    }
)

export const reset = createAction('/services/calculatePrice/RESET')

// Slice

const calcualtePriceSlice = createSlice({
    name: 'calculatePrice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(calculatePrices.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(calculatePrices.fulfilled, (state, action) => {
            state.entity = {
                ...state.entity,
                calculatedPrice: action.payload
            };
            state.loading = false;
            state.error = null;
        });

        builder.addCase(calculatePrices.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });

        builder.addCase(calculatePrice.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(calculatePrice.fulfilled, (state, action) => {
            state.entity = {
                ...state.entity,
                calculatedPrice: [action.payload]
            };
            state.loading = false;
            state.error = null;
        });

        builder.addCase(calculatePrice.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });

        builder.addCase(applyRebateCode.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(applyRebateCode.fulfilled, (state, action) => {
            state.loading = false;
            if(state.entity) {
                state.entity.appliedCodes = [action.payload];
            }
        });

        builder.addCase(applyRebateCode.rejected, (state, action) => {
            state.loading = false;
        });


        builder.addCase(removeRebateCode.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(removeRebateCode.fulfilled, (state, action) => {
            state.loading = false;
            if(state.entity) {
                state.entity.appliedCodes = undefined;
            }
        });

        builder.addCase(removeRebateCode.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(reset, (state) => {
            state.entity = initialState.entity;
            state.error = initialState.error;
            state.loading = initialState.loading;
        })

    },
});

startCalculatePriceListener({
    matcher: calculatePrice.fulfilled.match,
    effect: async (action, api) => {
        const state = api.getState();
        
        if(!state.calculatePrice.loading && state.calculatePrice.entity) {
            api.dispatch({
                type: 'UPDATE_SERVICE_QUANTITIES',
                payload: []
            })
        }
    }
});

// Listeners

startCalculatePriceListener({
    matcher: removeRebateCode.fulfilled.match,
    effect: async (_, api) => {
        api.dispatch(resetForm('promoCodes'));
    }
});

startCalculatePriceListener({
    matcher: calculatePrice.fulfilled.match,
    effect: async (action, api) => {
        const appliedCode = getAppliedCode(api.getState());
        
        if(appliedCode) {
            api.dispatch(applyRebateCode({
                rebateCodeSign: appliedCode.RebateCodeSign!,
                interval: action.meta.arg.interval,
                serviceId:  action.meta.arg.serviceId,
                customerEmail:  action.meta.arg.customerEmail,
                quantities:  action.meta.arg.quantities
            }))
        }
    }
});

startCalculatePriceListener({
    matcher: calculatePrices.fulfilled.match,
    effect: async (action, api) => {
        const appliedCode = getAppliedCode(api.getState());
        
        if(appliedCode) {
            api.dispatch(applyRebateCode({
                rebateCodeSign: appliedCode.RebateCodeSign!,
                interval: action.meta.arg[0].interval,
                serviceId:  action.meta.arg[0].serviceId,
                customerEmail:  action.meta.arg[0].customerEmail,
                quantities:  action.meta.arg.reduce((acc, curr) => {
                    acc.push(...curr.quantities);
                    return acc;
                }, [] as Required<PartialQuantity>[])
            }))
        }
    }
});

// Selectors

const selectSelf = (state: ApplicationState) => state

export const getAppliedCodeIds = createSelector(selectSelf, (state) => {
    return state.calculatePrice.entity?.appliedCodes && state.calculatePrice.entity?.appliedCodes[0]?.AppliedCodes
        ? state.calculatePrice.entity.appliedCodes[0].AppliedCodes.map(appliedCode => appliedCode.RebateCodeId) || []
        : [];
});

export const getAppliedCode = createSelector(selectSelf, (state) => {
    return state.calculatePrice.entity?.appliedCodes && state.calculatePrice.entity.appliedCodes[0]?.AppliedCodes
        ? state.calculatePrice.entity.appliedCodes[0].AppliedCodes[0]
        : undefined;
});

export const getTotalQuantityForDate = createSelector(
    [selectSelf, (_, from: string, to: string) => [from, to]],
    (state, [from, to]) => {
        return Object.entries(state.form).reduce((acc, curr) => {
            if (curr[0].includes(DATE_QUANTITIES_PREFIX) && curr[0].includes(from) && curr[0].includes(to)) {
                if(curr[1].values) {
                    acc = acc + curr[1].values.quantity
                }
            }
            return acc;
        }, 0);
    }
);

export default calcualtePriceSlice;
