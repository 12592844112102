import React from 'react';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';

const Page = styled.div`
    background-color: ${(props) => props.theme.dark};
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default function LoadingPage() {
    return (
        <Page>
            <Loader data-testid='loader' active inverted />
        </Page>
    );
}
