import { combineReducers, Reducer, AnyAction } from 'redux';
import * as actions from '../actions';

const modalInitialState = {
    // For testing purposes
    // Show modal if initial pathname is 'licenses';
    show: window.location.href.split('/').slice(-1)[0] === 'licenses',
    data: {},
    status: null,
};

const modal: Reducer<any, AnyAction> = (state = modalInitialState, action) => {
    switch (action.type) {
        case actions.SHOW_AGREEMENT_MODAL:
            return {
                ...state,
                show: true,
                data: action.payload,
            };
        case actions.HIDE_AGREEMENT_MODAL:
            return {
                ...state,
                show: false,
                data: modalInitialState.data
            };
        default:
            return state;
    }
};

export default combineReducers({
    modal
});

