import { AnyAction } from 'redux';
import { put, call } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { confirmSaga } from './confirm';
import { AxiosResponse } from 'axios';

export function* customerComments({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(`/customers/${payload.CustomerId}/comments`, {
            params: payload,
        });

        yield put(actions.FETCH_CUSTOMER_COMMENTS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_CUSTOMER_COMMENTS.failure(error));
    }
}

export function* createCustomerComment({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post(`/customers/${payload.CustomerId}/comments`, payload);

        yield put(actions.CREATE_CUSTOMER_COMMENT.success(response));
        yield put(actions.FETCH_CUSTOMER_COMMENTS.request({ CustomerId: payload.CustomerId }));
    } catch (error) {
        yield put(actions.CREATE_CUSTOMER_COMMENT.failure(throwSubmissionError(error)));
    }
}

export function* editCustomerComment({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(`/customers/${payload.CustomerId}/comments`, payload);

        yield put(actions.EDIT_CUSTOMER_COMMENT.success(response));
    } catch (error) {
        yield put(actions.EDIT_CUSTOMER_COMMENT.failure(throwSubmissionError(error)));
    }
}

export function* deleteCustomerComment({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(
                `/customers/${payload.CustomerId}/comments/${payload.CommentId}`
            );

            yield put(actions.DELETE_CUSTOMER_COMMENT.success(response));
            yield put(actions.FETCH_CUSTOMER_COMMENTS.request({ CustomerId: payload.CustomerId }));
        } catch (error) {
            yield put(actions.DELETE_CUSTOMER_COMMENT.failure(throwSubmissionError(error)));
        }
    }
}
