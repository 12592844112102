import { combineReducers } from 'redux';
import { format } from '@/utils/datetime';
import groupBy from 'lodash/groupBy';
import { createListLoadingReducer } from './utils';
import { createSelector } from 'reselect';

export const getRecurringSchedules = (state: any) => state.schedules.recurring;

export const getScheduleTimesGroupedByDay = (scheduleId: string) =>
    createSelector(getRecurringSchedules, (schedules) => {
        const schedule = schedules.data.find(
            (schedule: any) => schedule.Id.toString() === scheduleId
        );
        if (schedule) {
            return groupBy(schedule.ScheduleDates, (dateObj) => format(dateObj.Date, 'yyyy-MM-dd'));
        } else {
            return {};
        }
    });

export default combineReducers({
    intervals: createListLoadingReducer('RECURRING_SCHEDULES_INTERVALS'),
    recurring: createListLoadingReducer('RECURRING_SCHEDULES'),
    date: createListLoadingReducer('DATE_SCHEDULES'),
});
