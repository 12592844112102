import * as actions from '../actions';
import { Reducer, AnyAction } from 'redux';

const initialState = {
    open: false,
    message: false,
};

const reducer: Reducer<any, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
        case actions.SHOW_CONFIRMATION:
            return { ...state, open: true, message: action.payload, meta: action.meta };
        case actions.HIDE_CONFIRMATION:
            return { ...state, open: false, message: null, meta: null };
        default:
            return state;
    }
};

export default reducer;
