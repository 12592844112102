import { AnyAction } from 'redux';
import { put, select } from 'redux-saga/effects';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { push } from 'connected-react-router';
import { ApplicationState } from '@/store';

// Payments settings
export function* fetchPaymentSettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/payment/settings', {
            params: { IncludeAdminPaymentOptions: true, IncludePaymentProviderOptions: true },
        });

        yield put(actions.FETCH_PAYMENT_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_PAYMENT_SETTINGS.failure(error));
    }
}

export function* editPaymentSettings({ payload }: AnyAction) {
    try {
        const location: Location = yield select((s: ApplicationState) => s.router.location);
        const response: AxiosResponse = yield apiClient.put('/payment/settings', payload);

        yield put(actions.EDIT_PAYMENT_SETTINGS.success(response));

        const query = qs.parse(location.search.slice(1));

        if (query.afterSuccess) {
            yield put(push(query.afterSuccess as string))
        }
    } catch (error) {
        yield put(actions.EDIT_PAYMENT_SETTINGS.failure(throwSubmissionError(error)));
    }
}

// Payson API settings
export function* fetchPaysonAPISettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/payment/payson/apisettings');

        yield put(actions.FETCH_PAYSON_API_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_PAYSON_API_SETTINGS.failure(error));
    }
}

export function* createPaysonAPISettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/payment/payson/apisettings', payload);

        yield put(actions.CREATE_PAYSON_API_SETTINGS.success(response));
    } catch (error) {
        yield put(actions.CREATE_PAYSON_API_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* editPaysonAPISettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/payment/payson/apisettings', payload);

        yield put(actions.EDIT_PAYSON_API_SETTINGS.success(response));
    } catch (error) {
        yield put(actions.EDIT_PAYSON_API_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* testPaysonAPISettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/payment/payson/apisettings/test', payload);

        yield put(actions.FETCH_TEST_PAYSON_API_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_TEST_PAYSON_API_SETTINGS.failure(error));
    }
}

// Billmate API settings
export function* fetchBillmateAPISettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/payment/billmate/apisettings');

        yield put(actions.FETCH_BILLMATE_API_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_BILLMATE_API_SETTINGS.failure(error));
    }
}

export function* createBillmateAPISettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/payment/billmate/apisettings', payload);

        yield put(actions.CREATE_BILLMATE_API_SETTINGS.success(response));
    } catch (error) {
        yield put(actions.CREATE_BILLMATE_API_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* editBillmateAPISettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/payment/billmate/apisettings', payload);

        yield put(actions.EDIT_BILLMATE_API_SETTINGS.success(response));
    } catch (error) {
        yield put(actions.EDIT_BILLMATE_API_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* testBillmateAPISettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/payment/billmate/apisettings/test', payload);

        yield put(actions.FETCH_TEST_BILLMATE_API_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_TEST_BILLMATE_API_SETTINGS.failure(error));
    }
}
