import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from 'semantic-ui-react';
import ReactQuill, { Quill } from 'react-quill';
import Notifications from 'react-notification-system-redux';

import { WrappedFieldProps } from 'redux-form';
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic';
import { apiClient } from '@/utils';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

interface Props extends WrappedFieldProps {
    width: SemanticWIDTHS;
    style: Object;
    label?: string;
    dispatch: any;
    translate: any;
    image?: boolean;
}

let BlockEmbed = Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
  static create(value: any) {
    let node = super.create();
    if(value.url) {
        node.setAttribute('src', value.url);
    } else {
        node.setAttribute('src', value);
    }
    if(value.alt) {
        node.setAttribute('alt', value.alt);
    }
    if(value.style) {
        node.setAttribute('style', value.style);
    }
    
    return node;
  }

  static value(node: any) {
    return {
      alt: node.getAttribute('alt'),
      url: node.getAttribute('src'),
      style: node.getAttribute('style')
    };
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';

Quill.register(ImageBlot);


const QuillField: React.FC<Props> = ({ width, input, label, style = {}, image = false, ...rest }) => {
    const ref = useRef(null);
    const {dispatch, translate} = rest;

    const modules = useMemo(
        () => {
            let modules;
            
            if(image) {
                modules = {
                    toolbar: {
                        handlers: {
                            image: function (a: any) {
                                const input = document.createElement('input');
                                input.setAttribute('type', 'file');
                                input.setAttribute('accept', 'image/*');
                                input.click();
        
                                input.onchange = () => {
                                    // @ts-ignore
                                    const file = input.files[0];
        
                                    const formData = new FormData();
                                    formData.append('FolderType', '1');
                                    formData.append('file', file);
        
                                    if(file.size > 100000) {
                                        dispatch(Notifications.show({ title: translate('ritchTextField.fileUploadSizeLimitWarning') as string }, 'warning'));
                                        return;
                                    }
        
                                    // file type is only image.
                                    if (/^image\//.test(file.type) && ref.current) {
                                        apiClient.post('/images', formData)
                                        .then((r) => {
                                            // @ts-ignore
                                            const editor = ref.current.getEditor();
                                            const range = editor.getSelection();
                                            editor.insertEmbed(range.index, 'image', { url: r.data.FilePath, style: 'max-width: 60px; height: auto'});
                                        })
                                        .catch(err => {
                                            dispatch(Notifications.show({ title: err.response?.data?.ResponseStatus?.Message as string }, 'error'));
                                            return;
                                        })
                                    } else {
                                        dispatch(Notifications.show({ title: translate('ritchTextField.onlyImagesAllowedWarning') as string }, 'warning'));
                                    }
                                };
                            },
                        },
                        container: [
                            [{ header: [1, 2, false] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                            ['link', 'image'],
                            ['clean'],
                        ],
                    },
                    clipboard: {
                        matchVisual: false,
                    },
                }
            } else {
                modules = {
                    toolbar: {
                        container: [
                            [{ header: [1, 2, false] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                            ['link'],
                            ['clean'],
                        ],
                    },
                    clipboard: {
                        matchVisual: false,
                    },
                }
            }

            return modules;
        },
        [image]
    );

    const onChange = useCallback(function(newValue: any, delta: any, source: any){       
        if(
            ref.current &&
            delta?.ops &&
            delta?.ops[delta?.ops.length - 1] &&
            delta?.ops[delta?.ops.length - 1]?.attributes?.link &&
            !isValidLink(delta?.ops[delta?.ops.length - 1]?.attributes?.link) &&
            !delta?.ops.find((a: any) => a?.insert?.image)
        ) {
            delta.ops.pop();
            // @ts-ignore
            const editor = ref?.current?.getEditor();
            editor.setContents(delta);
            setTimeout(() => {
                // @ts-ignore
                const editor = ref?.current?.getEditor();
                editor.setSelection(editor.getLength());
            })
        }
        if (source === 'user') {
            input.onChange(newValue);
        }
        if(source === 'api' && delta?.ops.find((a: any) => a?.insert?.image)) {
            input.onChange(newValue);
        }
    }, [])

    const onBlur = useCallback((range: any, source: any, quill: any) => {
        input.onBlur(quill.getHTML());
    }, [])
    
    return (
        <Form.Field width={width}>
            {label && <label>{label}</label>}
            {/* @ts-ignore */}
            <ReactQuill
                {...input}
                ref={ref}
                value={input.value || ''}
                modules={modules}
                formats={[
                    'header',
                    'font',
                    'size',
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'blockquote',
                    'list',
                    'bullet',
                    'indent',
                    'link',
                    'image',
                    'video',
                ]}
                {...rest}
                onChange={onChange}
                onBlur={onBlur}
            />
        </Form.Field>
    );
};



const isValidLink = (value?: string) => {
    if(!value) return false

    if (
        value.includes('http://') ||
        value.includes('https://') || 
        value.includes('mailto:')
    ) {
        return true
    }

    return false
}

const mapStateToProps = (state: any) => {
    
    return {
        translate: getTranslate(state.localize),
    }
}

export default connect(mapStateToProps)(QuillField);
