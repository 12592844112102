import { getVismaIconName } from '@/utils/common';
import React from 'react';
import { SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props {
    icon?: SemanticICONS;
    color?: SemanticCOLORS;
    size?: 'sm' | 'lg';
    neutral?: boolean;
    dynamic?: boolean;
}

const IconWrapper = styled.span`
    .vismaicon {
        margin-right: 6px;
        cursor: pointer;
        &.red:before {
            background-color: var(--red-60);
        }
    }
    .vismaicon.icon-neutral:not(.vismaicon-filled)::before {
        background-color: var(--neutral-60);
    }

    .vismaicon.icon-white:not(.vismaicon-filled)::before {
        background-color: var(--neutral-05);
    }

    .vismaicon.icon-danger:not(.vismaicon-filled)::before {
        background-color: var(--red-70);
    }
`;
const ICON_CLASS = `vismaicon`;

export const VismaIcon: React.FC<React.HTMLProps<HTMLLIElement> & Props> = ({
    icon,
    size = 'sm',
    neutral = false,
    dynamic = false,
    color = '',
    ...restProps
}) => {
    if (!icon) {
        return null;
    }
    const classList = new Set();

    classList.add(ICON_CLASS);
    classList.add(`${ICON_CLASS}-${size}`);
    classList.add(`${ICON_CLASS}-${getVismaIconName(icon)}`);
    
    if(color) {
        classList.add(color);
    }

    if (neutral) {
        classList.add('icon-neutral');
    }

    if (dynamic) {
        classList.add(`${ICON_CLASS}-dynamic`);
    }

    return (
        <IconWrapper>
            <i className={Array.from(classList).join(' ')} aria-hidden="true" {...restProps} />
        </IconWrapper>
    );
};
