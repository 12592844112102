import { AnyAction } from 'redux';
import { put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { confirmSaga } from './confirm';
import { AxiosResponse } from 'axios';

export function* supportTickets({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/support/cases', {
            params: {
                OrderByDesc: 'Created',
                IncludeCaseStatusInformation: true,
                IncludeCaseTypeInformation: true,
                IncludeCaseAreaInformation: true,
                ...payload,
            },
        });

        yield put(actions.FETCH_SUPPORT_TICKETS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_SUPPORT_TICKETS.failure(error));
    }
}

export function* supportTicketStatuses({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/support/cases/status');

        yield put(actions.FETCH_SUPPORT_TICKET_STATUSES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_SUPPORT_TICKET_STATUSES.failure(error));
    }
}

export function* supportTicketAreas({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/support/cases/areas');

        yield put(actions.FETCH_SUPPORT_TICKET_AREAS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_SUPPORT_TICKET_AREAS.failure(error));
    }
}

export function* supportTicketTypes({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/support/cases/types');

        yield put(actions.FETCH_SUPPORT_TICKET_TYPES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_SUPPORT_TICKET_TYPES.failure(error));
    }
}

export function* createSupportTicket({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post(`/support/cases`, payload);

        yield put(actions.CREATE_SUPPORT_TICKET.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_SUPPORT_TICKET.failure(throwSubmissionError(error)));
    }
}

export function* editSupportTicket({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(`/support/cases/${payload.Id}`, payload);

        yield put(actions.EDIT_SUPPORT_TICKET.success(response.data));
    } catch (error) {
        yield put(actions.EDIT_SUPPORT_TICKET.failure(throwSubmissionError(error)));
    }
}

export function* deleteSupportTicket({ payload, meta: { confirmationMessage } }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga, confirmationMessage);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/support/cases/${payload.Id}`, payload);

            yield put(actions.DELETE_SUPPORT_TICKET.success(response.data));
            yield put(actions.FETCH_SUPPORT_TICKETS.request());
            yield put(push('/support/cases'));
        } catch (error) {
            yield put(actions.DELETE_SUPPORT_TICKET.failure(error));
        }
    }
}

export function* supportTicketComments({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(`/support/cases/${payload.CaseId}/comments`, {
            params: {
                OrderByDesc: 'Created',
            },
        });

        yield put(actions.FETCH_SUPPORT_TICKET_COMMENTS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_SUPPORT_TICKET_COMMENTS.failure(throwSubmissionError(error)));
    }
}

export function* createSupportTicketComment({ payload }: AnyAction) {
    try {
        const { CaseId } = payload;
        const response: AxiosResponse = yield apiClient.post(`/support/cases/${CaseId}/comments`, payload);

        yield put(actions.CREATE_SUPPORT_TICKET_COMMENT.success(response.data));
        yield put(actions.FETCH_SUPPORT_TICKET_COMMENTS.request({ CaseId }));
    } catch (error) {
        yield put(actions.CREATE_SUPPORT_TICKET_COMMENT.failure(throwSubmissionError(error)));
    }
}

export function* supportTicketAttachments({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(
            `/support/cases/${payload.CaseId}/attachments`,
            payload
        );

        yield put(actions.FETCH_SUPPORT_TICKET_ATTACHMENTS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_SUPPORT_TICKET_ATTACHMENTS.failure(throwSubmissionError(error)));
    }
}

export function* createSupportTicketAttachment({ payload }: AnyAction) {
    try {
        const { CaseId } = payload;
        const response: AxiosResponse = yield apiClient.post(`/support/cases/${CaseId}/attachments`, payload);

        yield put(actions.CREATE_SUPPORT_TICKET_ATTACHMENT.success(response.data));
        yield put(actions.FETCH_SUPPORT_TICKET_ATTACHMENTS.request({ CaseId }));
    } catch (error) {
        yield put(actions.CREATE_SUPPORT_TICKET_ATTACHMENT.failure(throwSubmissionError(error)));
    }
}

export function* deleteSupportTicketComment({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        const { CaseId } = payload;

        try {
            const response: AxiosResponse = yield apiClient.delete(
                `/support/cases/${CaseId}/comments/${payload.CommentId}`
            );

            yield put(actions.DELETE_SUPPORT_TICKET_COMMENT.success(response.data));
            yield put(actions.FETCH_SUPPORT_TICKET_COMMENTS.request({ CaseId }));
        } catch (error) {
            yield put(actions.DELETE_SUPPORT_TICKET_COMMENT.failure(throwSubmissionError(error)));
        }
    }
}
