import React, { useEffect } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Tab, TabProps } from 'semantic-ui-react';

import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import qs from 'qs';

import PageHeader from '@/components/PageHeader';

import PromoCodes from '@/containers/PromoCodes/Loadable'
import PromoCodesUsage from '@/containers/PromoCodesUsage/Loadable';
import TransactionsReport from '@/containers/TransactionsReport/Loadable';


interface Props extends RouteComponentProps, LocalizeContextProps {
    dispatch: Dispatch;
}

const PromoCodesValueCardsLoader: React.FC<Props> = (props) => {
    const { translate, location, history } = props;
    const { hash, search } = location;

    const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
    let { activeTab } = parsedSearch;

    useEffect(() => {
        if(activeTab) {
            delete parsedSearch.activeTab;
            history.push(`${location.pathname}?${qs.stringify(parsedSearch)}#${activeTab}`);
        }
    }, [activeTab])
    

    const handleTabChange = (e: React.MouseEvent, { activeIndex, panes }: TabProps) => {
        history.push(
            // Check if activeIndex property is valid
            // @ts-ignore
            `${location.pathname}#${panes[activeIndex].menuItem.key}`
        );
    };

    const panes = [
        {
            menuItem: {
                content: (
                    <div>
                        {translate('promoCodesValueCards.tab.promoCodes')}
                    </div>
                ),
                key: 'promoCodes',
            },
            render: () => (
                <Tab.Pane>
                    <PromoCodes />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                content: (
                    <div>
                        {translate('promoCodesValueCards.tab.promoCodesUsage')}
                    </div>
                ),
                key: 'promoCodesUsage',
            },
            render: () => (
                <Tab.Pane>
                    <PromoCodesUsage />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                content: (
                    <div>
                        {translate('promoCodesValueCards.tab.transactionsReport')}
                    </div>
                ),
                key: 'transactionsReport',
            },
            render: () => (
                <Tab.Pane>
                    <TransactionsReport />
                </Tab.Pane>
            ),
        }
    ];

    let activeHashIndex = panes.findIndex((pane) => pane.menuItem.key === hash.slice(1));
    activeHashIndex = activeHashIndex !== -1 ? activeHashIndex : 0;

    return (
        <>
            <PageHeader
                visible={false}
                breadcrumbs={[{ to: '/', content: translate('promoCodesValueCards.title') }]}
            />
            <Tab
                onTabChange={handleTabChange}
                panes={panes}
                activeIndex={activeHashIndex}
            />
        </>
    );
};


export default withLocalize(withRouter(PromoCodesValueCardsLoader));
