import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const PaddedSegment = styled(Segment)`
    && {
        padding-top: 3em;
        padding-bottom: 3em;
    }
    background-color: ${({ active, theme }) => (active ? theme.gray100 : '#fff')};
`;

interface EditModeToggleProps {
    floated?: 'left' | 'right';
    onClick?: () => void;
}

export const EditModeToggle = styled.span<EditModeToggleProps>`
    cursor: pointer;
    display: none;
    color: ${({ theme }) => theme.cyan};
    font-size: 14px;
    margin-left: 1em;
    float: ${({ floated }) => floated || 'none'};

    ${PaddedSegment}:hover & {
        display: inline;
    }
`;

export const CircleImage = styled.div<{ src: string }>`
    width: 144px;
    height: 144px;
    background-image: url(${({ src }) => src});
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin: auto;

    @media (max-width: ${({ theme }) => theme.breakpointmd}) {
        width: 72px;
        height: 72px;
    }
`;

export const SliderWrapper = styled.div`
    padding: 10px 0px 40px;

    .slider-wrapper {
        padding: 12px 0;
    }

    .rc-slider-rail {
        background-color: rgba(0, 0, 0, 0.05);
    }

    .rc-slider-track {
        background-color: transparent;
    }

    .rc-slider-handle {
        border-color: var(--green-60);
        background-color: var(--white);        
    }
    .rc-slider-handle:hover {
        box-shadow: 0 0 0 5px var(--green-60)!important;
        border-color: var(--green-30)!important;
    }
    .rc-slider-handle:focus-visible {
        box-shadow: 0 0 0 5px var(--green-60)!important;
        border-color: var(--green-30)!important;
    }
    .rc-slider-handle-dragging {
        box-shadow: 0 0 0 5px var(--green-60)!important;
        border-color: var(--green-30)!important;
    }
    .rc-slider-dot.rc-slider-dot-active {
        background-color: rgba(0, 0, 0, 0.05);
        border-color: rgba(0, 0, 0, 0.1);
    }

    .rc-slider-dot {
        background-color: rgba(0, 0, 0, 0.05);
        border-color: rgba(0, 0, 0, 0.1);
    }

    .rc-slider-mark-text-active,
    .rc-slider-mark-text {
        color: var(--black);
    }

    && select {
        /* Arrow */
        appearance: none;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat;
        background-position: right 12px top 50%;
        background-size: 8px auto;
    }

`;