import { put } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import { AxiosResponse } from 'axios';

export function* countries() {
    try {
        const response: AxiosResponse = yield apiClient.get('/countries');

        yield put(actions.FETCH_COUNTRIES.success({ Results: response.data }));
    } catch (error) {
        yield put(actions.FETCH_COUNTRIES.failure(error));
    }
}
