import { combineReducers } from 'redux';
import { createListLoadingReducer } from './utils';
import {
    SupportTicket,
    SupportTicketType,
    SupportTicketComment,
    SupportTicketStatus,
    SupportTicketAttachment,
    SupportTicketArea,
} from '@/types';

export default combineReducers({
    areas: createListLoadingReducer<SupportTicketArea>('SUPPORT_TICKET_AREAS'),
    attachments: createListLoadingReducer<SupportTicketAttachment>('SUPPORT_TICKET_ATTACHMENTS'),
    comments: createListLoadingReducer<SupportTicketComment>('SUPPORT_TICKET_COMMENTS'),
    statuses: createListLoadingReducer<SupportTicketStatus>('SUPPORT_TICKET_STATUSES'),
    tickets: createListLoadingReducer<SupportTicket>('SUPPORT_TICKETS'),
    types: createListLoadingReducer<SupportTicketType>('SUPPORT_TICKET_TYPES'),
});
