import React from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { connect, ConnectedProps } from 'react-redux';
import LogsTable from './Messages';
import useTranslate from '@/hooks/useTranslate';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends LocalizeContextProps, RouteComponentProps, ConnectedProps<typeof connector> {

}

const Endpoints: React.FC<Props> = (props) => {
    const { translate } = useTranslate();

    return (
        <>
            <LogsTable  />
        </>
    );
};

const connector = connect(null);

export default connector(withLocalize(Endpoints));


