import React, { useEffect } from 'react';
import {
    Label,
    Button,
    Input,
    TextArea,
    Icon,
    Segment,
    Message,
    Header,
    Grid,
} from 'semantic-ui-react';
import { definitions, operations } from '@/apitypes';
import { apiClient } from '@/utils';
import { FormProps } from '@/components/Form';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { FieldArray, Field, getFormValues, formValues } from 'redux-form';
import { Flex, Box } from 'grid-styled';
import Error from '@/components/Error';
import * as actions from '@/store/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '@/store';
import { ConnectedProps } from 'react-redux';
import InfoImgSv from '@/pngs/resource-group-example-1-sv.png';
import InfoImgEn from '@/pngs/resource-group-example-1-en.png';
import styled from 'styled-components';
import { getCurrentLanguageCode } from '@/utils/i18n';

interface Props extends LocalizeContextProps, ConnectedProps<typeof connector> {
    submitting: boolean;
}

const Img = styled.img`
    max-width: 100%;
    border: 1px solid var(--gray-200);
    border-radius: 12px;
    /* height: 15px; */
`;

const ResourceTypes: React.FC<Props> = (props) => {
    const { translate, ...restProps } = props;

    return (
        <>
            <FieldArray
                name="resources"
                component={FieldArraysForm}
                translate={translate}
                formSubmitting={props.submitting}
                {...restProps}
            />
        </>
    );
};

interface FieldArraysFormProps extends FormProps, ConnectedProps<typeof connector> {}

const FieldArraysForm: React.FC<any> = (formProps: FieldArraysFormProps & FormProps & { formSubmitting: boolean }) => {
    const { translate, error, resourceGroupName, formSubmitting, resourceTypes } = formProps;
    

    const addResource = (resource:any) => {
        formProps.dispatch({
            type: actions.CREATE_RESOURCE.REQUEST,
            payload: resource
        });
    };

    useEffect(() => {
        if(resourceTypes.created.length > 0) {
            formProps.fields.removeAll();
            resourceTypes.created.forEach(resource => {
                formProps.fields.push({
                    Name: resource.Name
                })
            });
            formProps.fields.push({});
        } else {
            formProps.fields.push({});
        }
    }, [resourceTypes.created]);

    return (
        <>
            <Segment attached="top">
                <Grid>
                    <Grid.Column computer={8} mobile={16}>
                        <Segment attached="top">
                            <Box>
                                <Header size="small">{resourceGroupName}</Header>
                            </Box>
                        </Segment>
                        <Segment attached="bottom">
                            <Flex flexDirection={'column'} style={{ gap: '8px'}}>
                                {formProps.fields.map((member: any, index: number) => {
                                    const resource = formProps.formValues.resourceWizard[index];
                          
                                    return (
                                        <Flex key={index} alignItems={'center'}>
                                            <Box mr="2" width={'100%'}>
                                                <Field
                                                    autoFocus
                                                    disabled={
                                                        formProps.fields.length > 1 &&
                                                        !!resourceTypes.created.find(
                                                            (r) => r.Name === resource.Name
                                                        )
                                                    }
                                                    placeholder={
                                                        translate(
                                                            'resourcesForm.field.Name'
                                                        ) as string
                                                    }
                                                    type="text"
                                                    component={Input}
                                                    name={`${member}.Name`}
                                                    style={{ width: '100%' }}
                                                />
                                            </Box>
                                            <Button
                                                content={translate('form.ok')}
                                                disabled={
                                                    resourceTypes.isResourceCreating ||
                                                    !resource.Name ||
                                                    (formProps.fields.length > 1 &&
                                                        !!resourceTypes.created.find(
                                                            (r) => r.Name === resource.Name
                                                        ))
                                                }
                                                loading={resourceTypes.isResourceCreating && !resourceTypes.created.find(
                                                    (r) => r.Name === resource.Name
                                                )}
                                                onClick={(evt) => {
                                                    evt.preventDefault();
                                                    addResource(resource);
                                                }}
                                            />
                                        </Flex>
                                    );
                                })}
                                
                            </Flex>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16}>
                        <p>
                            <Translate
                                id="CreateResourceWizard.resourceForm.explanation1"
                                data={{
                                    resourceGroupName,
                                }}
                                options={{
                                    renderToStaticMarkup: false,
                                    renderInnerHtml: true,
                                }}
                            />
                        </p>
                        <Box mb="3" width={[1, 1, 1/2]}>
                            <Img
                                src={getCurrentLanguageCode() === 'sv' ?  InfoImgSv : InfoImgEn}
                                alt={
                                    translate(
                                        'CreateResourceWizard.resourceGroupForm.explanation3'
                                    ) as string
                                }
                            />
                        </Box>
                        <p>
                            <Translate id="CreateResourceWizard.resourceForm.explanation2" options={{ renderInnerHtml: true, renderToStaticMarkup: false }} />
                        </p>
                        <p>
                            <Translate id="CreateResourceWizard.resourceForm.explanation3" options={{ renderInnerHtml: true, renderToStaticMarkup: false }} />
                        </p>
                        <p>
                            <Translate id="CreateResourceWizard.resourceForm.explanation4" options={{ renderInnerHtml: true, renderToStaticMarkup: false }} />
                        </p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached="bottom">
                <Flex justifyContent={'end'}>
                    <Button
                        type="submit"
                        loading={formSubmitting}
                        disabled={formSubmitting}
                        primary
                        content={translate('form.save')}
                    />
                </Flex>
            </Segment>
            {error ? (
                // @ts-ignore
                <Error error={error} />
            ) : null}
        </>
    );
};

const connector = connect((state: ApplicationState) => {
    return {
        // @ts-ignore
        resourceGroupName: getFormValues('resourceWizard')(state)?.Name,
        resourceTypes: state.resourceTypes
    };
});

export default compose(withLocalize, connector)(ResourceTypes) as React.FC<any>;
