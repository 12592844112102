import React from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Segment } from 'semantic-ui-react';

import * as actions from '@/store/actions';
import PageHeader from '@/components/PageHeader';
import BillingForm from '@/components/BillingForm';
import HelpButton from '@/components/HelpButton';

interface Props extends LocalizeContextProps {
    preventRequiredActions?: string[];
    autofill?: boolean
}

const BillingInformationSettingsLoader: React.FC<Props> = (props) => {
    const { translate, ...restProps } = props;

    return (
        <>
            <Segment>
                <BillingForm action={actions.EDIT_BILLING_SETTINGS} {...restProps} />
            </Segment>
        </>
    );
};

export default withLocalize(BillingInformationSettingsLoader);
