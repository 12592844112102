import React from 'react';
import { withLocalize, LocalizeContextProps, LocalizedElement, LocalizedElementMap } from 'react-localize-redux';
import { Form as UIForm, Segment, Grid } from 'semantic-ui-react';

import * as actions from '@/store/actions';
import { RootState } from 'MyTypes';
import {
    DefaultCreateTypes,
    EaccountingSettingsResponse,
} from '@/types';
import { connect, ConnectedProps } from 'react-redux';
import {
    useCheckEaccountingAuthQuery,
    useGetEaccountingArticlesQuery,
    useGetEaccountingPaymentTermsQuery,
    useGetEaccountingPriceMappingsQuery,
    useGetEaccountingPricesQuery,
    useGetEaccountingSettingsQuery,
} from '@/services/bmApi';
import PageHeader from '@/components/PageHeader';
import { getCompanyId } from '@/store/reducers/newAuth';
import RTKLoader from '@/components/RTKLoader';
import Form from '@/components/Form';
import Field from '@/components/Field';
import styled from 'styled-components';
import { FieldLabel } from '@/components/Input';
import useTranslate from '@/hooks/useTranslate';
import Main from './Main';
import { FormProps } from '@/components/Form';
import ArticleOption from "../EAPriceArticleMappings/ArticleOption";

interface EASettingsProps extends LocalizeContextProps, ConnectedProps<typeof connector> {}

const EASettings: React.FC<EASettingsProps> = (props) => {
    const { companyId, translate } = props;

    const articles = useGetEaccountingArticlesQuery();
    const prices = useGetEaccountingPricesQuery();
    const priceMappings = useGetEaccountingPriceMappingsQuery({ CompanyId: companyId });
    const eaSettings = useGetEaccountingSettingsQuery({ CompanyId: companyId });
    const { isSuccess: authenticated } = useCheckEaccountingAuthQuery();

    return (
        <>
            <PageHeader
                breadcrumbs={[
                    {
                        to: '/eaccounting/settings',
                        content: translate('EASettings.heading'),
                    },
                ]}
            />
            <RTKLoader queries={[eaSettings]}>
                <Segment>
                    <Main />
                </Segment>
            </RTKLoader>
            {authenticated ? <RTKLoader
                queries={[articles, prices, priceMappings]}
            >
                <Form
                    confirmOnLeave={false}
                    showSuccessMesage={false}
                    name="easettings"
                    initialValues={{
                        CompanyId: companyId,
                        DefaultCreateType: (eaSettings.data?.DefaultCreateType ? eaSettings.data?.DefaultCreateType : 'Draft') as DefaultCreateTypes,
                        DefaultArticle6PercentVat: eaSettings.data?.DefaultArticle6PercentVat,
                        DefaultArticle12PercentVat: eaSettings.data?.DefaultArticle12PercentVat,
                        DefaultArticle25PercentVat: eaSettings.data?.DefaultArticle25PercentVat,
                        DefaultTermsOfPaymentId: eaSettings.data?.DefaultTermsOfPaymentId,
                        Active: eaSettings.data?.Active,
                        CreatedDate: eaSettings.data?.CreatedDate,
                        UpdatedDate: eaSettings.data?.UpdatedDate,
                    } as Partial<EaccountingSettingsResponse>}
                    action={actions.updateEaccountingSettings}
                    customOnSubmitSuccess={() => {}}
                >
                    {(formProps: any) => <EASettingsForm {...props} {...formProps} />}
                </Form>
            </RTKLoader> : null}
        </>
    );
};

const OptionDescription = styled.div`
    font-size: 0.9 em;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 5px;
`;

interface EASettingsFormProps extends FormProps<EaccountingSettingsResponse> {
    companyId: string;
}

const EASettingsForm: React.FC<EASettingsFormProps> = (props) => {
    const { translate } = useTranslate();
    const articles = useGetEaccountingArticlesQuery();
    const paymentTerms = useGetEaccountingPaymentTermsQuery();

    const defaultArticlesOptions = articles.data!.map(a => ({
        value: a.ArticleId,
        text: ArticleOption(a, translate)
    }));

    let createSendOptions: {
        key: DefaultCreateTypes;
        value: DefaultCreateTypes;
        text: string | LocalizedElement | LocalizedElementMap;
    }[] = [
        {
            key: 'Draft',
            value: 'Draft',
            text: translate(
                'createInvoice.draftOrCreateSend.draft'
            ),
        },
        {
            key: 'CreateSend',
            value: 'CreateSend',
            text: translate(
                'createInvoice.draftOrCreateSend.createAndSend'
            ),
        }
    ];

    return (
        <>
            <Segment attached="top">
                <UIForm.Group inline>
                    <FieldLabel
                        htmlFor="Active"
                        width={4}
                        text={translate('EASettingsForm.EAccountingIntegrationActive.text')}
                    />
                    <Grid.Column style={{ padding: '10px 0px' }} computer={12}>
                        <Field name="Active" type="checkbox" toggle />
                    </Grid.Column>
                </UIForm.Group>
                <UIForm.Group inline>
                    <FieldLabel
                        htmlFor="DefaultCreateType"
                        width={4}
                        text={translate('EASettingsForm.DefaultCreateType.text')}
                    />
                    <Grid.Column
                        style={{ padding: '10px 0px', flexGrow: 1 }}
                        computer={8}
                        mobile={16}
                    >
                        <Field name="DefaultCreateType" type="select" options={createSendOptions} />
                    </Grid.Column>
                </UIForm.Group>
                <UIForm.Group inline>
                    <FieldLabel
                        htmlFor="DefaultArticle6PercentVat"
                        width={4}
                        text={translate('EASettingsForm.DefaultArticle6PercentVat.text')}
                    />
                    <Grid.Column
                        style={{ padding: '10px 0px', flexGrow: 1 }}
                        computer={8}
                        mobile={16}
                    >
                        <Field
                            name="DefaultArticle6PercentVat"
                            type="select"
                            style={{ border: '1px solid red!important ' }}
                            options={articles
                                .data!.filter((a) => a.VatRatePercent && parseFloat(a.VatRatePercent) === 0.06)
                                .map((a) => ({
                                    value: a.ArticleId,
                                    text: ArticleOption(a, translate),
                                }))}
                        />
                    </Grid.Column>
                </UIForm.Group>
                <UIForm.Group inline>
                    <FieldLabel
                        htmlFor="DefaultArticle12PercentVat"
                        width={4}
                        text={translate('EASettingsForm.DefaultArticle12PercentVat.text')}
                    />
                    <Grid.Column
                        style={{ padding: '10px 0px', flexGrow: 1 }}
                        computer={8}
                        mobile={16}
                    >
                        <Field
                            name="DefaultArticle12PercentVat"
                            type="select"
                            options={articles
                                .data!.filter((a) => a.VatRatePercent && parseFloat(a.VatRatePercent) === 0.12)
                                .map((a) => ({
                                    value: a.ArticleId,
                                    text: ArticleOption(a, translate),
                                }))}
                        />
                    </Grid.Column>
                </UIForm.Group>
                <UIForm.Group inline>
                    <FieldLabel
                        htmlFor="DefaultArticle25PercentVat"
                        width={4}
                        text={translate('EASettingsForm.DefaultArticle25PercentVat.text')}
                    />
                    <Grid.Column
                        style={{ padding: '10px 0px', flexGrow: 1 }}
                        computer={8}
                        mobile={16}
                    >
                        <Field
                            name="DefaultArticle25PercentVat"
                            type="select"
                            options={articles
                                .data!.filter((a) => a.VatRatePercent && parseFloat(a.VatRatePercent) === 0.25)
                                .map((a) => ({
                                    value: a.ArticleId,
                                    text: ArticleOption(a, translate),
                                }))}
                        />
                    </Grid.Column>
                </UIForm.Group>
                <UIForm.Group inline>
                    <FieldLabel
                        htmlFor="DefaultTermsOfPaymentId"
                        width={4}
                        text={translate('EASettingsForm.DefaultTermsOfPaymentId.text')}
                    />
                    <Grid.Column
                        style={{ padding: '10px 0px', flexGrow: 1 }}
                        computer={8}
                        mobile={16}
                    >
                        <Field
                            name="DefaultTermsOfPaymentId"
                            type="select"
                            disabled={paymentTerms.isLoading}
                            options={
                                paymentTerms.data
                                    ? paymentTerms.data.map((p) => ({
                                          key: p.Id,
                                          value: p.Id,
                                          text: p.Name,
                                      }))
                                    : []
                            }
                        />
                    </Grid.Column>
                </UIForm.Group>
            </Segment>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        companyId: getCompanyId(state),
    };
};

const connector = connect(mapStateToProps);

export default connector(withLocalize(EASettings));
