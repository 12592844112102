import React from 'react';
import { Field } from 'redux-form';
import Input from './Input';
import { CustomField, CustomFieldValueType } from '../types';

interface Props {
    customField: CustomField;
    prepopulate?: boolean;
    withLabel?: boolean;
    name?: string;
}

interface ParsedCustomFieldValueType {
    Id: number;
    Value: string | undefined;
    meta?: {
        Name: string;
        Column?: string;
        Description: string;
        DataType: string;
        DefaultValue: string;
    }
}

// props: customField, name
export class CustomFieldTextBox extends React.Component<Props> {
    static defaultProps = {
        withLabel: true,
    };

    validateRegEx = (value: CustomFieldValueType) => {
        let re = new RegExp(this.props.customField.RegExId, 'g');

        return !value || !re.test(value.Value)
            ? this.props.customField.RegExErrorMessage
            : undefined;
    };

    validateMandatory = (value: CustomFieldValueType) => {
        return !value || !value.Value ? this.props.customField.MandatoryErrorMessage : undefined;
    };

    render() {
        const { customField, name, withLabel, prepopulate = true } = this.props;

        const validations = [];

        if (customField.RegExId && customField.RegExId.length > 0) {
            validations.push(this.validateRegEx);
        }

        if (customField.IsMandatory) {
            validations.push(this.validateMandatory);
        }

        return (
            <Field
                key={customField.Id}
                name={name || `_${customField.Id}`}
                component={Input}
                type={customField.MultipleLineText ? 'textarea' : 'text'}
                label={withLabel && `${customField.Name}`}
                help={customField.Description}
                validate={validations}
                required={customField.IsMandatory}
                maxLength={customField.MaxLength}
                style={{ width: customField.Width || '100%' }}
                format={(value: ParsedCustomFieldValueType) => {
                    if(value && value?.Value) {
                        return value.Value
                    } else if (!prepopulate && value && typeof value?.Value === 'string' ) {
                        return value.Value
                    } else {
                        return value?.meta?.DefaultValue ? value.meta.DefaultValue : ''
                    }
                }}
                parse={(value: CustomFieldValueType) => ({
                    Id: customField.Id,
                    Value: value,
                    meta: customField,
                })}
            />
        );
    }
}

export default CustomFieldTextBox;
