import React, { Ref, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Icon, Form as UIForm } from 'semantic-ui-react';
import { WrappedFieldProps } from 'redux-form';
import { useTimePicker } from '@/hooks/useTimePicker';
import useTranslate from '@/hooks/useTranslate';

interface Props extends WrappedFieldProps {
    value?: Date;
    label?: JSX.Element;
    inline?: boolean;
    withIcon?: boolean;
    inputRef?: Ref<any>;
    disabled: boolean;
}

interface State {
    hours: number;
    minutes: number;
}

const Wrapper = styled.div<{ withIcon: boolean }>`
    width: auto;
    display: inline-flex;
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    /* outline: 0; */
    outline-color: currentcolor;
    outline-style: none;
    outline-width: 0px;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    padding: 0 1px;
    text-align: left;
    line-height: 1.21428571em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 12px;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: none;
    align-items: baseline;
`;
const TimeIcon = styled(Icon)`
    && {
        margin: 0 0.2em 0 0.3em;
    }
`;
const Divider = styled.span`
    display: inline-block;
    text-align: center;
    width: 0.4em;
    padding: 0.67857143em 0;
`;
const Input = styled.input`
    &&&& {
        padding: 0.67857143em 0;
        display: inline-block;
        width: 2em;
        border: none;
        text-align: center;
        border-radius: 12px;

        &:focus {
            box-shadow: 0 0 0 1px #85b7d9;
            background: #fff;
            color: rgba(0, 0, 0, 0.8);
        }
    }
`;
const Select = styled.select`
    &&&& {
        padding: 0.67857143em 0;
        display: inline-block;
        width: 2em;
        border: none;
        text-align: center;
        border-radius: 12px;
        outline: none;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus {
            box-shadow: 0 0 0 1px #85b7d9;
            background: #fff;
            color: rgba(0, 0, 0, 0.8);
        }
        margin-left: 1px;
    }
`;

const KeyCodes = {
    UP: 38,
    DOWN: 40,
};

const TimeField: React.FC<Props> = (props) => {
    const i18n = useTranslate();
    const [currentValue, setCurrentValue] = useState({id: '', value: ''});
    const minutesRef = useRef<HTMLInputElement>();
    const useAMPM = i18n.activeLanguage.code === 'en';
    const { label, inline, withIcon, inputRef, input } = props;
    const { hours, minutes, ampm } = useTimePicker({
        value: input.value,
        onChange: input.onChange,
        useAMPM,
    });

    const handleOnKeyUp = (
        ev: React.KeyboardEvent<HTMLInputElement>,
        payload: { id: string; value: string }
    ) => {
        if (
            (ev.shiftKey && ev.key === 'Tab') ||
            ev.key === 'Tab' ||
            ev.key === 'ArrowLeft' ||
            ev.key === 'ArrowRight' ||
            ev.key === 'ArrowUp' ||
            ev.key === 'ArrowDown' ||
            ev.key === 'Shift' ||
            ev.key === 'Alt' ||
            ev.key === 'Meta'
        ) {
            return;
        }

        setCurrentValue(payload);
    };

    useEffect(() => {
        if(minutesRef && minutesRef.current && currentValue?.id === 'hours' &&  currentValue?.value?.length === 2) {
            minutesRef?.current?.focus()
        }
    }, [currentValue, inputRef, minutesRef])

    // We need this to trigger blur on timepicker inputs when redux-form updates the input
    useEffect(() => {
        const evt = { target: { value: input.value }};
        // @ts-ignore
        hours.input.onBlur(evt);
        // @ts-ignore
        minutes.input.onBlur(evt);
    }, [input.value]);

    return (
        <UIForm.Field inline={inline}>
            {label && <label>{label}</label>}

            <Wrapper withIcon={!!withIcon}>
                {withIcon && <TimeIcon name="clock outline" color="grey" />}
                <Input
                    {...hours.input}
                    disabled={props.disabled}
                    name={`${input.name}.hours`}
                    type="text"
                    placeholder="--"
                    ref={inputRef}
                    onKeyUp={(ev) => handleOnKeyUp(ev, {id: `hours`, value: ev?.currentTarget?.value})}
                />
                <Divider className="TimePicker__Separator">:</Divider>
                <Input
                    {...minutes.input}
                    disabled={props.disabled}
                    ref={minutesRef as any}
                    name={`${input.name}.minutes`}
                    type="text"
                    placeholder="--"
                    onKeyUp={(ev) => handleOnKeyUp(ev, {id: `minutes`, value: ev?.currentTarget?.value})}
                />
                {useAMPM && (
                    <Select disabled={props.disabled} {...ampm.select}>
                        <option value="am">am</option>
                        <option value="pm">pm</option>
                    </Select>
                )}
            </Wrapper>
        </UIForm.Field>
    );
};

export default TimeField;
