import { Service } from '@/types';

export function getPricesInfo(service: Service) {
    const hasMultiplePrices = service.Prices && service.Prices.length > 1;
    const hasGroupBooking =
        service.GroupBooking.Active && service.GroupBooking.Min !== service.GroupBooking.Max;
    const hasMultipleResource =
        service.MultipleResource.Active &&
        service.MultipleResource.Min !== service.MultipleResource.Max;

    const prices = service.Prices;
    const hasPrices = prices.length > 0;
    const maxQuantity = service.GroupBooking.Active
        ? service.GroupBooking.Max
        : service.MultipleResource.Max;
    const totalSpots = service.TotalSpots;

    return {
        hasMultiplePrices,
        hasPrices,
        hasGroupBooking,
        hasMultipleResource,
        prices,
        maxQuantity,
        totalSpots
    };
}
