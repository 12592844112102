import { AnyAction } from 'redux';
import { createListLoadingReducer, listInitialState } from './utils';
import * as actions from '../actions';
import reduceReducers from 'reduce-reducers';

const initialState = {
    ...listInitialState
}

export const selectBookings = (state: any) => state.bookings;

const bookingsReducer = createListLoadingReducer('BOOKINGS');

const metaReducer = (state = listInitialState, action: AnyAction) => {
    switch (action.type) {
        case actions.EXPORT_BOOKINGS.REQUEST: {
            return {
                ...state,
                exporting: true
            };
        }
        case actions.EXPORT_BOOKINGS.SUCCESS: {
            return {
                ...state,
                exporting: false
            };
        }
        default:
            return state;
    }
}

export default reduceReducers<any>(initialState, bookingsReducer, metaReducer);
