import React from 'react';
import { Box, Flex } from 'grid-styled';
import classNames from 'classnames/bind';
import Form from '@/components/Form';
import Field from '@/components/Field';
import useTranslate from '@/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { FormGroup, Button } from 'semantic-ui-react';
import styles from './NewBookingForm.module.css';
import { applyRebateCode, removeRebateCode } from '@/store/reducers/calculatePrice';
import { Customer, Quantity, Service, Time } from '@/types';

export let cx = classNames.bind(styles);

export const ApplyRebateCodes: React.FC<{
    service: Service;
    customer?: Customer;
    timeSpot: Time;
    quantities: { Quantity: number; PriceId: number }[];
}> = ({ service, customer, timeSpot, quantities }) => {
    const dispatch = useAppDispatch();
    const calculatedPrice = useAppSelector((s) => s.calculatePrice);
    const applied = calculatedPrice.entity && calculatedPrice.entity.appliedCodes;

    return (
        <Box>
            <Form
                name={`promoCodes`}
                initialValues={{
                    RebateCodeSign: '',
                }}
                onSubmit={(formValues: any) => {
                    if (!applied) {
                        return dispatch(
                            applyRebateCode({
                                serviceId: service.Id,
                                rebateCodeSign: formValues.RebateCodeSign,
                                ...(customer?.Email
                                    ? {
                                          customerEmail: customer.Email,
                                      }
                                    : {}),
                                interval: timeSpot,
                                quantities,
                            })
                        ).unwrap();
                    } else {
                        return dispatch(
                            // @ts-ignore
                            removeRebateCode({
                                serviceId: service.Id,
                                rebateCodeSign: formValues.RebateCodeSign,
                                ...(customer?.Email
                                    ? {
                                          customerEmail: customer.Email,
                                      }
                                    : {}),
                                interval: timeSpot,
                                quantities,
                            })
                        ).unwrap();
                    }
                }}
                basic
                showActionBar={false}
                showSuccessMesage={false}
            >
                {(formProps: any) => <PromoCodeApplyForm {...formProps} />}
            </Form>
        </Box>
    );
};

const PromoCodeApplyForm: React.FC<any> = ({ appliedCodes, change, ...formProps }) => {
    const { translate } = useTranslate();
    const calculatedPrice = useAppSelector((s) => s.calculatePrice);
    const applied = calculatedPrice.entity && calculatedPrice.entity.appliedCodes;

    return (
        <FormGroup className={cx('fluid')}>
            <Flex alignItems="end" style={{ gap: '8px', flexGrow: 1, flexWrap: 'wrap' }}>
                <Field
                    label={translate('newBookingForm.applyPromoCode.description')}
                    name="RebateCodeSign"
                    placeholder={translate('newBookingForm.applyPromoCode.placeholder')}
                    type="text"
                    disabled={calculatedPrice.loading}
                    readOnly={applied || calculatedPrice.loading}
                />
                <Button
                    disabled={calculatedPrice.loading}
                    primary
                    content={
                        !applied
                            ? translate('newBookingForm.applyPromoCode')
                            : translate('newBookingForm.removePromoCode')
                    }
                />
            </Flex>
        </FormGroup>
    );
};
