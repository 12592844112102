import React from 'react';
import styled, { createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
    .ui.dropdown > .text .additional-info {
        display: none;
    }
`

const Container = styled.div`
    display: flex;
    gap: 4px;
    flex-direction: column;
    .additional-info {
        color: var(--gray-200);
    }
`;


const Option = (name: string, description: string) => (
    <>
        <GlobalStyle />
        <Container>
            <div className='name'>{name}</div>
            <div className='additional-info'>
                <span>{description}</span>
            </div>
        </Container>
    </>
);

export default Option
