import { combineReducers, Reducer, AnyAction } from 'redux';
import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import { createLoadingReducer, createListLoadingReducer } from './utils';
import * as actions from '../actions';
import { format } from '@/utils/datetime';

export const getTimes = (state: any) => state.availableTimes.times;

export const selectNavigationDate = (state: any) => state.availableTimes.ui.navigationDate;

export const getTimesGroupedByDay = createSelector(getTimes, (times) =>
    groupBy(times.data, (time) => format(time.From, 'yyyy-MM-dd'))
);

const uiInitialState = {
    navigationDate: new Date(),
};

const availableTimes = createListLoadingReducer('AVAILABLE_TIMES');
const nextFreeTime = createLoadingReducer('NEXT_FREE_TIME', (payload) =>
    payload.Times ? payload.Times[0] : null
);

const uiReducer: Reducer<any, AnyAction> = (state = uiInitialState, { type, payload }) => {
    switch (type) {
        case actions.CHANGE_BOOKING_NAVIGATION_DATE:
            return { ...state, navigationDate: payload };
        default:
            return state;
    }
};

export default combineReducers({
    times: availableTimes,
    nextFreeTime: nextFreeTime,
    ui: uiReducer,
});
