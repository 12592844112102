import { combineReducers } from 'redux';
import { createLoadingReducer, createListLoadingReducer } from './utils';

export default combineReducers({
    images: createListLoadingReducer('HOMEPAGE_IMAGES'),
    menu: createLoadingReducer('HOMEPAGE_MENU'),
    posts: createListLoadingReducer('HOMEPAGE_POSTS'),
    settings: createLoadingReducer('HOMEPAGE_SETTINGS'),
    schedulerSettings: createLoadingReducer('HOMEPAGE_SCHEDULER_SETTINGS'),
    widgetSettings: createLoadingReducer('HOMEPAGE_WIDGET_SETTINGS'),
});
