import React from 'react';
import { Field } from 'redux-form';
import Input from './Input';
import { CustomField, CustomFieldValueType } from '../types';

interface Props {
    customField: CustomField;
    withLabel?: boolean;
    name?: string;
}

// props: customField, name
export class CustomFieldDropDown extends React.Component<Props> {
    static defaultProps = {
        withLabel: true,
    };

    validateMandatory = (value: String) => {
        return !value ? this.props.customField.MandatoryErrorMessage : undefined;
    };

    render() {
        const { customField, name, withLabel } = this.props;

        const validations = [];

        if (customField.IsMandatory) {
            validations.push(this.validateMandatory);
        }

        return (
            <Field
                name={name || `_${customField.Id}`}
                component={Input}
                key={customField.Id}
                required={customField.IsMandatory}
                label={withLabel && `${customField.Name}`}
                type="select"
                help={customField.Description}
                options={
                    customField.Values
                        ? customField.Values.map((valueObject) => ({
                              value: valueObject ? valueObject.Value : null,
                              text: valueObject ? valueObject.Value : '',
                          }))
                        : []
                }
                style={{ width: customField.Width || '100%' }}
                validate={validations}
                format={(value: CustomFieldValueType) => (value ? value.Value : value)}
                parse={(value: CustomFieldValueType) => ({
                    Id: customField.Id,
                    Value: value,
                    meta: customField,
                })}
            />
        );
    }
}

export default CustomFieldDropDown;
