import { createSelector } from 'reselect';
import { getNormalizedServices } from '../../store/reducers/services';
import { createListLoadingReducer } from './utils';
import { Price } from '../../types';

export const getPrices = (state: any) => state.prices;

export const getPriceById = (id: string) =>
    createSelector(getPrices, (prices) =>
        prices.isLoaded ? prices.data.find((item: Price) => item.Id.toString() === id) : null
    );

export const getPricesJoinedWithServices = createSelector(
    getPrices,
    getNormalizedServices,
    (prices, services) => {
        return prices.data.map((price: Price) => ({
            ...price,
            ServiceName: services[price.ServiceId] && services[price.ServiceId].Name,
        }));
    }
);

export default createListLoadingReducer('PRICES');
