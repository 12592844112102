import React, { Component } from 'react';
import { Button, Icon, Table, Segment, Grid, Label, Image } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import Info from '@/components/Info';
import * as actions from '@/store/actions';
import BillingForm from '@/components/BillingForm';
import { differenceInDays, isBefore } from '@/utils/datetime';
import { Dispatch } from 'redux';
import { TrialType, ListState, LicenseType, LicensesInformation, License } from '@/types';
import { LocalizeContextProps } from 'react-localize-redux';
import classnames from 'classnames/bind';
import styles from './Licenses.module.css';
import CheckIconImg from '../../pngs/done@2x.png';
import invalidIconImg from '../../pngs/invalid-name@2x.png';
import { groupBy } from 'lodash';



const cx = classnames.bind(styles);

const EXTRA_LICENSE_NEEDED = 'ExtraLicenseNeeded';

const isStarColumns = (type: any) => {
    const GRATIS_TYPE_ID = 1;
    const STANDARD_TYPE_ID = 2;
    const PREMIUM_TYPE_ID = 3;
    const BUSINESS_TYPE_ID = 5;
    const ENTERPRISE_TYPE_ID = 9;

    return (
        type.Id === STANDARD_TYPE_ID ||
        type.Id === PREMIUM_TYPE_ID ||
        type.Id === BUSINESS_TYPE_ID ||
        type.Id === ENTERPRISE_TYPE_ID
    );
};

interface Props extends LocalizeContextProps {
    onGoBack?: () => void;
    onSelectPlan?: () => void;
    onContinueTrial?: () => void;
    dispatch: Dispatch;
    types: ListState<LicenseType>;
    companyLicenses: ListState<License>;
    information: ListState<LicensesInformation>;
    licenseInformationTypes: ListState<any>
    modal: any;
    addTrial: any;
    suggestedLicense?: any;
    suggestedLicenseId?: number;
    suggestedTrialType?: TrialType;
    currentTrialPeriod?: any;
    autofill?: boolean;
}
interface State {
    step: number;
    licenseTypeId: number | null;
}

export default class Licenses extends Component<Props, State> {
    static defaultProps = {
        onSelectPlan: () => {},
        onGoBack: () => {},
        currentTrialPeriod: {},
    };

    state = {
        step: 0,
        licenseTypeId: null,
    };

    handleBack = () => {
        this.setState({ step: this.state.step - 1 });
        if (this.state.step === 1 && this.props.onGoBack) {
            this.props.onGoBack();
        }
    };

    choosePlan = (licenseTypeId: number) => {
        this.setState({ licenseTypeId, step: 1 });
        if (this.props.onSelectPlan) {
            this.props.onSelectPlan();
        }
    };

    handleSubmitSuccess = () => {
        this.setState({ step: 0 });
        this.props.dispatch(actions.FETCH_COMPANY_LICENSES.request());
    };

    render() {
        const { suggestedTrialType, modal } = this.props;

        const isExtraLicence = this.props.modal?.exceedingData?.SuggestedLicenseToUpgrade
            ?.IsExtraLicenseOption;

        return (
            <>
                {this.state.step === 0 &&
                    isExtraLicence &&
                    !suggestedTrialType &&
                    this.renderUpgradeNeeded()}
                {this.state.step === 0 &&
                    !suggestedTrialType &&
                    !isExtraLicence &&
                    this.renderSelectPlan()}
                {this.state.step === 0 && suggestedTrialType && this.renderTrialNeeded()}
                {this.state.step === 1 && (
                    <Segment basic>
                        <BillingForm
                            licenseTypeId={this.state.licenseTypeId}
                            onBack={this.handleBack}
                            onSubmitSuccess={this.handleSubmitSuccess}
                            autofill={this.props.autofill}
                        />
                    </Segment>
                )}
            </>
        );
    }

    renderTrialNeeded = () => {
        const {
            suggestedTrialType,
            suggestedLicense,
            addTrial,
            currentTrialPeriod,
            translate,
        } = this.props;

        if (!suggestedLicense) {
            return (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        content={`${translate('licenses.testForFree')} ${
                            suggestedTrialType.TrialDays
                        } ${translate('common.days')}`}
                        icon="star"
                        basic
                        primary
                        onClick={this.handleAddTrial}
                        loading={addTrial.isLoading}
                    />
                    <Button
                        onClick={() => {
                            this.props.dispatch(push('/account'));
                            this.props.dispatch({
                                type: 'HIDE_LICENSES_MODAL',
                            });
                        }}
                        content={`${translate('licenses.goToSubscriptions')}`}
                    />
                </div>
            );
        }

        const price = suggestedLicense.Prices && suggestedLicense.Prices[0];
        const formattedPrice = price
            ? ` - ${price.Price}${price.Country.CurrencyInfo.CurrencySign}`
            : ' / månad';

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!currentTrialPeriod.Active &&
                    !isBefore(currentTrialPeriod.ValidTo, new Date()) && (
                        <Button
                            content={`${translate('licenses.testForFree')} ${
                                suggestedTrialType.TrialDays
                            } ${translate('common.days')}`}
                            icon="star"
                            basic
                            primary
                            onClick={this.handleAddTrial}
                            loading={addTrial.isLoading}
                        />
                    )}
                {currentTrialPeriod.Active && (
                    <Button
                        content={`${translate('licenses.continueTrial')} (${differenceInDays(
                            currentTrialPeriod.ValidTo,
                            new Date()
                        )}) ${translate('licenses.daysLeft')}`}
                        icon="star"
                        basic
                        primary
                        onClick={this.props.onContinueTrial}
                    />
                )}
                <Button
                    content={`${translate(
                        'licenses.upgradeAccount'
                    )}${formattedPrice} / ${translate('common.month')}`}
                    icon="check"
                    onClick={() => this.choosePlan(suggestedLicense.Id)}
                    primary
                />
            </div>
        );
    };

    handleAddTrial = () => {
        this.props.dispatch(
            actions.ADD_TRIAL.request({ TrialTypeId: this.props.suggestedTrialType.Id })
        );
    };

    renderSelectPlan = () => {
        const { translate, information, types, companyLicenses, suggestedLicenseId,licenseInformationTypes  } = this.props;
        const loading = information.isLoading || types.isLoading || companyLicenses.isLoading;
        const isLoaded = information.isLoaded && types.isLoaded && companyLicenses.isLoaded;
        const grouppedByInformationType = groupBy(information.data, 'LicenseInformationTypeId');

        return (
            <Segment basic loading={loading}>
                {isLoaded && (
                    <Table className={cx('plans-table')} basic="very" compact size="small">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell verticalAlign="top" textAlign="center">
                                    <Grid
                                        className={cx([
                                            'pb-6',
                                            'align-center',
                                            'justify-center',
                                            'direction-column',
                                        ])}
                                    >
                                        <Grid> </Grid>
                                        <Grid>
                                            <span className={cx('column-name')}>
                                                {translate('license.ourFeatures')}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Table.HeaderCell>
                                {types.data.map((type) => (
                                    <Table.HeaderCell
                                        verticalAlign="top"
                                        textAlign="center"
                                        key={type.Id}
                                    >
                                        <Grid
                                            className={cx([
                                                'pb-6',
                                                'justify-center',
                                                'direction-column',
                                            ])}
                                        >
                                            <Grid className={cx(['justify-center'])}>
                                                <span className={cx(['column-name', 'pb-2'])}>
                                                    {type.Name}
                                                </span>{' '}
                                                {type.Id === suggestedLicenseId && (
                                                    <div>
                                                        <Label horizontal color="orange">
                                                            {translate('common.suggested')}
                                                        </Label>
                                                    </div>
                                                )}
                                                {checkActiveLicense(companyLicenses, type) ? (
                                                    <div>
                                                        <Label horizontal>
                                                            {translate('common.Active')}
                                                        </Label>
                                                    </div>
                                                ) : null}
                                            </Grid>
                                            <Grid
                                                relaxed
                                                className={cx(['price', 'justify-center'])}
                                            >
                                                {type.Prices[0] ? (
                                                    <div>
                                                        <span className={cx('size-2')}>
                                                            {type.Prices[0].Country.CurrencyId}
                                                        </span>
                                                        <span className={cx('size-3')}>
                                                            {type.Prices[0].Price}
                                                        </span>
                                                        <span className={cx('size-1')}>
                                                            /
                                                            {type.Prices[0].MonthlyPayment
                                                                ? this.props.translate(
                                                                      'common.month'
                                                                  )
                                                                : ''}
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </Grid>
                                            {/* <Grid>
                                                {companyLicenses.data[type.Id] && (
                                                    <Label horizontal color="blue">
                                                        {translate('common.Active')}
                                                    </Label>
                                                )}
                                            </Grid> */}
                                        </Grid>
                                    </Table.HeaderCell>
                                ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {/* <Table.Row>
                                <Table.Cell
                                    colSpan={2}
                                    className={cx('cell-inactive')}
                                ></Table.Cell>
                                <Table.Cell colSpan={5} positive textAlign="center">
                                    {translate('licenses.freeToTest')}
                                </Table.Cell>
                            </Table.Row> */}
                            {Object.keys(grouppedByInformationType).map((key, rowIndex) => {
                                const informationTypeName = licenseInformationTypes.data.find(
                                    (l: any) => l.Id == key
                                )?.Name;
                                const informationByType = grouppedByInformationType[key];

                                return informationByType.map((item, index) => (
                                    <>
                                        {rowIndex > 0 && index === 0 ? (
                                            <Table.Row textAlign="center" key={`${key}-${index}`}>
                                                <Table.Cell
                                                    className={cx([
                                                        `license-information-type-${rowIndex}`,
                                                    ])}
                                                    textAlign="center"
                                                    colSpan={types.data.length + 1}
                                                >
                                                    {informationTypeName}
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : null}
                                        <Table.Row key={`${key}-${index}`}>
                                            <Table.Cell
                                                verticalAlign="middle"
                                                key={`${key}-${index}`}
                                            >
                                                {item.Url ? (
                                                    <a
                                                        className={cx('discrete-link')}
                                                        href={item.Url}
                                                    >
                                                        <div
                                                            className={cx([
                                                                'flex',
                                                                'justify-space-between',
                                                                'align-center',
                                                                'pl-1',
                                                            ])}
                                                        >
                                                            <span
                                                                style={{
                                                                    width: 'calc(100% - 80px)',
                                                                    flexGrow: 1,
                                                                    textOverflow: 'ellipsis',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {item.Name}
                                                            </span>
                                                            <span>
                                                                <Info content={item.Description} />
                                                            </span>
                                                        </div>
                                                    </a>
                                                ) : (
                                                    <div
                                                        className={cx([
                                                            'flex',
                                                            'justify-space-between',
                                                            'align-center',
                                                            'pl-1',
                                                        ])}
                                                    >
                                                        <span
                                                            style={{
                                                                width: 'calc(100% - 80px)',
                                                                flexGrow: 1,
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            {item.Name}
                                                        </span>
                                                        <span>
                                                            <Info content={item.Description} />
                                                        </span>
                                                    </div>
                                                )}
                                            </Table.Cell>
                                            {types.data.map((type) => {
                                                // e.g StandardEdition => StandardEditionValue
                                                // @ts-ignore

                                                const valueString = item[`${type.key}Value`];
                                                const value =
                                                    valueString && valueString.includes('|')
                                                        ? valueString.split('|')
                                                        : [valueString];

                                                return (
                                                    <Table.Cell
                                                        key={type.Id}
                                                        textAlign="center"
                                                        // active={!!companyLicenses.data[type.Id]}
                                                        positive={type.Id === suggestedLicenseId}
                                                    >
                                                        {valueString &&
                                                        value.length === 1 &&
                                                        valueString !== 'NULL' &&
                                                        item.Name !== 'own domain name'
                                                            ? value[0]
                                                            : null}
                                                        {valueString &&
                                                        value.length === 2 &&
                                                        valueString !== 'NULL' &&
                                                        item.Name !== 'own domain name' ? (
                                                            <>
                                                                <div>{value[0]}</div>
                                                                <small>{value[1]}</small>
                                                            </>
                                                        ) : null}
                                                        {!valueString &&
                                                        // @ts-ignore
                                                        item[type.key] ? (
                                                            <img
                                                                src={CheckIconImg}
                                                                alt="Info"
                                                                className={cx('check')}
                                                            />
                                                        ) : null}
                                                        {valueString === 'NULL' &&
                                                        item.FreeEdition &&
                                                        // @ts-ignore
                                                        item[type.key] ? (
                                                            <img
                                                                src={CheckIconImg}
                                                                alt="Info"
                                                                className={cx('check')}
                                                            />
                                                        ) : null}
                                                        {!valueString &&
                                                        // @ts-ignore
                                                        !item[type.key] ? (
                                                            <img
                                                                src={invalidIconImg}
                                                                alt="Invalid"
                                                                className={cx('invalid')}
                                                            />
                                                        ) : null}
                                                    </Table.Cell>
                                                );
                                            })}
                                        </Table.Row>
                                    </>
                                ));
                            })}
                            <Table.Row>
                                <Table.Cell />
                                {types.data.map((type) => (
                                    <Table.Cell textAlign="center" key={type.Id}>
                                    <Button
                                        data-testid="select-plan"
                                        className={cx(
                                            'btn-orange',
                                            checkActiveLicense(companyLicenses, type) ? 'green' : ''
                                        )}
                                        type="button"
                                        content={checkActiveLicense(companyLicenses, type) ? translate('form.selected') : translate('form.select')}
                                        disabled={
                                            !!companyLicenses.data[type.Id] &&
                                            !companyLicenses.data[type.Id].Canceled
                                        }
                                        onClick={() => this.choosePlan(type.Id)}
                                    />
                                </Table.Cell>
                                ))}
                            </Table.Row>
                            <Table.Row></Table.Row>
                        </Table.Body>
                    </Table>
                )}
            </Segment>
        );
    };

    renderUpgradeNeeded = () => {
        const { translate, modal } = this.props;
        const { ImageUrl, SuggestedLicenseToUpgrade } = modal.exceedingData;

        return (
            <>  
                <Grid>
                    <Grid.Column textAlign="right">
                        <Button
                            type="button"
                            primary
                            icon="check"
                            content={`${translate('form.buy')} - ${this.getPriceString(
                                SuggestedLicenseToUpgrade
                            )}`}
                            floated="right"
                            onClick={() => this.choosePlan(SuggestedLicenseToUpgrade.Id)}
                        />
                    </Grid.Column>
                </Grid>
            </>
        );
    };

    getPriceString = (type: LicenseType) => {
        const { Price, Country, MonthlyPayment } = type.Prices[0];
        let price = `${Price}${Country.CurrencyInfo.CurrencySign}`;
        if (MonthlyPayment) {
            price = `${price} / ${this.props.translate('common.month')}`;
        }
        return price;
    };
}
function checkActiveLicense(companyLicenses: ListState<License>, type: LicenseType) {
    return !!Object.values(companyLicenses.data)?.find(l => !l.Canceled && l.TypeId === type.Id);
}

