import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';

import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import { AxiosResponse } from 'axios';

export function* currencies(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/currencies', { params: { Active: true } });

        yield put(actions.FETCH_CURRENCIES.success({ Results: response.data }));
    } catch (error) {
        yield put(actions.FETCH_CURRENCIES.failure(error));
    }
}
