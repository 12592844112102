import React from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from 'react-error-boundary';
import { Dispatch } from 'redux';
import ErrorMessage from './Error';
import * as actions from '@/store/actions';

type Props = {
    children: React.ReactNode;
    FallbackComponent?: React.ComponentType<any>;
    dispatch: Dispatch;
};

export default connect()(({ FallbackComponent = ErrorMessage, dispatch, ...rest }: Props) => (
    <ErrorBoundary
        {...rest}
        FallbackComponent={FallbackComponent}
        onError={(error) => {
            if(error.message.includes('chunk')) {
                dispatch(actions.addBlockingError(error))
            }
            console.error(error);
        }}
    />
));
