import { AnyAction } from 'redux';
import { put, select, call } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { push } from 'connected-react-router';
import { apiClient, convertErrorToFormError, throwSubmissionError } from '@/utils';
import { confirmSaga } from './confirm';
import { Customer } from '@/types';
import Notifications from 'react-notification-system-redux';
import { getTranslate } from 'react-localize-redux';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';


export function* newsletters({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/newsletter/log', {
            params: payload,
        });

        yield put(actions.FETCH_NEWSLETTER_LOG.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_NEWSLETTER_LOG.failure(error));
    }
}

export function* sendNewsletter({ payload }: AnyAction) {
    const customers: Customer[] = yield select((state) => state.newsletters.customers.data);
    
    try {
        const now = new Date();
        now.setMinutes(new Date().getMinutes() + 1);

        const postPayload = {
            Id: payload.Id,
            Customers: customers.map((customer: Customer) => customer.Id),
            Receiver: payload.Receiver,
            SendDate: now
        }

        const response: AxiosResponse = yield apiClient.post(`/newsletter/send`, postPayload);

        yield put(actions.SEND_NEWSLETTER.success(response.data));
    } catch (error) {
        yield put(actions.SEND_NEWSLETTER.failure(throwSubmissionError(error)));
    }
}

export function* resendNewsletter({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(`/newsletter/log/${payload.Id}/resend`);

        yield put(actions.RESEND_NEWSLETTER.success(response.data));

        const newsletterLogState: RootState['newsletters']['log'] = yield select((state) => state.newsletters.log);
        // @ts-ignore
        const { requestActionPayload } = newsletterLogState;
        yield put(actions.FETCH_NEWSLETTER_LOG.request(requestActionPayload));
    } catch (error) {
        yield put(actions.RESEND_NEWSLETTER.failure(throwSubmissionError(error)));
    }
}

export function* newsletterTemplates({ payload = {} }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/newsletter/templates', {
            params: { IncludeSendMethodInformation: true, ...payload },
        });

        yield put(actions.FETCH_NEWSLETTER_TEMPLATES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_NEWSLETTER_TEMPLATES.failure(error));
    }
}

export function* newsletterCustomers({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/newsletter/customers', {
            params: {
                IncludeCustomerInformation: true,
                ...payload,
            },
        });

        yield put(actions.FETCH_NEWSLETTER_CUSTOMERS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_NEWSLETTER_CUSTOMERS.failure(throwSubmissionError(error)));
    }
}

export function* createNewsletterTemplate(action: AnyAction) {
    const create = actions.CREATE_NEWSLETTER_TEMPLATE;
    try {
        const response: AxiosResponse = yield apiClient.post(`/newsletter/templates`, action.payload);

        yield put(create.success(response));
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* editNewsletterTemplate(action: AnyAction) {
    const edit = actions.EDIT_NEWSLETTER_TEMPLATE;
    try {
        const response: AxiosResponse = yield apiClient.put(
            `/newsletter/templates/${action.payload.Id}`,
            action.payload
        );

        yield put(edit.success(response));
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* deleteNewsletterTemplate({
    payload,
    meta: { confirmationNewsletters },
}: AnyAction) {
    const confirmed: AxiosResponse = yield call(confirmSaga, confirmationNewsletters);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/newsletter/templates/${payload.Id}`, payload);

            yield put(actions.DELETE_NEWSLETTER_TEMPLATE.success(response));
            yield put(push('/newsletter/templates'));
        } catch (error) {
            yield put(actions.DELETE_NEWSLETTER_TEMPLATE.failure(error));
        }
    }
}

export function* testNewsletterTemplate(action: AnyAction) {
    const { payload } = action;
    const localizeState: RootState['localize'] = yield select((state) => state.localize);
    const translate = getTranslate(localizeState);
    const location: RootState['router']['location'] = yield select((state) => state.router.location);

    try {
        if (!payload.Id && location.pathname?.includes('/newsletters/templates/')) {    
            const response: AxiosResponse = yield apiClient.post(`/newsletter/templates`, action.payload);
            const postPayload = {
                Id: response.data.Id,
                Receiver: payload.Receiver,
                ImageUrl: payload.ImageUrl
            };
    
            const testResponse: AxiosResponse = yield apiClient.post('/newsletter/templates/test', postPayload);
    
            yield apiClient.post('/newsletter/templates/test', postPayload);
            
            if(location.pathname?.includes('/newsletters/templates/')) {
                yield put(push(`/newsletters/templates/${response.data.Id}`));
                yield put(Notifications.success({
                    title: translate('testNewsletterTemplate.success.description', {
                        id: response.data.Id,
                    }) as string,
                }));
                yield put(Notifications.success({
                    title: translate('testNewsletterTemplate.success.title', {
                        reciever: payload.Receiver,
                    }) as string,
                }));
                yield put(actions.TEST_NEWSLETTER_TEMPLATE.success());
            }
        } else if (location.pathname?.includes('/newsletters/templates/')) {
            const response: AxiosResponse = yield apiClient.put(
                `/newsletter/templates/${payload.Id}`,
                action.payload
            );
    
            const postPayload = {
                Id: response.data.Id,
                Receiver: payload.Receiver,
                ImageUrl: payload.ImageUrl
            };
    
            yield apiClient.post('/newsletter/templates/test', postPayload);

            yield put(Notifications.success({
                title: translate('testNewsletterTemplate.success.title', {
                    receiver: payload.Receiver,
                }) as string,
            }));
    
            yield put(actions.TEST_NEWSLETTER_TEMPLATE.success());
        } else {
            const templateId: number = yield select(state => state.form.sendNewsletter.values.Id);
            const testResponse: AxiosResponse = yield apiClient.post('/newsletter/templates/test', {...payload, Id: templateId });

            yield put(Notifications.success({
                title: translate('testNewsletterTemplate.success.title', {
                    receiver: testResponse.data.Receiver,
                }) as string,
            }));
    
            yield put(actions.TEST_NEWSLETTER_TEMPLATE.success(testResponse));
        }

    } catch (error) {
        yield put(actions.TEST_NEWSLETTER_TEMPLATE.failure(convertErrorToFormError(error)));
    }
}

export function* newsletterFieldTranslations({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/newsletter/fieldtranslations', {
            params: payload,
        });

        yield put(actions.FETCH_NEWSLETTER_FIELD_TRANSLATIONS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_NEWSLETTER_FIELD_TRANSLATIONS.failure(throwSubmissionError(error)));
    }
}

export function* newsletterSendMethods({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/newsletter/sendmethods');

        yield put(actions.FETCH_NEWSLETTER_SEND_METHODS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_NEWSLETTER_SEND_METHODS.failure(throwSubmissionError(error)));
    }
}

export function* newsletterEmailTemplates({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/newsletter/emailtemplates');

        yield put(actions.FETCH_NEWSLETTER_EMAIL_TEMPLATES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_NEWSLETTER_EMAIL_TEMPLATES.failure(throwSubmissionError(error)));
    }
}
