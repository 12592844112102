import { AnyAction } from 'redux';
import { put, call } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient, convertErrorToFormError } from '@/utils';
import { confirmSaga } from './confirm';
import { AxiosResponse } from 'axios';

export function* googleCalendarTokens() {
    try {
        const response: AxiosResponse = yield apiClient.get('/sync/googlecalendar');

        yield put(actions.FETCH_GOOGLE_CALENDAR_TOKENS.success(response.data.Result));
    } catch (error: any) {
        if (error && error.response && error.response.status === 404) {
            yield put(actions.FETCH_GOOGLE_CALENDAR_TOKENS.success(null));
        } else {
            yield put(actions.FETCH_GOOGLE_CALENDAR_TOKENS.failure(convertErrorToFormError(error)));
        }
    }
}

export function* createGoogleCalendarTokens() {
    try {
        const response: AxiosResponse = yield apiClient.post('/sync/googlecalendar');

        yield put(actions.CREATE_GOOGLE_CALENDAR_TOKEN.success(response.data));
        yield put(actions.FETCH_GOOGLE_CALENDAR_TOKENS.request());
    } catch (error) {
        yield put(actions.CREATE_GOOGLE_CALENDAR_TOKEN.failure(convertErrorToFormError(error)));
    }
}

export function* deleteGoogleCalendarTokens({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        yield put({ type: actions.DELETE_GOOGLE_CALENDAR_TOKEN_CONFIRMED} );
        try {
            const response: AxiosResponse = yield apiClient.delete('/sync/googlecalendar', {
                params: { Token: payload },
            });

            yield put(actions.DELETE_GOOGLE_CALENDAR_TOKEN.success(response.data));
            yield put(actions.FETCH_GOOGLE_CALENDAR_TOKENS.request());
        } catch (error) {
            yield put(actions.DELETE_GOOGLE_CALENDAR_TOKEN.failure(convertErrorToFormError(error)));
        }
    }
}

export function* iCalTokens() {
    try {
        const response: AxiosResponse = yield apiClient.get('/sync/icalsync');

        yield put(actions.FETCH_ICAL_TOKENS.success(response.data.Result));
    } catch (error: any) {
        if (error && error.response && error.response.status === 404) {
            yield put(actions.FETCH_ICAL_TOKENS.success(null));
        } else {
            yield put(actions.FETCH_ICAL_TOKENS.failure(convertErrorToFormError(error)));
        }
    }
}

export function* createICalTokens() {
    try {
        const response: AxiosResponse = yield apiClient.post('/sync/icalsync');

        yield put(actions.CREATE_ICAL_TOKEN.success(response.data));
        yield put(actions.FETCH_ICAL_TOKENS.request());
    } catch (error) {
        yield put(actions.CREATE_ICAL_TOKEN.failure(convertErrorToFormError(error)));
    }
}

export function* deleteICalTokens({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        yield put({ type: actions.DELETE_ICAL_TOKEN_CONFIRMED} );
        try {
            const response: AxiosResponse = yield apiClient.delete('/sync/icalsync', {
                params: { Token: payload },
            });

            yield put(actions.DELETE_ICAL_TOKEN.success(response.data));
            yield put(actions.FETCH_ICAL_TOKENS.request());
        } catch (error) {
            yield put(actions.DELETE_ICAL_TOKEN.failure(convertErrorToFormError(error)));
        }
    }
}
