import { Flex } from 'grid-styled';
import React, { useEffect, useState } from 'react';


interface Props { 
    timeout?: number
    appearComponent?: React.ReactNode
 }

 /**
 * It hides the child component and shows
 * the apeparComponent after specified timeout
 */
const Timeout: React.FC<Props> = ({ timeout = 30 * 1000, children, appearComponent }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setShow(true);
        }, timeout);

        return () => {
            clearTimeout(timerId);
        }
    }, [])

    return (
        <>
            {show && appearComponent ? appearComponent : children}
        </>
    );
};

export default Timeout;