import { combineReducers, AnyAction } from 'redux';
import { createSelector } from 'reselect';
import { createListLoadingReducer } from './utils';
import groupBy from 'lodash/groupBy';
import * as actions from '../actions';
import { Reducer } from 'react';

export const selectBookingPrintoutFieldTranslations = (state: any) =>
    state.bookingPrintout.fieldTranslations;

export const getGroupedBookingPrintoutFieldTranslations = createSelector(
    selectBookingPrintoutFieldTranslations,
    (translations) => groupBy(translations.data, 'Group')
);

const test: Reducer<any, AnyAction> = (
    state = { isLoading: false, error: null },
    { type, payload }
) => {
    switch (type) {
        case actions.TEST_BOOKING_PRINTOUT_TEMPLATE.REQUEST:
            return { ...state, isLoading: true, error: null };
        case actions.TEST_BOOKING_PRINTOUT_TEMPLATE.SUCCESS:
            return { ...state, isLoading: false, error: null };
        case actions.TEST_BOOKING_PRINTOUT_TEMPLATE.FAILURE:
            return { ...state, isLoading: false, error: payload };
        default:
            return state;
    }
};

export default combineReducers({
    templates: createListLoadingReducer('BOOKING_PRINTOUT_TEMPLATES'),
    fieldTranslations: createListLoadingReducer('BOOKING_PRINTOUT_FIELD_TRANSLATIONS'),
    test,
});
