import { getTranslate } from 'react-localize-redux';
import { pick } from 'lodash';
import { EXTRA_LICENSE_NEEDED_STATUS, LICENSE_NOT_ALLOWED_STATUS } from './apiClient';

interface ResponseData {
    SuggestedTrialType?: any;
    SuggestedLicenseToUpgrade?: any;
}

export const generateContentForModals = (response: any, request: any, state: any) => {
    const localize = state.localize;
    const location = state.router.location;
    const translate = getTranslate(localize);
    const requestPayload = typeof request.data === 'string' ? JSON.parse(request.data) : {};

    let requiredFields = [''];

    if(response.status === EXTRA_LICENSE_NEEDED_STATUS || response.data['SuggestedLicenseToUpgrade']) {
        requiredFields = ['SuggestedLicenseToUpgrade'];
    } else if(response.status === LICENSE_NOT_ALLOWED_STATUS) {
        requiredFields = ['SuggestedTrialType'];
    }

    let restFields = pick<ResponseData>(response.data, requiredFields);

    let body = response.data.Body;
    let header = response.data.Header;
    
    if(location.pathname.includes('/booking-settings') && requestPayload.EnableSendFollowUpMessage) {
        header = translate('licenses.followUpModalHeader');
        body = translate('licenses.followUpModalBody');
    } else if (location.pathname.includes('/booking-settings') && requestPayload.SendNotifications) {
        header = response.data.Header;
        body = response.data.Body;
    } else if (location.pathname.includes('/newsletters/send')) {
        header = translate('licenses.newslettersModalHeader');
        body = translate('licenses.newslettersModalBody');
    } else if (location.pathname.includes('/scheduler')) {
        header = translate('licenses.schedulerModalHeader');
        body = '';
    }
    
    const content = {
        ...restFields,
        Header: header,
        Body: body,
    }

    return content;
}