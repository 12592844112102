import React from 'react';
import { TranslateFunction } from 'react-localize-redux';
import { EaccountingArticle } from '@/types';
import styled, { createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
    .ui.dropdown > .text {
        width: 100%;
    }
`

const Container = styled.div`
    display: flex;
    gap: 4px;
    .name {
        flex: 1;
        flex-basis: 60%;
    }
    .additional-info {
        color: var(--gray-200);
        display: flex;
        flex-direction: column;
        flex-basis: 40%;
    }
`;


const ArticleOption = (article: EaccountingArticle, translate: TranslateFunction, showPrice = false) => (
    <>
        <GlobalStyle />
        <Container>
            <div className='name'>{article.ArticleName}</div>
            <div className='additional-info'>
                <span>{translate('priceArticleMappingsTable.optionText.VAT')}: {article.VatRate ? article.VatRate : null}</span>
                {showPrice ? <span>{translate('priceArticleMappingsTable.optionText.price')}: {article.ArticlePrice}{article.CurrencySign}</span> : null}
            </div>
        </Container>
    </>
);

export default ArticleOption
