import { combineReducers, Reducer, AnyAction } from 'redux';
import { createSelector } from 'reselect';
import { format } from '@/utils/datetime';
import groupBy from 'lodash/groupBy';
import { createListLoadingReducer } from './utils';
import * as actions from '../actions';

export const getRecurringScheduleTimes = (state: any) =>
    state.schedulePreview.recurringScheduleTimes;
export const getDateScheduleTimes = (state: any) => state.schedulePreview.dateScheduleTimes;

export const getNavigationDate = (state: any) => state.schedulePreview.ui.navigationDate;

export const getRecurringScheduleTimesGroupedByDay = createSelector(
    getRecurringScheduleTimes,
    (times) => groupBy(times.data, (time) => format(time.From, 'yyyy-MM-dd'))
);
export const getDateScheduleTimesGroupedByDay = createSelector(getDateScheduleTimes, (times) =>
    groupBy(times.data, (time) => format(time.From, 'yyyy-MM-dd'))
);

const uiInitialState = {
    navigationDate: new Date(),
    duration: 30,
};

const recurringScheduleTimes = createListLoadingReducer('RECURRING_SCHEDULE_TIMES');
const dateScheduleTimes = createListLoadingReducer('DATE_SCHEDULE_TIMES');

const uiReducer: Reducer<any, AnyAction> = (state = uiInitialState, { type, payload }) => {
    switch (type) {
        case actions.RESET_SCHEDULE_PREVIEW:
            return { ...state };
        case actions.CHANGE_SCHEDULE_PREVIEW_NAVIGATION_DATE:
            return { ...state, navigationDate: payload };
        case actions.CHANGE_SCHEDULE_PREVIEW_DURATION:
            return { ...state, duration: payload };
        default:
            return state;
    }
};

export default combineReducers({
    recurringScheduleTimes,
    dateScheduleTimes,
    ui: uiReducer,
});
