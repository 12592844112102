import React from 'react';
import styled from 'styled-components';

import { Popup, PopupProps } from 'semantic-ui-react';
import InfoImg from '../pngs/info-icon@2x.png';

const Img = styled.img`
    width: 15px;
    height: 15px;
`;

const Info = (props: PopupProps) => (
    <Popup
        trigger={
            <span>
                <Img src={InfoImg} alt="Info" />
            </span>
        }
        {...props}
    />
);

export default Info;
