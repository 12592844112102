import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { AxiosResponse } from 'axios';

export function* homepageMenu() {
    try {
        const response: AxiosResponse = yield apiClient.get('/homepage/menu');

        yield put(actions.FETCH_HOMEPAGE_MENU.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_HOMEPAGE_MENU.failure(error));
    }
}

export function* editHomepageMenu({ payload }: AnyAction) {
    const edit = actions.EDIT_HOMEPAGE_MENU;
    try {
        const response: AxiosResponse = yield apiClient.put('/homepage/menu', payload);

        yield put(edit.success(response));
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}
