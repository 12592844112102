import React, { useCallback, ReactNode } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { languageCodeToFlagCode } from '@/utils/common';
import useTranslate from '../hooks/useTranslate';
import { ReactComponent as Se } from '../svg/flags/se.svg';
import { ReactComponent as No } from '../svg/flags/no.svg';
import { ReactComponent as Gb } from '../svg/flags/gb.svg';
import { ReactComponent as Fi } from '../svg/flags/fi.svg';
import styled from 'styled-components';

const flags = {
    se: <Se className="language-dropdown__flag" />,
    no: <No className="language-dropdown__flag" />,
    gb: <Gb className="language-dropdown__flag" />,
    fi: <Fi className="language-dropdown__flag" />,
} as { [key: string]: ReactNode };

interface Props {
    item?: boolean;
}

const DropdownWrapper = styled.div`
    .menu {
        z-index: 200!important;

    }
    .dropdown svg {
        border-radius: 12px;
    }
`;

const LanguageDropdown: React.FC<Props> = (props) => {
    const handleLanguageChange = useCallback((e, { name }) => {
        window.localStorage.setItem('language', name);
        document.location.reload();
    }, []);

    const { activeLanguage, languages } = useTranslate();

    return (
        <DropdownWrapper>
            <Dropdown
                style={{ display: 'flex', alignItems: 'center' }}
                item
                trigger={activeLanguage ? flags[languageCodeToFlagCode(activeLanguage.code)] : <div />}
            >
                <Dropdown.Menu>
                    {languages.map((language, index) => (
                        <Dropdown.Item key={index} name={language.code} onClick={handleLanguageChange}>
                            {flags[languageCodeToFlagCode(language.code)]}
                            {language.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </DropdownWrapper>
    );
};

export default LanguageDropdown;
