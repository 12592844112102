import React from 'react';
import { Label, Loader as Spinner, Message, Icon } from 'semantic-ui-react';
import { withLocalize } from 'react-localize-redux';
import styled from 'styled-components';

import DataTable from '@/components/DataTable';
import { useGetWebhookLogsQuery } from '@/services/bmApi';
import { useAppSelector } from '@/hooks';
import { getCompanyId } from '@/store/reducers/newAuth';
import useTranslate from '@/hooks/useTranslate';
import { WebhookLog } from '@/types';
import { format, toDate } from '@/utils/datetime';

const Description = styled.span`
    font-style: italic;
    color: ${(props) => props.theme.gray500};
    font-size: 0.95rem;
`;

const EventTypeRenderer = (row: WebhookLog) => <Label content={row.EventType} />;

const ChannelsRenderer = (row: WebhookLog) => {
    return row.Channels ? row.Channels.map(channel => (<Label content={channel} />)) : null;
};

const TimestampRenderer = (row: WebhookLog) => {
    if(row.Timestamp) {
        const date = toDate(row.Timestamp);
        
        return (
            <React.Fragment>
                {format(date, 'PP')} <Description>{format(date, 'p')}</Description>
            </React.Fragment>
        );

    }

    return null
};

const SystemSetttingsForm: React.FC = (props) => {
    const { translate } = useTranslate();
    const companyId = useAppSelector(getCompanyId);
    
    const logsData = useGetWebhookLogsQuery({ CompanyId: companyId });

    const columns = [
        {
            name: translate('webhooks.logs.eventId.text'),
            attribute: 'EventId',
            type: 'text',
        },
        {
            name: translate('webhooks.logs.eventType.text'),
            renderer: EventTypeRenderer
        },
        {
            name: translate('webhooks.logs.channels.text'),
            renderer: ChannelsRenderer,
        },
        {
            name: translate('webhooks.logs.timestamp.text'),
            renderer: TimestampRenderer,
        },
    ];

    // @ts-ignore
    const errorMessage = logsData.error ? logsData.error.data.ResponseStatus?.Message : '';
    
    return (
        <>
            {logsData.isFetching ? <Spinner data-testid="loader" active /> : null}
            <DataTable data={logsData.data?.Data} columns={columns} />
            {errorMessage ? (
                <Message icon negative>
                    <Message.Content>
                        {errorMessage}
                    </Message.Content>
                </Message>
            ) : null}
        </>
    );
};

export default withLocalize(SystemSetttingsForm);
