import React from 'react';
import { Button } from 'semantic-ui-react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { connect, ConnectedProps } from 'react-redux';
import EndpointsTable from './Endpoints';
import useTranslate from '@/hooks/useTranslate';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '@/components/PageHeader';
import { push } from 'connected-react-router';

interface Props extends LocalizeContextProps, RouteComponentProps, ConnectedProps<typeof connector> {

}

const Endpoints: React.FC<Props> = (props) => {
    const { translate } = useTranslate();

    return (
        <>
        <PageHeader
                breadcrumbs={[
                    { to: '/webhook-settings#endpoints' },
                ]}
               
                content={
                    <Button
                        floated="right"
                        size='large'
                        positive
                        content={translate('webhooks.newEndpoint')}
                        onClick={() => props.dispatch(push('/webhooks/endpoint/new'))}
                    />
                }
            />
            <EndpointsTable  />
        </>
    );
};

const connector = connect(null);

export default connector(withLocalize(Endpoints));


