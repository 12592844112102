import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Form, FormFieldProps } from 'semantic-ui-react';
import Help from '../Help';

const StyledField = styled(Form.Field)`
    /* justify-content: flex-end; */
    /* align-items: baseline; */

    @media (max-width: ${({ theme }) => theme.breakpointmd}) {
        justify-content: flex-start;
    }
`;

const StyledLabel = styled.label`
    /* text-align: right; */
`;

interface Props extends FormFieldProps {
    text?: ReactNode;
    htmlFor?: string;
    help?: ReactNode;
}

const FieldLabel: React.FC<Props> = (props) => {
    const { text, htmlFor, help, ...rest } = props;

    return (
        <StyledField {...rest}>
            <StyledLabel htmlFor={htmlFor}>{text}</StyledLabel>
            {help && <Help content={help} />}
        </StyledField>
    );
};

export default FieldLabel;
