import { select } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { RootState } from 'MyTypes';
import { getUnixTime } from 'date-fns';

export function* runWootrics({ payload }: AnyAction) {
    try {
        if(window && window.localStorage && window.wootric) {
            const authState: unknown = yield select((state) => state.authenticate);
            const company: RootState['company'] = yield select((state) => state.company);
            const language = window.localStorage.getItem('language')?.toUpperCase();
            
            window.wootricSettings = {
                // @ts-ignore
                email: authState.data.UserName,
                language,
                
                created_at: getUnixTime(new Date(company.data.Created)),
                disclaimer: {
                    text: ' ',
                    link: 'https://www.visma.com/privacy/privacy-statement-inmoment/',
                    link_word: 'Privacy Statement',
                },
                properties: {
                    companyId: company.data.Id,
                    // @ts-ignore
                    categoryId: company.data.CategoryId,
                    isFreeAccount: company.data.IsFreeAccount,
                },
                account_token: 'NPS-EU-1a7650bb',
            };
            // @ts-ignore
            // enable for dev purposes
            // window.wootric_survey_immediately = true;
            
            window.wootric('run');
        }
    } catch (error) {
        console.error(error)
    }
}
