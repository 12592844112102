import { put, call, select } from 'redux-saga/effects';
import { push, goBack, replace } from 'connected-react-router';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { confirmSaga } from './confirm';
import { AnyAction } from 'redux';
import { getTranslate } from 'react-localize-redux';
import Notifications from 'react-notification-system-redux';
import { realmParamsMapping } from './auth';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';
import { getUserId, getUsername } from '../reducers/newAuth';

export function* administrators({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/administrators', { params: payload });

        yield put(actions.FETCH_ADMINISTRATORS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_ADMINISTRATORS.failure(error));
    }
}

export function* administratorRoles(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/administrators/roles');

        yield put(actions.FETCH_ADMINISTRATOR_ROLES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_ADMINISTRATOR_ROLES.failure(error));
    }
}

export function* createAdministrator(action: AnyAction) {
    const create = actions.CREATE_ADMINISTRATOR;
    try {
        const response: AxiosResponse = yield apiClient.post(`/administrators`, action.payload);

        yield put(create.success(response));
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* editAdministrator(action: AnyAction) {
    const edit = actions.EDIT_ADMINISTRATOR;
    try {
        const response: AxiosResponse = yield apiClient.put(
            `/administrators/${action.payload.Id}`,
            action.payload
        );

        yield put(edit.success(response));
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* deleteAdministrator({ payload, meta: { confirmationMessage } }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga, confirmationMessage);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/administrators/${payload.Id}`, payload);

            yield put(actions.DELETE_ADMINISTRATOR.success(response));
            yield put(actions.FETCH_ADMINISTRATORS.request());
            yield put(push('/administrators'));
        } catch (error) {
            yield put(actions.DELETE_ADMINISTRATOR.failure(error));
        }
    }
}

export function* deleteUser({ payload, meta }: AnyAction) {
    const localizeState: RootState['localize'] = yield select((state) => state.localize);
    const translate = getTranslate(localizeState);
    const userId: string = yield select(getUserId);

    if (payload.Id === userId) {
        const confirmed: boolean = yield call(
            confirmSaga,
            translate('deleteAccount.deleteConfirmation') as string,
            { danger: true }
        );
        if (confirmed) {
            try {
                const username: string = yield select(getUsername);
                const response: AxiosResponse = yield apiClient.delete(`/users`, {
                    params: {
                        UserName: username,
                        // @ts-ignore
                        Realm: realmParamsMapping[process.env.REACT_APP_KEYCLOAK_REALM],
                        ForceDelete: false,
                    },
                });

                yield put(Notifications.show({ title: translate('deleteAccount.userDeleteEmailSent') as string }, 'success'));
                yield put(actions.FETCH_ADMINISTRATORS.request());
                yield put(push('/administrators'));
            } catch (error) {
                // yield put(Notifications.show({ title: error.message }, 'error'));
                yield put(actions.DELETE_ADMINISTRATOR.failure(error));
                yield put(push('/administrators'));
            }
        }
    } else {
        yield put({
            type: actions.DELETE_ADMINISTRATOR.REQUEST,
            payload,
            meta,
        });
    }
}
