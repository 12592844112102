import { AnyAction } from 'redux';
import { put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { confirmSaga } from './confirm';
import { format } from '@/utils/datetime';
import { AxiosResponse } from 'axios';
import exportToXLSX from '@/utils/exportToXLSX';

export function* timeExceptions({ payload, meta }: AnyAction) {
    try {

        if(meta?.toXLSX && meta?.columns) {
            yield put(actions.EXPORT_TIME_EXCEPTIONS.request());
        }

        const response: AxiosResponse = yield apiClient.get('/timeexceptions', {
            params: { IncludeResourceDetails: true, ...payload },
        });
        if (meta?.toXLSX && meta?.columns) {
            yield exportToXLSX(response.data && response.data.Results, meta.columns);
            yield put(actions.EXPORT_TIME_EXCEPTIONS.success());
        }

        yield put(actions.FETCH_TIME_EXCEPTIONS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_TIME_EXCEPTIONS.failure(error));
    }
}

const transformObject = (formValues: any) => ({
    ...formValues,
    ResourceIds: formValues.Resources.reduce(
        (acc: any[], value: any) => (value.selected ? [...acc, value.Id] : acc),
        []
    ),
    Resources: undefined,
    From: format(formValues.From, 'yyyy-MM-d'),
    To: format(formValues.To, 'yyyy-MM-d'),
});

export function* createTimeException({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post(`/timeexceptions`, transformObject(payload));

        yield put(actions.CREATE_TIME_EXCEPTION.success(response));
    } catch (error: any) {
        if (error.response && error.response.status === 409) {
            const objToSubmit = transformObject(payload);

            yield put({
                type: actions.TIME_EXCEPTION_BOOKINGS_CONFLICT,
                payload: error.response.data,
            });

            yield put(actions.FETCH_COLLIDING_EVENTS.request(objToSubmit));
        } else {
            yield put(actions.CREATE_TIME_EXCEPTION.failure(throwSubmissionError(error)));
        }
    }
}

export function* editTimeException({ payload }: AnyAction) {
    const objToSubmit = transformObject(payload);
    try {
        const response: AxiosResponse = yield apiClient.put(`/timeexceptions/${payload.Id}`, objToSubmit);

        yield put(actions.EDIT_TIME_EXCEPTION.success(response));
    } catch (error: any) {
        if (error.response && error.response.status === 409) {
            yield put({
                type: actions.TIME_EXCEPTION_BOOKINGS_CONFLICT,
                payload: error.response.data,
            });

            yield put(actions.FETCH_COLLIDING_EVENTS.request(objToSubmit));
        }

        yield put(actions.EDIT_TIME_EXCEPTION.failure(throwSubmissionError(error)));
    }
}

export function* deleteTimeException({ payload, meta }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/timeexceptions/${payload.Id}`, {
                params: payload,
            });

            yield put(actions.DELETE_TIME_EXCEPTION.success(response));
            if (meta.onSuccess) {
                meta.onSuccess();
            } else {
                yield put(push('/time-exceptions'));
            }
        } catch (error) {
            yield put(actions.DELETE_TIME_EXCEPTION.failure(throwSubmissionError(error)));
        }
    }
}

export function* fetchCollidingEvents({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/timeexceptions/collidingevents', {
            params: {
                IncludeServiceInformation: true,
                IncludeCustomerInformation: true,
                ...payload,
            },
        });

        yield put(
            actions.FETCH_COLLIDING_EVENTS.success({ Results: response.data.Bookings || [] })
        );
    } catch (error) {
        yield put(actions.FETCH_COLLIDING_EVENTS.failure(error));
    }
}
