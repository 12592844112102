import { AnyAction } from 'redux';
import { call, put, select } from 'redux-saga/effects';
import { getTranslate } from 'react-localize-redux';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { AxiosResponse } from 'axios';
import { WebhookSettingsQuery, WebhookSettingsResponse } from '@/types';
import { PayloadAction } from '@reduxjs/toolkit';
import bmApi from '@/services/bmApi';
import { confirmSaga } from './confirm';
import { ApplicationState } from '@/store';

export function* fetchWebhookSettings(action: AnyAction) {
    try {
        const response: AxiosResponse<WebhookSettingsResponse> = yield apiClient.get('/webhook/settings');

        yield put(actions.FETCH_WEBHOOK_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_WEBHOOK_SETTINGS.failure(error));
    }
}

export function* editWebhookSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse<WebhookSettingsResponse> = yield apiClient.put('/webhook/settings', payload);

        yield put(actions.EDIT_WEBHOOK_SETTINGS.success(response));
    } catch (error) {
        yield put(actions.EDIT_WEBHOOK_SETTINGS.failure(throwSubmissionError(error)));
    }
}


