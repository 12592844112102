import { combineReducers, compose, Reducer, AnyAction } from 'redux';
import { createLoadingReducer } from './utils';
import * as actions from '../actions';

const icalCreateDeleteReducer = (state: any, action: AnyAction) => {
    switch (action.type) {
        case actions.CREATE_ICAL_TOKEN.REQUEST:
            return { ...state, isLoading: true };
        case actions.CREATE_ICAL_TOKEN.SUCCESS:
            return { ...state, isLoading: false };
        case actions.CREATE_ICAL_TOKEN.FAILURE:
            return { ...state, isLoading: false };
        case actions.DELETE_ICAL_TOKEN_CONFIRMED:
            return { ...state, isLoading: true };
        case actions.DELETE_ICAL_TOKEN.SUCCESS:
            return { ...state, isLoading: false };
        case actions.DELETE_ICAL_TOKEN.FAILURE:
            return { ...state, isLoading: false };
        default:
            return state;
    }
}

const googleCreateDeleteReducer = (state: any, action: AnyAction) => {
    switch (action.type) {
        case actions.CREATE_GOOGLE_CALENDAR_TOKEN.REQUEST:
            return { ...state, isLoading: true };
        case actions.CREATE_GOOGLE_CALENDAR_TOKEN.SUCCESS:
            return { ...state, isLoading: false };
        case actions.CREATE_GOOGLE_CALENDAR_TOKEN.FAILURE:
            return { ...state, isLoading: false };
        case actions.DELETE_GOOGLE_CALENDAR_TOKEN_CONFIRMED:
            return { ...state, isLoading: true };
        case actions.DELETE_GOOGLE_CALENDAR_TOKEN.SUCCESS:
            return { ...state, isLoading: false };
        case actions.DELETE_GOOGLE_CALENDAR_TOKEN.FAILURE:
            return { ...state, isLoading: false };
        default:
            return state;
    }
}


const icalTokensReducer = (state: any, action: AnyAction) => {
    const icalTokensState = createLoadingReducer('ICAL_TOKENS')(state, action);
    return icalCreateDeleteReducer(icalTokensState, action);
}

const googleCalendarTokensReducer = (state: any, action: AnyAction) => {
    const googleCalendarState = createLoadingReducer('GOOGLE_CALENDAR_TOKENS')(state, action);

    return googleCreateDeleteReducer(googleCalendarState, action);
}

export default combineReducers({
    googleCalendarTokens: googleCalendarTokensReducer,
    iCalTokens: icalTokensReducer,
});
