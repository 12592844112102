import { put } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

export function* statistics({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/statistics', {
            params: {
                IncludeOccuringBookingsByDay: true,
                IncludeCreatedBookingsByDay: true,
                IncludeCreatedCustomersByDay: true,
                IncludeBookingsCanceledByDay: true,
                ...payload,
            },
        });

        yield put(actions.FETCH_STATISTICS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_STATISTICS.failure(error));
    }
}
