import { put, select, all } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import { AnyAction } from 'redux';
import { PrismicClient } from '@/utils/prismicHelpers';
import * as prismicDOM from 'prismic-dom';
import { getFullLanguageCode } from '@/utils/common';
import Notifications from 'react-notification-system-redux';
import { AxiosResponse } from 'axios';
import ApiSearchResponse from '@prismicio/client/types/ApiSearchResponse';

export function* checkAgreement({ payload }: AnyAction) {
    const lang: string = yield select((s) => s.localize.languages.find((l: any) => l.active)?.code);
    const userId: string = yield select((s) => s.authenticate.data?.UserId);
    const fullLangCode = getFullLanguageCode(lang);

    try {
        const { data: userAgreement } = yield apiClient.get(`/users/agreement`, { params: { UserId: userId } });

        if (!userAgreement.AgreedToLatest) {
            const prismicDocumentIds = userAgreement.Documents;
            
            const response: ApiSearchResponse = yield PrismicClient().getByIDs(prismicDocumentIds, { lang: fullLangCode });
            const  data = response.results[0].data;

            yield put({
                type: actions.SHOW_AGREEMENT_MODAL,
                payload: {
                    agreementId: userAgreement.AgreementId,
                    content: prismicDOM.RichText.asHtml(data.content),
                },
            });

        }
    } catch (error) {
        // do nothing
        console.log(error)
    }
}

export function* acceptAgreement({ payload }: AnyAction) {
    try {
        yield apiClient.post('/users/agreement', {
            UserId: payload.userId,
            AgreementId: payload.agreementId
        });
        yield put({ type: actions.HIDE_AGREEMENT_MODAL });
    } catch (error: any) {
        yield put(
            Notifications.show(
                { title: error.response?.data?.ResponseStatus?.Message || error.message },
                'error'
            )
        );
    }
}