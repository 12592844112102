import React from 'react';
import styled from 'styled-components';
import { Popup, Icon, PopupProps } from 'semantic-ui-react';

const HelpIcon = styled(Icon)`
    color: #bbb;
`;

const Help = (props: PopupProps) => (
    <Popup
        trigger={
            <span>
                <HelpIcon name="question" />
            </span>
        }
        {...props}
    />
);

export default Help;
