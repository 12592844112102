import { AnyAction } from 'redux';
import { put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { confirmSaga } from './confirm';
import { AxiosResponse } from 'axios';

export function* homepagePosts({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/news', {
            params: payload,
        });

        yield put(actions.FETCH_HOMEPAGE_POSTS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_HOMEPAGE_POSTS.failure(error));
    }
}

export function* createHomepagePost(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post(`/news`, action.payload);

        yield put(actions.CREATE_HOMEPAGE_POST.success(response));
    } catch (error) {
        yield put(actions.CREATE_HOMEPAGE_POST.failure(throwSubmissionError(error)));
    }
}

export function* editHomepagePost(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(`/news/${action.payload.Id}`, action.payload);

        yield put(actions.EDIT_HOMEPAGE_POST.success(response));
    } catch (error) {
        yield put(actions.EDIT_HOMEPAGE_POST.failure(throwSubmissionError(error)));
    }
}

export function* deleteHomepagePost({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/news/${payload.Id}`, payload);

            yield put(actions.DELETE_HOMEPAGE_POST.success(response));
            yield put(push('/homepage/posts'));
        } catch (error) {
            yield put(actions.DELETE_HOMEPAGE_POST.failure(throwSubmissionError(error)));
        }
    }
}
