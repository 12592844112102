import { createSelector } from 'reselect';
import { Reducer, AnyAction } from 'redux';
import { selectResourceTypes } from './resourcetypes';
import { createListLoadingReducer, listInitialState } from './utils';
import reduceReducers from 'reduce-reducers';
import * as actions from '../actions';
import { Resource, ListState } from '../../types';

export const getResources = (state: any) => state.resources;

export const getResourceWithoutType = createSelector(
    [getResources, selectResourceTypes],
    (resources, resourceTypes) => {
        if (!resourceTypes.isLoaded) return [];

        return resources.data.filter((resource: Resource) => {
            return !resourceTypes.data.find((resourceType: any) => {
                return resourceType.Resources.find(
                    (resourceWithType: any) => resourceWithType.Id === resource.Id
                );
            });
        });
    }
);

export const getResourceById = (id: string) =>
    createSelector(getResources, (resources) =>
        resources.data.find((resource: any) => resource.Id.toString() === id)
    );

interface UIState {
    showBookingsConflictModal: boolean;
    conflictData: any;
}

interface ExtraState {
    selectedResources: Resource[]
}

interface ResourcesState extends ListState<Resource>, UIState, ExtraState {}

export const dataReducer = (createListLoadingReducer('RESOURCES') as unknown) as Reducer<
    ResourcesState,
    AnyAction
>;

const uiInitialState = {
    ...listInitialState,
    showBookingsConflictModal: false,
    conflictData: null,
};

const extraState = {
    selectedResources: []
};

const initialState = {
    ...listInitialState,
    ...uiInitialState,
    ...extraState
};

const extraStateReducer: Reducer<ResourcesState, AnyAction> = (state = initialState, { type, payload }) => {
    switch (type) {
        case actions.FILTER_RESOURCES:
            const selectedResources = Object.keys(payload)
            .filter((key) =>
                payload[key] &&
                state.data.find((r) => r.Id.toString() === key.toString())
            )
            .map(key => state.data.find((r) => r.Id.toString() === key.toString()) as Resource)
            
            return {
                ...state,
                selectedResources: selectedResources,
            }
    
        default:
            return state;
    }
};

export const uiReducer: Reducer<ResourcesState, AnyAction> = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case actions.RESOURCE_BOOKINGS_CONFLICT:
            return { ...state, showBookingsConflictModal: true, conflictData: payload };
        case actions.CLOSE_RESOURCE_BOOKINGS_CONFLICT_MODAL:
            return { ...state, showBookingsConflictModal: false, conflictData: null };
        default:
            return state;
    }
};

export default (reduceReducers(initialState, dataReducer, uiReducer, extraStateReducer) as unknown) as Reducer<
    ResourcesState,
    AnyAction
>;
