import { put, call } from 'redux-saga/effects';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { confirmSaga } from './confirm';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';
import { GetWebhookEndointsResponse } from '@/types';
import bmApi from '@/services/bmApi';
import { push } from 'connected-react-router';

export function* fetchWebhookEndpoints(action: AnyAction) {
    try {
        const response: AxiosResponse<GetWebhookEndointsResponse> = yield apiClient.get('/webhook/endpoints');

        yield put(actions.FETCH_WEBHOOK_ENDPOINTS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_WEBHOOK_ENDPOINTS.failure(error));
    }
}

export function* editWebhookEndpoint({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(`/webhook/endpoints/${payload.Id}`, payload);

        yield put(actions.EDIT_WEBHOOK_ENDPOINT.success(response));
    } catch (error) {
        yield put(actions.EDIT_WEBHOOK_ENDPOINT.failure(throwSubmissionError(error)));
    }
}

export function* createWebhookEndpoint(action: AnyAction) {
    const create = actions.CREATE_WEBHOOK_ENDPOINT;
    try {
        const response: AxiosResponse = yield apiClient.post(`/webhook/endpoints`, action.payload);

        yield put(create.success(response));
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* deleteEndpoint({ payload, meta }: AnyAction) { 
    const confirmed: boolean = yield call(
        confirmSaga,
        meta.confirmationMessage,
        { danger: true }
    );

    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/webhook/endpoints/${payload.Id}`, payload);

            yield put(actions.DELETE_WEBHOOK_ENDPOINT.success(response));
            
            yield put(bmApi.util.invalidateTags(['webhooks']));
            yield put(push('/webhook-settings#endpoints'));
        } catch (error) {
            yield put(actions.DELETE_WEBHOOK_ENDPOINT.failure(error));
        }
    }
}

