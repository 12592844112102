import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Menu, Dropdown, Icon } from 'semantic-ui-react';
import useTranslate from '../hooks/useTranslate';
import * as actions from '../store/actions';
import LanguageDropdown from './LanguageDropdown';
import { RootState } from 'MyTypes';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

const CompanyName = styled.div`
    font-size: 20px;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 27px;
`;

const Bar = styled.div<{isMobile: boolean}>`
    width: 100%;
    /* background-color: white; */
    border-bottom: 4px solid var(--neutral-30);
    & .ui.menu {
        background: linear-gradient(90deg, #48237D 0%, #8049CC 100%),
        linear-gradient(0deg, #DADADA, #DADADA);
        & .item {
            color: var(--neutral-05);
        }
        & .item:hover {
            color: var(--neutral-05);
        }
        a.item:hover {
            color: var(--neutral-05);
        }
    }
    i {
        width: 14px;
        height: 14px;
    }
    & ${CompanyName} {
        position: ${(props) => props.isMobile ? `absolute` : 'initial'};
        position: ${(props) => props.isMobile ? `hidden` : 'visible'};
        z-index: ${(props) => props.isMobile ? `-1` : '0'};
    }
    &  a.item ${LogoContainer} {
        ${(props) => props.isMobile ? `margin-right: 0;` : ''};
    }
`;

const IconWrapper = styled.div<{sidebarOpened: boolean}>`
    i {
        transition: 0.1s all;
        transform: ${(props: { sidebarOpened: boolean }) => props.sidebarOpened ? "rotate(-90deg)" : ""};
    }
`;


const Logo = styled.img`
    width: 28px;
    height: auto;
    margin-right: 10px;
`;


interface OwnProps extends ReturnType<typeof mapStateToProps> {

}
interface Props {
    sidebarOpened: boolean;
    toggleSidebarOpen: (e: React.MouseEvent) => void;
    openSubscriptionModal: (e: React.MouseEvent) => void;
}
interface Props extends RouteComponentProps {
    
}

const Header = (props: Props & OwnProps) => {
    const { translate } = useTranslate();
    const { authenticate } = useSelector(({ authenticate }: any) => ({
        authenticate,
    }));

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(actions.authLogout());
    }

    const handleMyAccount = () => {
        props.history.push('/account');
    }

    const handlePasswordChange = () => {
        props.history.push('/change-password');
    };

    return (
        <Bar isMobile={props.isMobile}>
            <Menu attached style={{ border: 'none' }}>
                <Menu.Item onClick={props.toggleSidebarOpen} link={false}>
                    <LogoContainer>
                        <CompanyName>{translate('common.BokaMera')}</CompanyName>
                    </LogoContainer>
                    <IconWrapper sidebarOpened={props.sidebarOpened}>
                        {props.isMobile ? (
                            <Icon disabled name="bars" />
                        ) : (
                            <Icon disabled name="angle down" />
                        )}
                    </IconWrapper>
                </Menu.Item>
                <Menu.Menu position="right">
                    {props.isFreeAccount ? (
                        <Menu.Item
                            style={{
                                backgroundColor: '#F28E2C',
                                color: '#FFFFFF',
                            }}
                            onClick={props.openSubscriptionModal}
                        >
                            {translate('upgrade')}
                        </Menu.Item>
                    ) : null}
                    <LanguageDropdown item />
                    <Dropdown
                        style={{ zIndex: 200 }}
                        icon={'solid dropdown'}
                        className="circled"
                        data-cy="user-dropdown"
                        item
                        trigger={
                            !props.isMobile ? (
                                <div>
                                    <span>
                                        {authenticate.data && authenticate.data.DisplayName}
                                    </span>
                                </div>
                            ) : null
                        }
                    >
                        <Dropdown.Menu data-testid="headerDropdown">
                            {props?.company?.Name ? (
                                <Dropdown.Header>
                                    {props.company.Name}
                                </Dropdown.Header>
                            ) : null}
                            <Dropdown.Item onClick={handleMyAccount}>
                                {translate('routes.myAccount')}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handlePasswordChange}>
                                {translate('changePassword.title')}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout}>
                                {translate('common.logout')}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </Menu>
        </Bar>
    );
};

const mapStateToProps = ({
    company,
    browser
}: RootState) => {
    return {
        isFreeAccount: !!company?.data?.IsFreeAccount,
        isMobile: browser.is.extraSmall,
        company: company.data
    };
};

export default compose<React.ComponentType<Props>>(connect(mapStateToProps), withRouter)(Header);