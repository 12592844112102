import React from 'react';
import Loader from '@/components/Loader';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { compose } from '@reduxjs/toolkit';
import { withRouter } from 'react-router-dom';

import { ApplicationState } from '@/store';
import * as actions from '@/store/actions';
import ServiceView from '../Service/components/ServiceInformationOnly';
import { getGroupedServices } from '@/store/reducers/services';
import { push } from 'connected-react-router';

interface Props extends ConnectedProps<typeof connector>, RouteComponentProps<any>, LocalizeContextProps {}

const CreateService: React.FC<Props> = (props) => {
    const { resourceTypes, schedules, currencies, serviceDurationTypes, serviceGroups, dispatch } = props;
  return (
      <Loader
          requirements={[
              {
                  action: actions.FETCH_RESOURCE_TYPES.request({ Active: true }),
                  data: resourceTypes,
              },
              {
                  action: actions.FETCH_RECURRING_SCHEDULES.request({ Active: true }),
                  data: schedules.recurring,
              },
              {
                  action: actions.FETCH_DATE_SCHEDULES.request({ Active: true }),
                  data: schedules.date,
              },
              {
                  action: actions.FETCH_CURRENCIES.request(),
                  data: currencies,
              },
              {
                  action: actions.FETCH_SERVICE_GROUPS.request(),
                  data: serviceGroups,
              },
              {
                  action: actions.FETCH_SERVICE_DURATION_TYPES.request(),
                  data: serviceDurationTypes,
              },
              {
                  action: actions.FETCH_CALCULATION_TYPES.request(),
                  data: serviceDurationTypes,
              },
          ]}
      >
          <ServiceView
              {...props}
              editing
              onToggleEdit={() => {
                props.dispatch(push('/setup-account'))
              }}
              onSubmitSuccess={() => {}}
                //   @ts-ignore
              service={{}}
          />
      </Loader>
  );
}

const mapStateToProps = (state: ApplicationState) => {
    const schedules = state.schedules;


    return {
        schedules,
        services: state.services,
        serviceDurationTypes: state.serviceDurationTypes,
        serviceGroups: state.serviceGroups,
        customFields: state.customFields,
        prices: state.prices,
        currencies: state.currencies,
        resourceTypes: state.resourceTypes,
        groupedServices: getGroupedServices(state),
        calculationTypes: state.calculationTypes
    };
};

const connector = connect(mapStateToProps);

export default compose(
    connector,
    withRouter,
    withLocalize
)(CreateService) as React.FC;