import { operations } from '@/apitypes';
import { AnyAction, Reducer } from 'redux';
import * as actions from '../actions';

const initialState = {
    isLoading: false,
    isLoaded: false,
    hasError: false,
    loadedAt: undefined,
    error: undefined,
    data: [] as Array<operations["DurationTypeQuerydurationtypes_Get"]['responses']['200']['schema']>,
};

const reducer: Reducer<any, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_SERVICE_DURATION_TYPES.REQUEST: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                hasError: false,

                error: undefined,
                data: []
            };
        }
        case actions.FETCH_SERVICE_DURATION_TYPES.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                hasError: false,
                loadedAt: new Date(),
                error: undefined,
                data: action.payload
            };
        }
        case actions.FETCH_SERVICE_DURATION_TYPES.FAILURE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                hasError: true,
                loadedAt: undefined,
                error: action.payload,
                data: [],
            };
        }
        default:
            return state;
    }
};

export default reducer;
