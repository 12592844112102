import React from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import CompanySettings from '@/containers/Company/Loadable';
import BillingInformationSettings from '@/containers/BillingInformationSettings/Loadable';
import Administrators from '@/containers/Administrators/Loadable';

import { RouteComponentProps } from 'react-router';
import { Tab, TabProps } from 'semantic-ui-react';
import PageHeader from '@/components/PageHeader';


interface Props extends RouteComponentProps, LocalizeContextProps {

}

const CompanyMainLoader: React.FC<Props> = (props) => {
    const { translate, location, history } = props;
    const { hash } = location;

    const handleTabChange = (e: React.MouseEvent, { activeIndex, panes }: TabProps) => {
        history.push(
            // Check if activeIndex property is valid
            // @ts-ignore
            `${location.pathname}#${panes[activeIndex].menuItem.key}`
        );
    };

    const panes = [
        {
            menuItem: {
                content: (
                    <div>
                        {translate('company.tab.information')}
                    </div>
                ),
                key: 'information',
            },
            render: () => (
                <Tab.Pane>
                    <CompanySettings {...props} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                content: (
                    <div>
                        {translate('company.tab.billingInformation')}
                    </div>
                ),
                key: 'billing-information',
            },
            render: () => (
                <Tab.Pane>
                    <BillingInformationSettings {...props} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                content: (
                    <div>
                        {translate('company.tab.administrators')}
                    </div>
                ),
                key: 'administrators',
            },
            render: () => (
                <Tab.Pane>
                    <Administrators {...props} />
                </Tab.Pane>
            ),
        }
    ];

    let activeHashIndex = panes.findIndex((pane) => pane.menuItem.key === hash.slice(1));
    activeHashIndex = activeHashIndex !== -1 ? activeHashIndex : 0;

    return (
        <>
            <PageHeader
                visible={false}
                breadcrumbs={[{ to: '/', content: translate('routes.company') }]}
            />
            <Tab
                onTabChange={handleTabChange}
                panes={panes}
                activeIndex={activeHashIndex}
            />
        </>
    );
};


export default withLocalize(CompanyMainLoader)
