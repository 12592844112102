import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy'
import { AnyAction, Reducer } from 'redux';
import { Service } from '../../types';
import * as actions from '../actions';

const initialState = {
    isLoading: false,
    isLoaded: false,
    hasError: false,
    loadedAt: undefined,
    error: undefined,
    data: [],
};

export const getServices = (state: any) => state.services;

export const getNormalizedServices = (state: any) =>
    state.services.data.reduce((acc: any, item: Service) => ({ ...acc, [item.Id]: item }), {});

export const getServiceById = (id: number) =>
    createSelector(getServices, (services) =>
        services.isLoaded
            ? services.data.find((service: Service) => String(service.Id) === String(id))
            : null
    );

export const getGroupedServices = createSelector(getServices, (services) =>
    groupBy(services.data, 'Group')
);

export const getGroupedSortedServices = createSelector(getServices, (services) => 
    groupBy(sortBy(services.data, ['Group', 'Name']), 'Group'));

const reducer: Reducer<any, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_SERVICES.REQUEST: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                hasError: false,

                error: undefined,
                data: [],
                total: undefined,
            };
        }
        case actions.FETCH_SERVICES.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                hasError: false,
                loadedAt: new Date(),
                error: undefined,
                data: action.payload.Results,
                total: action.payload.Total,
                offset: action.payload.Offset,
            };
        }
        case actions.FETCH_SERVICES.FAILURE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                hasError: true,
                loadedAt: undefined,
                error: action.payload,
                data: [],
                total: undefined
            };
        }
        default:
            return state;
    }
};

export default reducer;
