import { AnyAction } from 'redux';
import { put, call } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { push } from 'connected-react-router';
import { apiClient, throwSubmissionError } from '@/utils';
import { confirmSaga } from './confirm';
import { AxiosResponse } from 'axios';

export function* homepageImages({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/homepage/images', {
            params: payload,
        });

        yield put(actions.FETCH_HOMEPAGE_IMAGES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_HOMEPAGE_IMAGES.failure(error));
    }
}

export function* createHomepageImage(action: AnyAction) {
    const create = actions.CREATE_HOMEPAGE_IMAGE;
    try {
        const response: AxiosResponse = yield apiClient.post(`/homepage/images`, action.payload);

        yield put(create.success(response));
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* editHomepageImage(action: AnyAction) {
    const edit = actions.EDIT_HOMEPAGE_IMAGE;
    try {
        const response: AxiosResponse = yield apiClient.put(
            `/homepage/images/${action.payload.Id}`,
            action.payload
        );

        yield put(edit.success(response));
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* deleteHomepageImage({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/homepage/images/${payload.Id}`, payload);

            yield put(actions.DELETE_HOMEPAGE_IMAGE.success(response));
            yield put(push('/homepage/images'));
        } catch (error) {
            yield put(actions.DELETE_HOMEPAGE_IMAGE.failure(throwSubmissionError(error)));
        }
    }
}
