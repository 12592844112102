import {
    createSlice,
    createListenerMiddleware,
    isAnyOf,
    TypedStartListening,
} from '@reduxjs/toolkit';
import Notifications from 'react-notification-system-redux';
import { ApplicationDispatch, ApplicationState } from '@/store';
import bmApi from '@/services/bmApi';
import { getTranslate } from 'react-localize-redux';

export const resyncCodelockListener = createListenerMiddleware();

export type ResyncCodelockStartListening = TypedStartListening<ApplicationState, ApplicationDispatch>;

const startResyncCodelockListening = resyncCodelockListener.startListening as ResyncCodelockStartListening;

startResyncCodelockListening({
    matcher: isAnyOf(
        bmApi.endpoints.resyncCodelock.matchFulfilled,
        bmApi.endpoints.resyncCodelock.matchRejected,
    ),
    effect: async (action, api) => {
        const translate =  getTranslate(api.getState().localize);

        if(bmApi.endpoints.resyncCodelock.matchFulfilled(action)) {
            api.dispatch(Notifications.show({ title: translate('editBooking.paymentLog.ExternalReference.resyncSuccessMessage') as string }, 'success'));
        }

        if(bmApi.endpoints.resyncCodelock.matchRejected(action)) {
            api.dispatch(Notifications.show({ title: action.error.message as string }, 'error'));
        }
    }
})

const resyncCodelock = createSlice({
    name: 'auth',
    initialState: {
        data: null
    },
    reducers: {

    }
});

export default resyncCodelock;

