import { AnyAction } from 'redux';
import { put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { confirmSaga } from './confirm';
import { AxiosResponse } from 'axios';

export function* customFields({ payload = {} }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/customfields', {
            params: { IncludeCustomFieldValues: true, ...payload },
        });

        yield put(actions.FETCH_CUSTOM_FIELDS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_CUSTOM_FIELDS.failure(error));
    }
}

export function* customFieldSlots({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/customfields/slots', { params: payload });

        yield put(actions.FETCH_CUSTOM_FIELD_SLOTS.success({ Results: response.data }));
    } catch (error) {
        yield put(actions.FETCH_CUSTOM_FIELD_SLOTS.failure(error));
    }
}

export function* customFieldValidations({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/customfields/validations');

        yield put(actions.FETCH_CUSTOM_FIELD_VALIDATIONS.success({ Results: response.data }));
    } catch (error) {
        yield put(actions.FETCH_CUSTOM_FIELD_VALIDATIONS.failure(error));
    }
}

const transformFormData = (formData: any) => ({
    ...formData,
    Values:
        formData.DataType === 'DropDown' && formData.Values
            ? formData.Values.map((item: any, index: number) => ({
                  ...item,
                  SortOrder: index,
              }))
            : [],
    Services:
        formData.Services && !formData._ApplyForAllServices
            ? formData.Services.filter((service: any) => service.selected)
            : [],
});

export function* createCustomField({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post(`/customfields`, transformFormData(payload));

        yield put(actions.CREATE_CUSTOM_FIELD.success(response));
    } catch (error) {
        yield put(actions.CREATE_CUSTOM_FIELD.failure(throwSubmissionError(error)));
    }
}

export function* editCustomField({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(
            `/customfields/${payload.Id}`,
            transformFormData(payload)
        );

        yield put(actions.EDIT_CUSTOM_FIELD.success(response));
    } catch (error) {
        yield put(actions.EDIT_CUSTOM_FIELD.failure(throwSubmissionError(error)));
    }
}

export function* deleteCustomField({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/customfields/${payload.Id}`, payload);

            yield put(actions.DELETE_CUSTOM_FIELD.success(response));
            yield put(push('/custom-fields'));
        } catch (error) {
            yield put(actions.DELETE_CUSTOM_FIELD.failure(throwSubmissionError(error)));
        }
    }
}
