import React from 'react';
import { TypedUseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';

import Loading from '@/components/Loading';
import { Header, Message } from 'semantic-ui-react';
import CorrelationIds from './CorrelationIds';

interface Props {
    queries: TypedUseQueryHookResult<any, any, any>[];
}

const RTKLoader: React.FC<Props> = ({ queries, children }) => {
    queries.some((q) => q.isError);
    return (
        <>
            {queries.every((q) => q.isSuccess) ? (
                children
            ) : queries.some((q) => q.isLoading) ? (
                <Loading />
            ) : queries.some((q) => q.isError) ? (
                <>
                    {queries
                        .filter((q) => q.isError)
                        .map((q) => (
                            <Message
                                error
                                content={
                                    <>
                                        <Header>{q.endpointName}</Header>
                                        <p>{q.error?.error || q.error?.status}</p>
                                        <CorrelationIds />
                                    </>
                                }
                            />
                        ))}
                </>
            ) : null} 
        </>
    );
};

export default RTKLoader;
