import { AnyAction } from 'redux';
import { put, call, select } from 'redux-saga/effects';
import { arrayPush, clearFields, focus, stopSubmit, clearSubmitErrors } from 'redux-form';
import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError, convertErrorToFormError } from '@/utils';
import { DEFAULT_SCHEDULER_COLOR } from '@/utils/constants';
import { confirmSaga } from './confirm';
import { push } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';
import qs from 'qs';
import { ApplicationState } from '@/store';
import Notifications from 'react-notification-system-redux';

export function* resources({ payload = {} }: AnyAction) {
    try {        
        const response: AxiosResponse = yield apiClient.get('/resource', {
            params: { Active: true, ...payload },
        });

        yield put({
            type: actions.FETCH_RESOURCES.SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        yield put({
            type: actions.FETCH_RESOURCES.FAILURE,
            payload: error,
        });
    }
}

export function* editResource({ payload, meta }: AnyAction) {
    const edit = actions.EDIT_RESOURCE;
    try {
        const response: AxiosResponse = yield apiClient.put(`/resource/${payload.Id}`, payload);

        yield put(edit.success(response));

        // Reload the list (for instance, after reactivate  a resource)
        if (meta && meta.reloadListOnSuccess) {
            const resourcesState: RootState['resources'] = yield select((state) => state.resources);
            // @ts-ignore
            const { requestActionPayload } = resourcesState;
            yield put(actions.FETCH_RESOURCES.request(requestActionPayload));
        }
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* createResource({ payload, meta }: AnyAction) {
    const create = actions.CREATE_RESOURCE;
    const location: Location = yield select((s: ApplicationState) => s.router.location);

    try {
        const response: AxiosResponse = yield apiClient.post(
            '/resource',
            // Add default color
            { Color: DEFAULT_SCHEDULER_COLOR, ...payload }
        );

        yield put(create.success(response.data));
        const query = qs.parse(location.search.slice(1));

        // If resource is being created from resource type form, add related changes to this form
        if (meta && meta.formName) {
            yield put(
                arrayPush(meta.formName, 'Resources', {
                    ...response.data,
                    Selected: true,
                })
            );

            yield put(clearSubmitErrors(meta.formName));
            yield put(clearFields(meta.formName, false, false, 'NewResource'));
            yield put(focus(meta.formName, 'NewResource.Name'));
        }
    } catch (error: any) {
        yield put(create.failure(throwSubmissionError(error)));
        if(error?.response?.data?.ResponseStatus?.Message) {
            yield put(Notifications.show({ title: error.response.data.ResponseStatus.Message as string }, 'warning'))
        }
        // If method is called from resourceType form
        if (meta && meta.formName) {
            yield put(clearFields(meta.formName, false, false, 'NewResource'));
            yield put(stopSubmit(meta.formName, convertErrorToFormError(error, 'NewResource')));
        }
    }
}

export function* deleteResource({ payload }: AnyAction) {
    const location: Location = yield select((s: ApplicationState) => s.router.location);

    let confirmed: boolean = payload.Force;
    if (!confirmed) {
        confirmed = yield call(confirmSaga);
    }

    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/resource/${payload.Id}`, { params: payload });

            yield put(actions.DELETE_RESOURCE.success(response));

            const query = qs.parse(location.search.slice(1));

            if (!query.afterSuccess) {
                yield put(push('/resources'));
            }
        } catch (error: any) {
            if (error.response && error.response.status === 409) {
                yield put({ type: actions.RESOURCE_BOOKINGS_CONFLICT });
            } else {
                yield put(actions.DELETE_RESOURCE.failure(error));
            }
        }
    }
}
