import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Modal, Button } from 'semantic-ui-react';
import { push, go } from 'connected-react-router';
import { RouteComponentProps } from 'react-router-dom';
import { withLocalize, LocalizeContextProps, Translate } from 'react-localize-redux';
import { Dispatch } from 'redux';
import Licenses from '@/containers/Licenses';
import * as actions from '@/store/actions';
import { LicenseExceededPayload } from '@/types';

interface Props extends LocalizeContextProps, RouteComponentProps<any> {
    dispatch: Dispatch;
    exceedingData: LicenseExceededPayload;
}

interface State {
    step: Number;
    useCompanyAdress: Boolean;
}

class LicensesModal extends Component<Props, State>  {
    static defaultProps = {
        exceedingData: {},
    };

    handleClose = () => {
        this.props.dispatch({ type: actions.HIDE_LICENSES_MODAL });
    };

    handleCloseAndLeave = () => {
        this.handleClose();
        if(!this.props.location.pathname.includes('wizard')) {
            this.props.dispatch(go(0));
        }
    };

    render() {
        const { exceedingData, translate } = this.props;
        const trialIsNeeded = !!exceedingData.SuggestedTrialType;

        return (
            <Modal
                open
                onClose={this.handleCloseAndLeave}
                size={trialIsNeeded ? 'small' : 'large'}
                closeIcon
                closeOnDimmerClick={false}
            >
                <Modal.Content>
                    <Header
                        icon="warning"
                        content={exceedingData.Header || translate('upgrade')}
                        subheader={
                            exceedingData.Body
                        }
                    />
                    <Licenses
                        suggestedLicense={exceedingData.SuggestedLicenseToUpgrade}
                        suggestedLicenseId={
                            exceedingData.SuggestedLicenseToUpgrade &&
                            exceedingData.SuggestedLicenseToUpgrade.Id
                        }
                        suggestedTrialType={exceedingData.SuggestedTrialType}
                        currentTrialPeriod={exceedingData.CurrentTrialPeriod}
                        onContinueTrial={this.handleClose}
                        onSubmitSuccess={this.handleClose}
                        autofill={true}
                    />
                </Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        exceedingData: state.licenses.modal.exceedingData,
    };
};

export default connect(mapStateToProps)(withLocalize(LicensesModal));
