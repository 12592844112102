import React, { useEffect } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Message, Icon, Button } from 'semantic-ui-react';

import * as actions from '@/store/actions';
import { RootState } from 'MyTypes';
import { ArticlePrice, EaccountingArticle, ListState, Price, ServicePriceMapping } from '@/types';
import { connect } from 'react-redux';
import {
    useCheckEaccountingAuthQuery,
    useGetEaccountingArticlesQuery,
    useGetEaccountingPriceMappingsQuery,
    useGetEaccountingPricesQuery,
} from '@/services/bmApi';
import PageHeader from '@/components/PageHeader';
import { getCompanyId } from '@/store/reducers/newAuth';
import PriceArticleMappingsTable from './PriceArticleMappingsTable';
import { ApplicationDispatch } from '@/store';
import RTKLoader from '@/components/RTKLoader';
import { UpdatedPrices } from '@/store/reducers/eaccounting';
import { Box } from 'grid-styled';
import { Link, Prompt } from 'react-router-dom';
import { resetUpdatedPrices } from '@/store/actions';

interface Props extends LocalizeContextProps {
    preventRequiredActions?: string[];
    articles: ListState<{ Results: EaccountingArticle[] }>;
    prices: ListState<{ Results: ArticlePrice[] }>;
    priceMappings: ListState<ServicePriceMapping>;
    updatedPrices: UpdatedPrices;
    companyId: string;
    dispatch: ApplicationDispatch;
}

const PAGE_SIZE = 25;

const ArticlesLoader: React.FC<Props> = (props) => {
    const { companyId, updatedPrices, dispatch, translate } = props;

    const articles = useGetEaccountingArticlesQuery();
    const prices = useGetEaccountingPricesQuery({ Active: true });
    const priceMappings = useGetEaccountingPriceMappingsQuery({ CompanyId: companyId });
    const { isSuccess: authenticated } = useCheckEaccountingAuthQuery();

    useEffect(() => {
        return () => {
            if(updatedPrices.changed) {
                dispatch(resetUpdatedPrices());
            }
        }
    }, [updatedPrices.changed])

    return (
        <>
            <PageHeader
                breadcrumbs={[
                    {
                        to: '/eaccounting/service-price-mappings',
                        content: translate('priceArticleMappings.heading'),
                    },
                ]}
            />
            <Message icon info>
                <Icon name="info" />
                <span
                    dangerouslySetInnerHTML={{
                        __html: translate('eaccounting.priceArticleMappings.messageText') as string,
                    }}
                />
            </Message>
            {authenticated ? (
                <RTKLoader queries={[articles, prices, priceMappings]}>
                    <PriceArticleMappingsTable
                        prices={prices.data!}
                        articles={articles.data!}
                        priceMappings={priceMappings.data!}
                        updatedPrices={updatedPrices}
                        onRemoveMapping={(id) => {
                            dispatch(
                                // @ts-ignore
                                actions.removePriceArticleMapping(id)
                            );
                        }}
                        onUpdateMapping={(value) => {
                            dispatch({
                                type: actions.UPDATE_EACCOUNTING_PRICE_EXTERNAL_REF,
                                payload: value,
                            });
                        }}
                        renderSaveButton={() => {
                            return (
                                <div className="clearfix">
                                    <Button
                                        data-cy="submit-price-article-mapping"
                                        onClick={() =>
                                            dispatch(
                                                actions.POST_EACCOUNTING_PRICES.request(
                                                    updatedPrices.entities
                                                )
                                            )
                                        }
                                        disabled={
                                            !updatedPrices.changed ||
                                            priceMappings.isFetching ||
                                            articles.isFetching
                                        }
                                        loading={
                                            (!updatedPrices.changed && priceMappings.isFetching) ||
                                            articles.isFetching
                                        }
                                        floated="right"
                                        primary
                                    >
                                        {translate('form.save')}
                                    </Button>
                                </div>
                            );
                        }}
                    />
                </RTKLoader>
            ) : (
                <Message icon info>
                    <Icon name="info" />
                    <Box as="span" mr="1">
                        {translate('eaccounting.priceArticleMappings.authenticateInThe')}
                    </Box>
                    <Link to="/eaccounting/settings">Eaccounting/settings</Link>
                </Message>
            )}
            <Prompt
                when={updatedPrices.changed}
                // @ts-ignore
                message={translate('form.unsavedChangesMessage')}
            />
            <Prompt
                when={
                    (!updatedPrices.changed && priceMappings.isFetching) ||
                    articles.isFetching}
                // @ts-ignore
                message={translate('form.saveInProgress.alertPrompt')}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        companyId: getCompanyId(state),
        updatedPrices: state.eaccounting.updatePrices,
    };
};

export default connect(mapStateToProps)(withLocalize(ArticlesLoader));
