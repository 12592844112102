import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import { AxiosResponse } from 'axios';

export function* logErrors({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/errors', payload);

        yield put(actions.LOG_ERRORS.success(response.data));
    } catch (error) {
        yield put(actions.LOG_ERRORS.failure(error));
    }
}
