import React, { useEffect } from 'react';
import { Box, Flex } from 'grid-styled';
import { Price } from '@/types';
import Field from '@/components/Field';
import Counter from '@/components/Counter';
import { FormProps } from '@/components/Form';
import { DateQuantityProps, cx } from './DateQuantities';
import { getPricesInfo } from '../components/common';

interface DateQuantityFormProps extends DateQuantityProps, FormProps<{ price: number; quantity: number; }> { 
    onIncrement: (val: number, cb: () => void) => void;
    onDecrement: (val: number, cb: () => void) => void;
}

export const DateQuantityForm: React.FC<DateQuantityFormProps> = ({
    service,
    prices,
    formValues,
    change,
    onIncrement = () => {},
    onDecrement = () => {},
}) => {
    const { hasPrices } = getPricesInfo(service);
    const priceSign = hasPrices  ? prices[0].PriceSign : '';
    const rowPrice =
        (prices.find((p) => p.Id === formValues.price)?.Price || 0) * formValues.quantity;

    return (
        <Box className={cx('row')}>
            <Box className={cx('left', 'small')}>
                {hasPrices ? <Field
                    disabled={prices.length === 1}
                    type="select"
                    name="price"
                    options={prices.map((price: Price) => ({
                        key: price.Id,
                        value: price.Id,
                        text: price.PriceText,
                    }))}
                /> : null}
                <Box mx="auto">
                    <span className="hidden-field">
                        <Field name="quantity" />
                    </span>
                    <Flex
                        justifyContent={'center'}
                        className={cx('counter')} // className={cx(calculatedPrice.loading ? 'disabled' : '')}
                        data-testid={`counter-${formValues.price}`}
                    >
                        <Counter
                            value={formValues.quantity}
                            onDecrement={() => {
                                onDecrement(formValues.quantity - 1, () => {
                                    change('quantity', formValues.quantity - 1);
                                });
                            }}
                            onIncrement={() => {
                                onIncrement(formValues.quantity + 1, () => {
                                    change('quantity', formValues.quantity + 1);
                                });
                            }}
                        />
                    </Flex>
                </Box>
            </Box>
            {hasPrices ? <Box className={cx('right')}>
                {rowPrice}
                {priceSign}
            </Box> : null}
        </Box>
    );
};
