import { useState, useCallback, useMemo } from 'react';

const UnitStringLengths = {
    hours: 2,
    minutes: 2,
    seconds: 2,
};

const KeyCodes = {
    UP: 38,
    DOWN: 40,
};

interface Options {
    value?: string;
    onChange?: (value: string) => void;
    useAMPM?: boolean;
    min?: string;
    max?: string;
}

type Units = 'hours' | 'minutes' | 'seconds';
type AMPMOptions = 'am' | 'pm';

const useInputsState = (unitValues: { [key: string]: string }, useAMPM = false) => {
    const [hours, setHours] = useState(
        useAMPM ? convert24to12(unitValues.hours) : unitValues.hours
    );
    const [minutes, setMinutes] = useState(unitValues.minutes);
    const [seconds, setSeconds] = useState(unitValues.seconds);
    return {
        hours: {
            value: hours,
            setValue: setHours,
        },
        minutes: {
            value: minutes,
            setValue: setMinutes,
        },
        seconds: {
            value: seconds,
            setValue: setSeconds,
        },
    };
};

const formatUnitValue = (unitValue: number | string, unit: Units) =>
    `00${unitValue}`.slice(-UnitStringLengths[unit]);

const convert24to12 = (hours24: string) => {
    if (!hours24) return '';
    let hours12 = parseInt(hours24) % 12;
    hours12 = hours12 ? hours12 : 12;
    return formatUnitValue(hours12, 'hours');
};

const convert12to24 = (hours12: string, ampm: AMPMOptions) => {
    let hours24 = parseInt(hours12);
    if (ampm == 'pm' && hours24 < 12) hours24 = hours24 + 12;
    if (ampm == 'am' && hours24 == 12) hours24 = hours24 - 12;
    return formatUnitValue(hours24, 'hours');
};

interface InputUnit {
    input: {
        value: string;
        onChange: (e: React.ChangeEvent) => void;
        onKeyDown: (e: React.KeyboardEvent) => void;
        onBlur: () => void;
    };
}

interface ReturnedType {
    hours: InputUnit;
    minutes: InputUnit;
    seconds: InputUnit;
    ampm: { select: { value: string; onChange: (e: React.ChangeEvent) => void } };
}

export function useTimePicker({ value, onChange, useAMPM }: Options = {}): ReturnedType {
    const MaxUnitValues = useMemo(
        () => ({
            hours: useAMPM ? 12 : 23,
            minutes: 59,
            seconds: 59,
        }),
        [useAMPM]
    );

    const unitValues = useMemo(() => {
        const [hours, minutes, seconds] = (value || '')
            .split(':')
            .map((value) => (typeof value === 'undefined' ? '' : value));

        return { hours, minutes, seconds };
    }, [value]);

    const ampmValue = useMemo(() => (parseInt(unitValues.hours) >= 12 ? 'pm' : 'am'), [
        unitValues.hours,
    ]);

    const inputStates = useInputsState(unitValues, useAMPM);

    function handleChange(value: string, unit: Units) {
        if (value === '' || isValid(value, unit)) {
            inputStates[unit].setValue(value);
        }
    }

    function handleKeyDown(e: React.KeyboardEvent, unit: Units) {
        if (e.which === KeyCodes.DOWN) {
            decrement(unit);
        }
        if (e.which === KeyCodes.UP) {
            increment(unit);
        }
    }

    function decrement(unit: Units) {
        const number = parseInt(inputStates[unit].value);

        let nextUnitValue;

        if (isNaN(number) || number === 0) {
            nextUnitValue = MaxUnitValues[unit];
        } else {
            nextUnitValue = number - 1;
        }

        if (isValid(String(nextUnitValue), unit)) {
            if (unit === 'hours' && useAMPM) {
                nextUnitValue = convert12to24(String(nextUnitValue), ampmValue);
            }
            setUnitValue(String(nextUnitValue), unit);
        }
    }

    function increment(unit: Units) {
        const number = parseInt(inputStates[unit].value);

        let nextUnitValue;

        if (unit === 'hours' && useAMPM && number === 11) {
            nextUnitValue = 0;
        } else if (unit === 'hours' && useAMPM && number === 12) {
            nextUnitValue = 1;
        } else if (isNaN(number) || number === MaxUnitValues[unit]) {
            nextUnitValue = 0;
        } else {
            nextUnitValue = number + 1;
        }

        if (isValid(String(nextUnitValue), unit)) {
            if (unit === 'hours' && useAMPM) {
                nextUnitValue = convert12to24(String(nextUnitValue), ampmValue);
            }
            setUnitValue(String(nextUnitValue), unit);
        }
    }

    function handleBlur(e: React.FocusEvent<HTMLInputElement>, unit: Units) {
        if (e.target.value === '') return;

        const newValue =
            unit === 'hours' && useAMPM ? convert12to24(e.target.value, ampmValue) : e.target.value;
        
        if (isValid(newValue, unit)) {
            setUnitValue(newValue, unit);
        } else if (unit === 'hours' && useAMPM === true) {
            setUnitValue(newValue, unit);
        } else {
            setUnitValue(unitValues[unit], unit);
        }
    }

    function setUnitValue(unitValue: string = '', unit: Units) {
        let formattedValue = formatUnitValue(unitValue, unit);
        inputStates[unit].setValue(
            unit === 'hours' && useAMPM ? convert24to12(formattedValue) : formattedValue
        );

        if (onChange) {
            let nextUnitValues = { ...unitValues };

            nextUnitValues[unit] = formattedValue;

            let nextValue = `${nextUnitValues.hours}:${nextUnitValues.minutes}`;

            if (!isNaN(parseInt(nextUnitValues.seconds))) {
                nextValue += `:${nextUnitValues.seconds}`;
            }

            if (value !== nextValue) {
                onChange(nextValue);
            }
        }
    }

    const handleAMPMChange = useCallback(
        (e) => {
            const value = e.target.value;
            let hours = parseInt(unitValues.hours);

            if (hours >= 12 && value === 'am') {
                hours -= 12;
            } else if (hours < 12 && value === 'pm') {
                hours += 12;
            }

            setUnitValue(`00${hours}`.slice(-2), 'hours');
        },
        [unitValues.hours]
    );

    const isValid = useCallback(
        (unitValue: string, unit: Units) => {
            const isNum = /^\d+$/.test(unitValue);
            const number = parseInt(unitValue);
            if (!isNaN(number) && number < 0) return false;

            if (isNum) {
                if (useAMPM && unit === 'hours') {
                    return number <= 12;
                } else {
                    return number <= MaxUnitValues[unit];
                }
            }

            return false;
        },
        [useAMPM]
    );

    return (Object.keys(inputStates).reduce(
        (acc, unit) => {
            const inputValue = inputStates[unit as Units].value;

            return {
                ...acc,
                [unit as Units]: {
                    input: {
                        value: inputValue,
                        // @ts-ignore
                        onChange: (e) => handleChange(e.target.value, unit as Units),
                        // @ts-ignore
                        onKeyDown: (e) => handleKeyDown(e, unit as Units),
                        // @ts-ignore
                        onBlur: (e) => handleBlur(e, unit as Units),
                        type: 'text',
                    },
                    increment,
                    decrement,
                },
            };
        },
        {
            ampm: {
                select: {
                    value: ampmValue,
                    onChange: handleAMPMChange,
                },
            },
        }
    ) as unknown) as ReturnedType;
}

export default useTimePicker;
