import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';

import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import { AxiosResponse } from 'axios';

export function* fetchAccessKeytypes(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/accesskeytypes', {});

        yield put(actions.FETCH_ACCESSKEYTYPES.success({ Results: response.data }));
    } catch (error) {
        yield put(actions.FETCH_ACCESSKEYTYPES.failure(error));
    }
}
