import Form from '@/components/Form';
import qs from 'qs';
import React from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import * as actions from '@/store/actions';

import { RouteComponentProps } from 'react-router';
import { Menu, Tab, TabProps } from 'semantic-ui-react';
import ResourceGroup from './ResourceGroup';
import Resources from './Resources';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '@/store';
import { formValues, getFormValues, isSubmitting } from 'redux-form';

interface Props extends RouteComponentProps, LocalizeContextProps, ConnectedProps<typeof connector> {}

const CreateResourcesWizard: React.FC<Props> = (props) => {
    const { translate, location, history, stepFullfilled, submitting } = props;
    const { hash, search, pathname } = location;

    const handleTabChange = (e: React.MouseEvent, { activeIndex, panes }: TabProps) => {
        const { stepFullfilled} = props;

        if(stepFullfilled) {
            history.push({
                pathname: pathname,
                search: qs.stringify(qs.parse(search)),
                ...(panes && typeof activeIndex === 'number' && panes[activeIndex] ? {hash: `${panes[activeIndex].menuItem.key}`} : {})
            });
        }
    };

    const panes = [
        {
            menuItem: {
                content: <div>{translate('CreateResourcesWizard.tab.resourceGroup')}</div>,
                key: 'resource-group',
            },
            render: (props: any) => (
                <Tab.Pane>
                    <ResourceGroup {...props} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                content: <Menu.Item disabled={!stepFullfilled}>{translate('CreateResourcesWizard.tab.resources')}</Menu.Item>,
                key: 'resources',
            },
            render: (props: any) => (
                <Tab.Pane>
                    <Resources {...props} />
                </Tab.Pane>
            ),
        },
    ];

    let activeHashIndex = panes.findIndex(
        (pane) => pane.menuItem.key === hash.slice(1)
    );
    activeHashIndex = activeHashIndex !== -1 ? activeHashIndex : 0;

    return (
        <Form
            name="resourceWizard"
            action={actions.CREATE_RESOURCE_TYPE_WIZARD}
            showActionBar={false}
            showErrors={false}
            showSuccessMesage={false}
            confirmOnLeave={false}
        >
            {(formProps: any) => (
                <Tab
                    onTabChange={handleTabChange}
                    panes={panes}
                    activeIndex={stepFullfilled ? activeHashIndex : 0}
                    {...formProps}
                    stepFullfilled={stepFullfilled}
                    submitting={submitting}
                />
            )}
        </Form>
    );
};


const connector = connect((state: ApplicationState) => {
    const formValues: any = getFormValues('resourceWizard')(state);
    const submitting = isSubmitting('resourceWizard')(state);

    const stepFullfilled =
        formValues &&
        formValues['Name']

    return {
        stepFullfilled,
        submitting,
        formValues
    }
});

export default compose(
    connector,
    withLocalize,
)(CreateResourcesWizard) as React.FC<Props>;

