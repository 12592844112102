import Keycloak from 'keycloak-js';
import * as actions from '@/store/actions';
import { store } from '@/store';
import { INITIAL_ROUTE_KEY, SHOULD_LOGOUT_STORAGE_KEY } from './utils/constants';



export const REFRESH_TOKEN_INTERVAL = 55; // in seconds

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

const keycloak = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string
});

keycloak.init({
    onLoad: 'login-required',
    checkLoginIframe: false,
    redirectUri: `${window.location.origin}/#/auth`,
}).then((isAuthenticated) => {
    if (isAuthenticated) {
        store.dispatch(actions.authSuccess());
    } else {
        store.dispatch(actions.authLogout())
    }
})
.catch(err => {
    console.error(err);
});

keycloak.onAuthSuccess = () => {
    if(sessionStorage.getItem(SHOULD_LOGOUT_STORAGE_KEY)) {
        sessionStorage.removeItem(SHOULD_LOGOUT_STORAGE_KEY);
        store.dispatch(actions.KEYCLOAK_API_LOGOUT.request());
        return;
    } 
};

keycloak.onAuthRefreshSuccess = () => {
    store.dispatch(actions.authSuccess());
};

keycloak.onAuthError = (err) => {
    store.dispatch(actions.authLogout());
};

keycloak.onAuthRefreshError = () => {
    const err = new Error('Token refresh error;');
    store.dispatch(actions.authFail(err));
};

keycloak.onTokenExpired = () => {
    keycloak.updateToken(REFRESH_TOKEN_INTERVAL);
};

export default keycloak
