import React, { useState, ReactNode } from 'react';
import styled from 'styled-components';
import Truncate from 'react-truncate';
import useTranslate from '../hooks/useTranslate';

const StyledA = styled.a`
    cursor: pointer;
`;

interface Props {
    children: ReactNode;
    lines?: number;
}

const ShowMoreText: React.FC<Props> = (props) => {
    const { children, lines = 3 } = props;

    const [expanded, setExpanded] = useState(false);
    const [truncated, setTruncated] = useState(false);

    const { translate } = useTranslate();

    function handleTruncate(nextTruncated: boolean) {
        if (truncated !== nextTruncated) {
            setTruncated(nextTruncated);
        }
    }

    function toggleLines(event: React.MouseEvent) {
        event.preventDefault();
        setExpanded(!expanded);
    }

    return (
        <div>
            <Truncate
                lines={!expanded && lines}
                ellipsis={
                    <span>
                        ...{' '}
                        <StyledA href="#" onClick={toggleLines}>
                            {translate('common.showMore')}
                        </StyledA>
                    </span>
                }
                onTruncate={handleTruncate}
            >
                {children}
            </Truncate>
            {!truncated && expanded && (
                <span>
                    {' '}
                    <StyledA href="#" onClick={toggleLines}>
                        {translate('common.showLess')}
                    </StyledA>
                </span>
            )}
        </div>
    );
};

export default ShowMoreText;
