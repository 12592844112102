interface NavFunctionArguments {
    goToHomepage: (e: React.MouseEvent) => void;
    goToConfigurator: (e: React.MouseEvent) => void;
    eaccountingEnabled?: boolean;
}

interface NavConfigurationItem {
    name: string;
    url?: string;
    icon: string;
    label?: string;
    children?: Array<{
        name: string;
        url: string;
        icon?: string;
        isExternal?: boolean;
        onClick?: (e: React.MouseEvent) => void;
    }>;
}

type NavConfiguration = Array<NavConfigurationItem>;

export default ({
    goToHomepage,
    goToConfigurator,
    eaccountingEnabled = false
}: NavFunctionArguments): NavConfiguration => {
    let nav = [
        {
            name: 'routes.dashboard',
            url: '/dashboard',
            icon: '',
        },
        {
            name: 'routes.calendar',
            url: '/scheduler',
            icon: '',
        },
        {
            name: 'routes.myServices',
            url: '/services',
            icon: '',
            children: [
                {
                    name: 'routes.services',
                    url: '/services',
                },
                {
                    name: 'routes.articles',
                    url: '/articles',
                    icon: ''
                },
                {
                    name: 'routes.promoCodes',
                    url: '/promo-codes-value-cards',
                },
                {
                    name: 'routes.customFields',
                    url: '/custom-fields',
                },
            ],
        },
        {
            name: 'routes.resources',
            url: '/resources',
            icon: ''
        },
        {
            name: 'routes.mySchedules',
            url: '/schedules',
            icon: '',
            children: [
                {
                    name: 'routes.schedules',
                    url: '/schedules',
                },
                {
                    name: 'routes.timeExceptions',
                    url: '/time-exceptions',
                },
                {
                    name: 'routes.timeExceptionsReport',
                    url: '/time-exceptions-report',
                },
            ]
        },
        {
            name: 'routes.customers',
            url: '/customers',
            icon: '',
            children: [
                {
                    name: 'routes.allCustomers',
                    url: '/customers',
                },
                {
                    name: 'routes.reviews',
                    url: '/reviews',
                },
                {
                    name: 'routes.newsletter',
                    url: '/newsletters',
                },
                {
                    name: 'routes.mailchimpSettings',
                    url: '/mailchimp-settings',
                },
            ],
        },
        {
            name: 'routes.bookings',
            url: '/bookings',
            icon: '',
        },
        {
            name: 'routes.website',
            icon: '',
            url: '/homepage'
        },
        {
            name: 'routes.settings',
            url: '',
            icon: '',
            children: [
                {
                    name: 'routes.company',
                    url: '/company',
                },
                {
                    name: 'routes.bookingSettings',
                    url: '/booking-settings',
                },
                {
                    name: 'routes.bookingPrintout',
                    url: '/booking-printout',
                },
                {
                    name: 'routes.systemSettings',
                    url: '/system-settings',
                },
                {
                    name: 'routes.paymentSettings',
                    url: '/payment-settings',
                },
                {
                    name: 'routes.codelockSettings',
                    url: '/codelock-settings',
                },
                {
                    name: 'routes.syncWithExternalCalendar',
                    url: '/sync',
                },
            ],
        },
        {
            name: 'routes.support',
            icon: '',
            url: '/support'
        }
    ];

    if(eaccountingEnabled) {
        // @ts-ignore
        nav.splice(8, 0,  {
            name: 'routes.eaccounting',
            icon: '',
            children: [
                {
                    name: 'routes.eaccounting.settings',
                    url: '/eaccounting/settings',
                },
                {
                    name: 'routes.eaccounting.priceArticleMappings',
                    url: '/eaccounting/articles',
                }
            ],
        });
    }

    return nav;


};
