import { put, select, call } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { getTranslate } from 'react-localize-redux';
import qs from 'qs';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { AnyAction } from 'redux';
import keycloak from '@/keycloak';
import Notifications from 'react-notification-system-redux';
import { take } from 'lodash';
import { RootState } from 'MyTypes';
import { AxiosResponse } from 'axios';
import { INITIAL_ROUTE_KEY } from '@/utils/constants';
import bmApi from '@/services/bmApi';
import { appBroadcast } from '@/broadcast';

// A hardcoded company id value for customer users which are not administrators
const PLACEHOLDER_COMPANY_ID = '00000000-0000-0000-0000-000000000000';

type Realm = 'bookmore' | 'bookmore-admin'

export const realmParamsMapping: {[key in Realm]: number} =  {
    bookmore: 1,
    'bookmore-admin': 2
};

export function* login(action: AnyAction) {
    const authenticate = actions.AUTHENTICATE;
    const authState: {[key in string]: any} = yield select((state) => state.authenticate);
    if (!authState.isLoading && keycloak.sessionId) {
    } else if (keycloak.authenticated) {
        try {
            const _authData: {[key in string]: any} = yield keycloak.loadUserInfo().then((ui: any) => {
                return {
                    UserId: ui?.sub,
                    UserName: ui?.preferred_username,
                    DisplayName: ui?.name,
                    BearerToken: keycloak.token,
                    RefreshToken: keycloak.refreshToken,
                    ProfileUrl: keycloak.createAccountUrl(),
                    SessionId: keycloak.sessionId
                };
            });

            yield localStorage.setItem('auth', JSON.stringify(_authData));

            // @ts-ignore
            const roles = keycloak.tokenParsed?.Role ? keycloak.tokenParsed?.Role : [];

            // @ts-ignore
            const promise = yield put(bmApi.endpoints.getUsers.initiate());
            yield promise;

            // @ts-ignore
            const userResponse = yield select(bmApi.endpoints.getUsers.select());

            const response = {
                data: {
                    ..._authData,
                    Roles: roles,
                    Meta: {
                        ...userResponse.data.AdminProfile,
                        CompanyUsers: userResponse.data.CompanyCustomers,
                    },
                },
            };
            const userIsNotAdmin =
                !userResponse.data?.AdminProfile ||
                (userResponse.data?.AdminProfile &&
                    userResponse.data.AdminProfile?.CompanyId === PLACEHOLDER_COMPANY_ID);

            if (userIsNotAdmin) {
                const localizeState: RootState['localize'] = yield select((state) => state.localize);
                const translate = getTranslate(localizeState);
                // @ts-ignore
                throw new Error(translate('common.userIsNotAdmin'));
            } else {
                const location: RootState['router']['location'] = yield select((state) => state.router?.location);
                const query = qs.parse(location.search.slice(1));
                yield put(authenticate.success(response));
                yield put(push(query.redirectTo as string));
            }
        } catch (error) {
            // TODO: prevent error in catch
            console.log(error);
            yield put(authenticate.failure(throwSubmissionError(error)));
        }
    }
}

export function* logout(action?: AnyAction) {
    const logoutAction = actions.LOGOUT;

    try {
        yield put({ type: 'RESET_STORE' });
        yield put(push('/login?redirectTo=%2F'));
        yield put(logoutAction.success());
        yield keycloak.logout();
    } catch (error) {
        yield put(logoutAction.failure(throwSubmissionError(error)));
    }
}

const sleep = (timeout = 200) => {
    return new Promise((resolve ) => setTimeout(() => {resolve(true)}, timeout))
}

export function* checkThatIsAuthenticated(action: AnyAction) {
    const checkAuth = actions.CHECK_AUTH;
    try {
        yield take(actions.AUTHENTICATE_UPDATE_TOKENS.SUCCESS);
        yield call(sleep, 500);
        yield put(checkAuth.success());
    } catch (error) {
        yield put(checkAuth.failure(throwSubmissionError(error)));
    }
}

export function* keycloakApiLogout(action: AnyAction) {
    const auth: {[key in string]: any} = yield select((state) => state.authenticate);
    
    try {
        const initialRoute = sessionStorage.getItem(INITIAL_ROUTE_KEY);
        yield apiClient.post('/users/logout', {
            ClientId: keycloak.clientId,
            Realm: keycloak.realm,
            RefreshToken: keycloak.refreshToken
        });
        
        yield put(actions.KEYCLOAK_API_LOGOUT.success());
        appBroadcast.postMessage('logout');
        yield keycloak.logout({ redirectUri: `${window.location.origin}/#/auth?redirectTo=${initialRoute}` });
    } catch (error) {
        yield put(actions.KEYCLOAK_API_LOGOUT.failure());
    }
}

export function* waitForAuthenticateReplace(action: AnyAction) {
    const checkAuth = actions.WAIT_FOR_AUTHENTICATE_REPLACE;
    try {
        yield take(actions.AUTHENTICATE.SUCCESS);
        yield put(replace(action.payload));
    } catch (error) {
        yield put(checkAuth.failure(throwSubmissionError(error)));
    }
}

export function* resetPassword({ payload }: AnyAction) {
    const realm = realmParamsMapping[process.env.REACT_APP_KEYCLOAK_REALM as Realm];
    const email = payload.Email;
    try {
        const localizeState: RootState['localize']= yield select((state) => state.localize);
        const translate = getTranslate(localizeState);
        const successMessage = translate('changePassword.linkSent', { email: email }, { renderInnerHtml: true });
        yield apiClient.post(`/users/forgotpassword`, { Email: email, Realm: realm });
        
        yield put(actions.RESET_PASSWORD.success(successMessage));
    } catch (error) {
        yield put(actions.RESET_PASSWORD.failure(throwSubmissionError(error)));
    }
}

export function* changePassword({ payload }: AnyAction) {
    const realm = realmParamsMapping[process.env.REACT_APP_KEYCLOAK_REALM as Realm];
    try {
        const email: string = yield select((state) => state.authenticate.data.Meta.Email);
        const localizeState: RootState['localize'] = yield select((state) => state.localize);
        const translate = getTranslate(localizeState);
        const successMessage = translate('changePassword.linkSent', { email }, { renderInnerHtml: true });
        yield apiClient.post(`/users/forgotpassword`, { Email: email, ...payload, Realm: realm });
        yield put(actions.CHANGE_PASSWORD.success(successMessage));
    } catch (error) {
        yield put(actions.CHANGE_PASSWORD.failure(throwSubmissionError(error)));
    }
}

export function* fetchUpdateTokens(action: AnyAction) {
    if (keycloak.authenticated && keycloak.isTokenExpired()) {
        try {
            const authData: {[key in string]: any} = yield select((state) => state.authenticate?.data);
            const response: AxiosResponse = yield call(getAccessToken, authData.RefreshToken);
        
            // @ts-ignore
            yield put(actions.AUTHENTICATE_UPDATE_TOKENS.success({ BearerToken: response.access_token }))
        } catch (error) {
            yield put(actions.AUTHENTICATE_UPDATE_TOKENS.failure(error));
        }
    } else {
        yield put(actions.AUTHENTICATE_UPDATE_TOKENS.failure());
    }

}

export function* authenticateReLogin(action: AnyAction) {
    yield put(
        Notifications.show({
            level: 'info',
            title: 'Sesion expired, please login again.',
        })
    );

    try {
        yield put(actions.AUTHENTICATE_RE_LOGIN.success());
    } catch (error) {
        yield put(actions.AUTHENTICATE_RE_LOGIN.failure(error));
    }
}

interface KeycloakResponse {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    token_type: string;
    id_token: string;
    session_state: string;
    scope: string;
  }

const getAccessToken = (
    refreshToken: string
  ): any => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
    var urlencoded = new URLSearchParams();
    urlencoded.append("client_id", process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string);
    urlencoded.append("grant_type", "refresh_token");
    urlencoded.append("refresh_token", refreshToken);
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
  
    return fetch(
      `${process.env.REACT_APP_KEYCLOAK_AUTH_URL}/realms/bookmore-admin/protocol/openid-connect/token`,
      requestOptions as any
    ).then((response: any) => response.json());
  };

export function* postAuthCode({ payload }: AnyAction) {
    try {
        const { companyId, code } = payload;

        const response: AxiosResponse<any> = yield apiClient.get('/eaccounting/callback', {
            params: {
                CompanyId: companyId,
                Code: code
            }
        });

        yield put(actions.EACCOUNTING_POST_AUTH_CODE.success(payload));
        yield put(push({ pathname: '/eaccounting' }));
        sessionStorage.removeItem('eaccountingCode');
    } catch (error: any) {
        yield put(
            Notifications.show(
                {
                    message: error.message as string,
                },
                'error'
            )
        );
        yield put(actions.EACCOUNTING_POST_AUTH_CODE.failure(error));
        yield put(push({ pathname: '/eaccounting' }));
    }
}