import { createSelector } from 'reselect';
import { Reducer, AnyAction } from 'redux';
import reduceReducers from 'reduce-reducers';
import { createListLoadingReducer, listInitialState } from './utils';
import * as actions from '../actions';
import { ResourceType, ListState } from '@/types';
import { definitions, operations } from '@/apitypes';

export const selectResourceTypes = (state: any) => state.resourceTypes;

export const selectResourceTypeById = (id: string) =>
    createSelector(selectResourceTypes, (resourceTypes) =>
        resourceTypes.data.find((resourceType: any) => resourceType.Id.toString() === id)
    );

interface UIState {
    isResourceCreating: boolean;
    created: definitions['Resource'][]
}
interface ResourceTypesState extends ListState<ResourceType>, UIState {}

export const dataReducer = (createListLoadingReducer('RESOURCE_TYPES') as unknown) as Reducer<
    ResourceTypesState,
    AnyAction
>;

const uiInitialState: UIState = {
    isResourceCreating: false,
    created: []
};

const initialState = {
    ...listInitialState,
    ...uiInitialState,
};

export const uiReducer: Reducer<ResourceTypesState, AnyAction> = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case actions.CREATE_RESOURCE.REQUEST:
            return { ...state, isResourceCreating: true };
        case actions.CREATE_RESOURCE.SUCCESS:
            return { ...state, isResourceCreating: false, created: [...state.created, payload] };
        case actions.CREATE_RESOURCE.FAILURE:
            return { ...state, isResourceCreating: false };
        case actions.DELETE_RESOURCE.SUCCESS:
            return { ...state, isResourceCreating: false, created: state.created.filter(r => r.Id !== payload?.data.Id)}
        case actions.CREATE_RESOURCE_TYPE_WIZARD.SUCCESS:
            return { ...state, isResourceCreating: false, created: []}
        default:
            return state;
    }
};

export default (reduceReducers(initialState, dataReducer, uiReducer) as unknown) as Reducer<
    ResourceTypesState,
    AnyAction
>;
