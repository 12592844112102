import { combineReducers, Reducer, AnyAction } from 'redux';
import { createSelector } from 'reselect';
import { createListLoadingReducer } from './utils';
import groupBy from 'lodash/groupBy';
import * as actions from '../actions';
import { LogMessage } from '@/types';

export const getMessageFieldTranslations = (state: any) => state.messages.fieldTranslations;

export const getGroupedMessageFieldTranslations = createSelector(
    getMessageFieldTranslations,
    (translations) => groupBy(translations.data, 'Group')
);

const test: Reducer<any, AnyAction> = (
    state = { isLoading: false, error: null },
    { type, payload }
) => {
    switch (type) {
        case actions.TEST_MESSAGE_TEMPLATE.REQUEST:
            return { ...state, isLoading: true, error: null };
        case actions.TEST_MESSAGE_TEMPLATE.SUCCESS:
            return { ...state, isLoading: false, error: null };
        case actions.TEST_MESSAGE_TEMPLATE.FAILURE:
            return { ...state, isLoading: false, error: payload };
        default:
            return state;
    }
};

export default combineReducers({
    log: createListLoadingReducer<LogMessage>('MESSAGE_LOG'),
    dashboard: createListLoadingReducer('MESSAGE_DASHBOARD'),
    templates: createListLoadingReducer('MESSAGE_TEMPLATES'),
    templateTypes: createListLoadingReducer('MESSAGE_TEMPLATE_TYPES'),
    fieldTranslations: createListLoadingReducer('MESSAGE_FIELD_TRANSLATIONS'),
    test,
});
