import { combineReducers, Reducer, AnyAction } from 'redux';
import * as actions from '../actions';
import { createLoadingReducer, createListLoadingReducer } from './utils';
import { createSelector } from 'reselect';

const companyLicenses = createListLoadingReducer('COMPANY_LICENSES', (payload) => payload);
const creditCard = createLoadingReducer('CREDIT_CARD');
const information = createListLoadingReducer('LICENSE_INFO');
const licenseInformationTypes = createListLoadingReducer('LICENSE_INFORMATION_TYPES');
const trials = createListLoadingReducer('TRIALS');
const companyTrials = createListLoadingReducer('COMPANY_TRIALS');
const trialCheck = createLoadingReducer('CHECK_TRIAL');
const domain = createLoadingReducer('CHECK_DOMAIN');

const licenseTypes = createListLoadingReducer('LICENSE_TYPES', (payload) =>
    payload.Results.map((licenseType: any) => {
        const { Id } = licenseType;
        let key;
        // debugger;
        if (Id === 1) {
            key = 'FreeEdition';
        }  else if (Id === 2) {
            key = 'StartEdition';
        }  else if (Id === 3) {
            key = 'SmartEdition';
        } else if (Id === 5) {
            key = 'ProEdition'
        } else if (Id === 9) {
            key = 'EnterpriseEdition'
        }
        return { ...licenseType, key };
    })
);

const modalInitialState = {
    // For testing purposes
    // Show modal if initial pathname is 'licenses';
    show: window.location.href.split('/').slice(-1)[0] === 'licenses',
    exceedingData: {},
    status: null,
};

const modal: Reducer<any, AnyAction> = (state = modalInitialState, action) => {
    switch (action.type) {
        case actions.SHOW_LICENSES_MODAL:
            return {
                ...state,
                show: true,
                exceedingData: action.payload,
                status: action.meta.status,
            };
        case actions.HIDE_LICENSES_MODAL:
            return {
                ...state,
                show: false,
                exceedingData: modalInitialState.exceedingData,
                status: null,
            };
        default:
            return state;
    }
};

const addTrial: Reducer<any, AnyAction> = (state = { isLoading: false }, action) => {
    switch (action.type) {
        case actions.ADD_TRIAL.REQUEST:
            return { ...state, isLoading: true };
        case actions.ADD_TRIAL.SUCCESS:
        case actions.ADD_TRIAL.FAILURE:
            return { ...state, isLoading: false };

        default:
            return state;
    }
};

export default combineReducers({
    addTrial,
    modal,
    companyLicenses,
    creditCard,
    information,
    domain,
    types: licenseTypes,
    licenseInformationTypes,
    trials,
    companyTrials,
    trialCheck,
});

export const selectCompanyLicenses = (state: any) => state.licenses.companyLicenses;

export const selectNormalizedCompanyLicenses = createSelector(
    selectCompanyLicenses,
    (licenses) => ({
        ...licenses,
        data: licenses.data.reduce((acc: any[], val: any) => ({ ...acc, [val.TypeId]: val }), {}),
    })
);
