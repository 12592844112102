export default {
    grayDark: '#29363d',
    white: '#fff',
    gray: '#536c79',
    primary: '#20a8d8',
    secondary: '#a4b7c1',
    success: '#4dbd74',
    info: '#63c2de',
    warning: '#ffc107',
    danger: '#f86c6b',
    light: '#f0f3f5',
    dark: '#29363d',
    blue: '#20a8d8',
    indigo: '#6610f2',
    purple: '#6f42c1',
    pink: '#e83e8c',
    red: '#f86c6b',
    orange: '#f8cb00',
    yellow: '#ffc107',
    green: '#4dbd74',
    teal: '#20c997',
    cyan: '#63c2de',
    gray100: '#f0f3f5',
    gray200: '#c2cfd6',
    gray300: '#a4b7c1',
    gray400: '#869fac',
    gray500: 'rgba(0,0,0,.5)',
    gray600: '#536c79',
    gray700: '#3e515b',
    gray800: '#29363d',
    gray900: '#151b1e',
    breakpointxs: ' 0',
    breakpointsm: ' 576px',
    breakpointmd: ' 768px',
    breakpointlg: ' 992px',
    breakpointxl: ' 1200px',
};
