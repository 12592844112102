import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Icon, Button, Segment, Message } from 'semantic-ui-react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getCompanyId } from '@/store/reducers/newAuth';
import { apiClient } from '@/utils';
import bmApi, { useCheckEaccountingAuthQuery, useEaccountingLogoutMutation } from '@/services/bmApi';
import Loading from '@/components/Loading';
import { ApplicationDispatch, ApplicationState } from '@/store';
import { Box } from 'grid-styled';
import * as actions from '@/store/actions';
import Error from '@/components/Error';
import { useAppSelector } from '@/hooks';


interface Props extends LocalizeContextProps, RouteComponentProps {
    dispatch: ApplicationDispatch;
    companyId: string;
}

const getAuthLink = async (companyId: string, redirectUri = `${process.env.REACT_APP_API_BASE_URL}/eaccounting/callback`,) => {
    const response = await apiClient.get('/encryption/encrypt', { params: {
        CompanyId: companyId,
        Text: companyId
    }});

    const encryptedState = encodeURIComponent(response.data.Results[0].EncryptedText);

    const encodedRedirectUri = encodeURIComponent(redirectUri);
    return `${process.env.REACT_APP_EACCOUNTING_AUTH_URL}/connect/authorize?client_id=${process.env.REACT_APP_EACCOUNTING_CLIENT_ID}${companyId ? '&state=' + encryptedState : ''}&scope=ea:api%20offline_access%20ea:sales&response_type=code&redirect_uri=${encodedRedirectUri}`;
}


const Authenticate: React.FC<Props> = ({ companyId, translate, dispatch }) => {
    const auth = useAppSelector(s => s.eaccounting.auth);
    const { isSuccess, isLoading, isError, refetch, isFetching } = useCheckEaccountingAuthQuery();
    const [logout, logoutState] = useEaccountingLogoutMutation();
    const [authStarted, setAuthStarted] = useState(false);
    const [authWindow, setAuthWindow] = useState<Window | null>(null);

    useEffect(() => {
        if(logoutState.isSuccess) {
            dispatch(bmApi.util.resetApiState());
        }
    }, [logoutState.isSuccess])

    useEffect(() => {
        let intervalId: number;
        if(authStarted) {
            intervalId = setInterval(() => {
                if(!isLoading && !isSuccess) {
                    refetch();
                }
            }, 2000);
        }

        if(isSuccess && !isError && authWindow && !isLoading && !isFetching) {
            authWindow.close();
            dispatch(
                bmApi.util.invalidateTags(['ServicePriceMappings', 'Invoices', 'Settings'])
            );
        }
        
        return () => {
            clearInterval(intervalId);
        }
    }, [isSuccess, isError, isLoading, authStarted, isFetching]);

    return (
        <Segment>
            {isSuccess ? (
                <Box mb="3">
                    <Box mb="2">
                        <Icon disabled name="checkmark" color="green" />
                        <span>{translate('eaccounting.youAreAuthenticated')}</span>
                    </Box>
                    <Button
                        loading={logoutState.isLoading}
                        disabled={logoutState.isLoading}
                        onClick={(evt) => {
                            evt.preventDefault();
                            logout();
                        }}
                        data-cy="eaccounting-logout"
                    >
                        {translate('common.logout')}
                    </Button>
                </Box>
            ) : null}

            {!isSuccess ? (
                <Box mb="2">
                    <Button
                        data-cy="eaccounting-authenticate"
                        disabled={authStarted}
                        loading={authStarted}
                        primary
                        onClick={async () => {
                            setAuthStarted(true);
                            const authLink = await getAuthLink(companyId);

                            let _window = window.open(authLink, '_blank');
                            setAuthWindow(_window);
                        }}
                    >
                        {translate('eaccounting.authenticate')}
                    </Button>
                </Box>
            ) : null}

            {logoutState.error ? (
                <Box mb="2">
                    {/* @ts-ignore */}
                    <Error error={{ errors: logoutState.error.data.ResponseStatus.Errors }} text={logoutState.error.data.ResponseStatus.Message} />
                </Box>
            ) : null}

            <Box>
                <Message icon info>
                    <Icon name="info" />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: translate('eaccounting.main.messageText') as string,
                        }}
                    />
                </Message>
            </Box>
        </Segment>
    );
};

const mapStateToProps = (state: ApplicationState) => {

    return {
        companyId: getCompanyId(state)
    }
};

export default connect(mapStateToProps)(withLocalize(withRouter(Authenticate)));
