import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import { AxiosResponse } from 'axios';

export function* fetchCalculationTypes({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/services/prices/calculationtypes', {
            ...payload
        });

        yield put(actions.FETCH_CALCULATION_TYPES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_CALCULATION_TYPES.failure(error));
    }
}

