import { createSelector } from 'reselect';
import reduceReducers from 'reduce-reducers';
import { createListLoadingReducer } from './utils';
import * as actions from '../actions';

export const getPromoCodes = (state: any) => state.promoCodes;

export const getPromoCodeById = (id: string) =>
    createSelector(getPromoCodes, (promoCodes) =>
        promoCodes.isLoaded ? promoCodes.data.find((item: any) => item.Id.toString() === id) : null
    );

// export default createListLoadingReducer('PROMO_CODES');

export default reduceReducers<any>(
    {},
    createListLoadingReducer('PROMO_CODES'),
    (state, action) => {
        switch (action.type) {
            case actions.PRINT_PROMOCODE_RECEIPT.REQUEST: {
                return {
                    ...state,
                    exporting: true
                }
            }
            case actions.PRINT_PROMOCODE_RECEIPT.SUCCESS: {
                return {
                    ...state,
                    exporting: false
                }
            }
            case actions.PRINT_PROMOCODE_RECEIPT.FAILURE: {
                return {
                    ...state,
                    exporting: false
                }
            }
            // 
            case actions.SEND_PROMOCODE_RECEIPT.REQUEST: {
                return {
                    ...state,
                    sending: true
                }
            }
            case actions.SEND_PROMOCODE_RECEIPT.SUCCESS: {
                return {
                    ...state,
                    sending: false
                }
            }
            case actions.SEND_PROMOCODE_RECEIPT.FAILURE: {
                return {
                    ...state,
                    sending: false
                }
            }
            case actions.EXPORT_PROMO_CODES.REQUEST: {
                return {
                    ...state,
                    exporting: true
                }
            }
            case actions.EXPORT_PROMO_CODES.SUCCESS: {
                return {
                    ...state,
                    exporting: false
                }
            }
            case actions.EXPORT_PROMO_CODES.FAILURE: {
                return {
                    ...state,
                    exporting: false
                }
            }
            default:
                return state;
        }
    }
);
