import React from 'react';
import { Message, Icon, Button, Segment } from 'semantic-ui-react';
import useTranslate from '../hooks/useTranslate';
import CorrelationIds  from './CorrelationIds';

interface Props {
    showReload?: boolean;
    error: {
        message: string;
        errors?: Array<string>;
    };
    attached?: boolean;
    text?: string;
};

const defaultProps = {
    showReload: false,
};

const ErrorMessage: React.FC<Props> = (props) => {
    const { error, text, showReload, attached } = props;
    const { translate } = useTranslate();

    return (
        <Message icon negative attached={attached}>
            <Icon name="ban" />
            <Message.Content>
                <Message.Header>{error ? error.message : false}</Message.Header>
                {text}
                {!error && translate('form.errorLoadingData')}
                {error && error.errors && <Message.List items={error.errors} />}
                {showReload && (
                    <Segment basic>
                        <Button
                            onClick={() => {
                                console.log(window.location.hash);
                                const pathname = window.location.hash.split('?')[0];

                                window.location.hash = pathname;
                                window.location.reload.bind(window.location);
                                window.location.reload();
                            }}
                            content={translate('form.reloadPageButton')}
                            icon="refresh"
                            color="grey"
                            floated="right"
                        />
                    </Segment>
                )}
                <CorrelationIds />
            </Message.Content>
        </Message>
    );
};


ErrorMessage.defaultProps = defaultProps;

export default ErrorMessage;