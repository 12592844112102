import React, { Component } from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';

import DataTable from '@/components/DataTable';
import { NewsletterTemplate, ListState } from '@/types';
import { LocalizeContextProps } from 'react-localize-redux';
import { RouteComponentProps } from 'react-router-dom';

const messageTypeRenderer = (row: NewsletterTemplate) => (
    <Icon name={row.SendMethodId === 1 ? 'envelope' : 'mobile alternate'} />
);

interface Props extends LocalizeContextProps, RouteComponentProps {
    templates: ListState<NewsletterTemplate>;
    pageSize: number;
}

class NewsletterLog extends Component<Props> {
    render() {
        const { templates, translate } = this.props;
        const { total } = templates;

        const columns = [
            { name: '', renderer: messageTypeRenderer, collapsing: true },
            {
                name: translate('newsletterTemplates.Heading'),
                attribute: 'Heading',
            },
        ];

        const rowActions = [
            {
                name: this.props.translate('common.edit'),
                icon: 'edit' as SemanticICONS,
                onAction: (newsletterTemplate: NewsletterTemplate) => {
                    this.props.history.push(`/newsletters/templates/${newsletterTemplate.Id}`);
                },
            },
        ];

        return (
            <DataTable
                loading={templates.isLoading}
                data={templates.data}
                columns={columns}
                actions={rowActions}
                pageSize={this.props.pageSize}
                total={total}
            />
        );
    }
}

export default NewsletterLog;
