import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import { change } from 'redux-form';
import { AxiosResponse } from 'axios';

export function* uploadImage({ payload }: AnyAction) {
    try {
        const data = new FormData();
        data.append('FolderType', payload.FolderType);
        data.append('file', payload.file);

        const response: AxiosResponse = yield apiClient.post('/images', data);

        if (payload.form && payload.field && response.data.FilePath) {
            yield put(change(payload.form, payload.field, response.data.FilePath));
        }

        if (payload.onUploadSuccess) {
            payload.onUploadSuccess(response.data.FilePath);
        }

        yield put(actions.UPLOAD_IMAGE.success(response.data));
    } catch (error) {
        console.error(error);
        yield put(actions.UPLOAD_IMAGE.failure(error));
    }
}
