import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import convertErrorToFormError from '@/utils/convertErrorToFormError';
import { AxiosResponse } from 'axios';

export function* verify({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post(`/users/${payload.Id}/verify`, payload);

        yield put(actions.FETCH_VERIFY_EMAIL.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_VERIFY_EMAIL.failure(convertErrorToFormError(error)));
    }
}
