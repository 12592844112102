import { put, call, select, take, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getFormValues } from 'redux-form';
import { startOfWeek, endOfWeek } from '@/utils/datetime';
import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { confirmSaga } from './confirm';
import { AnyAction } from 'redux';
import { sortRecurringSchedules, sortDateSchedules } from '@/utils/sortSchedules';
import { compareAsc } from 'date-fns';
import { RecurringSchedule } from '@/types';
import Notifications from 'react-notification-system-redux';
import { getTranslate } from 'react-localize-redux';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';
import { flatten } from 'lodash';

export function* recurringSchedules({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/schedules/recurring', {
            params: {
                Active: true,
                IncludeRecurringDays: true,
                IncludeConnectedResources: true,
                IncludeScheduleDates: true,
                IncludeExceptions: true,
                ...payload,
            },
        });

        yield put({
            type: actions.FETCH_RECURRING_SCHEDULES.SUCCESS,
            payload: {
                ...response.data,
                Results: response.data.Results.map((result: any) => ({
                    ...result,
                    DaysOfWeek: result.DaysOfWeek.map((day: any) => day.DayOfWeekId),
                })).sort((a: RecurringSchedule, b: RecurringSchedule) => {
                    compareAsc(new Date(a.CreatedDate), new Date(b.CreatedDate));
                }),
            },
        });
    } catch (error) {
        yield put({ type: actions.FETCH_RECURRING_SCHEDULES.FAILURE, payload: error });
    }
}

export function* recurringSchedulesIntervals({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/schedules/recurring/intervals', {
            params: {
                ...payload,
            },
        });

        yield put({
            type: actions.FETCH_RECURRING_SCHEDULES_INTERVALS.SUCCESS,
            payload: {
                ...response.data,
                Results: response.data,
            },
        });
    } catch (error) {
        yield put({ type: actions.FETCH_RECURRING_SCHEDULES_INTERVALS.FAILURE, payload: error });
    }
}

export function* dateSchedules({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/schedules/date', {
            params: {
                Active: true,
                IncludeConnectedResources: true,
                IncludeScheduleDates: true,
                ...payload,
            },
        });

        yield put(
            actions.FETCH_DATE_SCHEDULES.success({
                ...response.data,
                Results: response.data.Results.sort(sortDateSchedules),
            })
        );
    } catch (error) {
        yield put(actions.FETCH_DATE_SCHEDULES.failure(error));
    }
}

const transformRecurringSchedule = (formValues: any) => {
    const newFormValues = {
        ...formValues,
        Resources: formValues.validForAllResources
            ? []
            : formValues.Resources.filter((resource: any) => resource.selected),
        Services: formValues.Services.filter((service: any) => service.selected),
    };

    if (formValues.type === 'fixed') {
        return {
            ...newFormValues,
            StartTime: undefined,
            EndTime: undefined,
            DaysOfWeek: undefined,
            ScheduleDates: Object.keys(formValues.groupedScheduleDates).reduce(
                (acc: any, key: string) => [...acc, ...formValues.groupedScheduleDates[key]],
                []
            ),
        };
    }
    // if formValues.type === 'rolling'
    return { ...newFormValues, ScheduleDates: undefined };
};

export function* createRecurringSchedule({ payload }: AnyAction) {
    const localizeState: RootState['localize'] = yield select((state) => state.localize);
    const {location } = yield select(state => state.router);
    const translate = getTranslate(localizeState);
    
    try {
        if(Object.keys(payload.groupedScheduleDates).length === 0 && payload.type === 'fixed') {
            yield put(actions.CREATE_RECURRING_SCHEDULE.failure());

            // @ts-ignore
            return yield put(Notifications.show({
                title: translate('schedules.fixedSetTimeTitle') as string,
                message: translate('schedules.fixedSetTimeMessage') as string
            }, 'warning'));
        }
        const response: AxiosResponse = yield apiClient.post(
            '/schedules/recurring',
            transformRecurringSchedule(payload)
        );

        yield put(actions.CREATE_RECURRING_SCHEDULE.success(response.data));
        if(location.pathname === '/schedules/recurring/new') {
            yield delay(300);
            yield put(push('/schedules'));
        }
    } catch (error) {
        yield put(actions.CREATE_RECURRING_SCHEDULE.failure(throwSubmissionError(error)));
    }
}

export function* editRecurringSchedule({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(
            `/schedules/recurring/${payload.Id}`,
            transformRecurringSchedule(payload)
        );

        yield put(actions.EDIT_RECURRING_SCHEDULE.success(response));
    } catch (error) {
        yield put(actions.EDIT_RECURRING_SCHEDULE.failure(throwSubmissionError(error)));
    }
}

export function* deleteRecurringSchedule({ payload, meta: { confirmationMessage } }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga, confirmationMessage);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/schedules/recurring/${payload.Id}`);

            yield put(actions.DELETE_RECURRING_SCHEDULE.success(response));
            yield put(push('/schedules'));
        } catch (error) {
            yield put(actions.DELETE_RECURRING_SCHEDULE.failure(error));
        }
    }
}

export function* testRecurringSchedule({ payload }: AnyAction) {
    try {
        const { navigationDate } = payload;
        const values: {[key in string]: any} = yield select(getFormValues('recurringSchedule'));
        const { duration } = yield select((state) => state.schedulePreview.ui);
        const response: AxiosResponse = yield apiClient.post(`/schedules/recurring/test`, {
            Duration: duration,
            ...transformRecurringSchedule(values),
            From: startOfWeek(navigationDate),
            To: endOfWeek(navigationDate),
        });

        yield put(actions.FETCH_RECURRING_SCHEDULE_TIMES.success({ Results: flatten(response.data.map((t: any) => t.Times)) }));
    } catch (error) {
        yield put(actions.FETCH_RECURRING_SCHEDULE_TIMES.failure(error));
    }
}

const transformDateSchedule = (formValues: any) => {
    if (formValues.ScheduleDates) {
        return {
            ...formValues,
            Resources: formValues.validForAllResources
                ? []
                : formValues.Resources.filter((resource: any) => resource.selected),
            Services: formValues.Services.filter((service: any) => service.selected),
        };
    }
    return formValues;
};

export function* createDateSchedule({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/schedules/date', transformDateSchedule(payload));

        yield put(actions.CREATE_DATE_SCHEDULE.success(response.data));
    } catch (error) {
        yield put(actions.CREATE_DATE_SCHEDULE.failure(throwSubmissionError(error)));
    }
}

export function* editDateSchedule({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put(
            `/schedules/date/${payload.Id}`,
            transformDateSchedule(payload)
        );

        yield put(actions.EDIT_DATE_SCHEDULE.success(response));
    } catch (error) {
        yield put(actions.EDIT_DATE_SCHEDULE.failure(throwSubmissionError(error)));
    }
}

export function* deleteDateSchedule({ payload, meta: { confirmationMessage } }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga, confirmationMessage);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/schedules/date/${payload.Id}`);

            yield put(actions.DELETE_DATE_SCHEDULE.success(response));
            yield put(push('/schedules'));
        } catch (error) {
            yield put(actions.DELETE_DATE_SCHEDULE.failure(error));
        }
    }
}

export function* testDateSchedule({ payload }: AnyAction) {
    try {
        const { navigationDate } = payload;
        const values: {[key in string]: any} = yield select(getFormValues('dateSchedule'));

        const response: AxiosResponse = yield apiClient.post(`/schedules/date/test`, {
            Duration: values.TimeInterval,
            ...transformDateSchedule(values),
            From: startOfWeek(navigationDate),
            To: endOfWeek(navigationDate),
        });

        yield put(actions.FETCH_DATE_SCHEDULE_TIMES.success({ Results: response.data.Times }));
    } catch (error) {
        yield put(actions.FETCH_DATE_SCHEDULE_TIMES.failure(error));
    }
}
