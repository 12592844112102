import { Reducer, AnyAction } from 'redux';
import * as actions from '../actions';

interface ImagesState {
    isLoading: boolean;
    imageUrl?: string;
    modalOpen: boolean;
}

const initialState: ImagesState = {
    isLoading: false,
    imageUrl: undefined,
    modalOpen: false,
};

const reducer: Reducer<ImagesState, AnyAction> = (state = initialState, { type, payload }) => {
    switch (type) {
        case actions.CREATE_CUSTOMER_COMMENT.SUCCESS:
        case actions.CREATE_CUSTOMER_COMMENT.FAILURE:
            return initialState;
        case actions.UPLOAD_IMAGE.REQUEST:
            return { ...state, isLoading: true };
        case actions.UPLOAD_IMAGE.FAILURE:
            return { ...state, isLoading: false, imageUrl: undefined };
        case actions.UPLOAD_IMAGE.SUCCESS:
            return { ...state, isLoading: false, imageUrl: payload.FilePath };
        case actions.OPEN_IMAGE_UPLOAD_MODAL:
            return { ...state, imageUrl: undefined, modalOpen: true };
        case actions.CLOSE_IMAGE_UPLOAD_MODAL:
            return initialState;
        case actions.RESET_IMAGE_UPLOADER:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
