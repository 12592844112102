import { AnyAction } from 'redux';
import { put, select } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient } from '@/utils';
import { getCompanyId } from '@/store/reducers/newAuth';
import { throwSubmissionError } from '@/utils';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { push } from 'connected-react-router';
import { ApplicationState } from '@/store';

export function* fetchCompany({ payload }: AnyAction) {
    try {
        const Id: string = yield select(getCompanyId);
        const response: AxiosResponse = yield apiClient.get('/companies', { params: { Id } });

        yield put(actions.FETCH_COMPANY.success(response.data.Results[0]));
    } catch (error) {
        yield put(actions.FETCH_COMPANY.failure(error));
    }
}

export function* editCompany({ payload }: AnyAction) {
    try {
        const location: Location = yield select((s: ApplicationState) => s.router.location);
        const response: AxiosResponse = yield apiClient.put(`/companies/${payload.Id}`, payload);

        yield put(actions.EDIT_COMPANY.success(response.data));
        
        const query = qs.parse(location.search.slice(1));

        if (query.afterSuccess) {
            yield put(push(query.afterSuccess as string))
        }
    } catch (error) {
        yield put(actions.EDIT_COMPANY.failure(throwSubmissionError(error)));
    }
}

export function* fetchCompanyTypes({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/companies/types', payload);

        yield put(actions.FETCH_COMPANY_TYPES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_COMPANY_TYPES.failure(error));
    }
}

export function* fetchCompanyCategories({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/categories', payload);

        yield put(actions.FETCH_COMPANY_CATEGORIES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_COMPANY_CATEGORIES.failure(error));
    }
}

export function* sendForApproval({ payload }: AnyAction) {
    try {
        const Id: string = yield select(getCompanyId);
        const response: AxiosResponse = yield apiClient.put(`/companies/${Id}/sendforapproval`, payload);

        yield put(actions.FETCH_COMPANY.request());

        yield put(actions.SEND_FOR_APPROVAL.success(response.data));
    } catch (error) {
        yield put(actions.SEND_FOR_APPROVAL.failure(error));
    }
}
