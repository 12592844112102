import { createListLoadingReducer, listInitialState } from './utils';
import reduceReducers from 'reduce-reducers';
import * as actions from '../actions';
import { Reducer, AnyAction } from 'redux';
import { ListState, TimeException } from '@/types';

interface UIState {
    showBookingsConflictModal: boolean;
    conflictData: any;
}

interface TimeExceptionsState extends ListState<TimeException>, UIState {}

const uiInitialState = {
    showBookingsConflictModal: false,
    conflictData: null,
};

const initialState = {
    ...listInitialState,
    ...uiInitialState,
    ...{
        isExporting: false
    }
};

export const dataReducer = (createListLoadingReducer('TIME_EXCEPTIONS') as unknown) as Reducer<
    TimeExceptionsState
>;

export const uiReducer: Reducer<TimeExceptionsState, AnyAction> = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case actions.TIME_EXCEPTION_BOOKINGS_CONFLICT:
            return { ...state, showBookingsConflictModal: true, conflictData: payload };
        case actions.CLOSE_TIME_EXCEPTION_BOOKINGS_CONFLICT_MODAL:
            return { ...state, showBookingsConflictModal: false, conflictData: null };
        default:
            return state;
    }
};

const reducer: Reducer<TimeExceptionsState, AnyAction> = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case actions.EXPORT_TIME_EXCEPTIONS.REQUEST:
            return {
                ...state,
                isExporting: true
            };
        case actions.EXPORT_TIME_EXCEPTIONS.SUCCESS:
            return {
                ...state,
                isExporting: false
            };
        default:
            return state;
    }
};

export default (reduceReducers(initialState, dataReducer, uiReducer, reducer) as unknown) as Reducer<
    TimeExceptionsState
>;
