import React from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { connect, ConnectedProps } from 'react-redux';

import Loader from '@/components/Loader';
import * as actions from '@/store/actions';

import ResourceGroupForm from './ResourceGroupForm';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '@/store';
import { withRouter } from 'react-router-dom';

interface Props extends RouteComponentProps<{ id: string }>, LocalizeContextProps, ConnectedProps<typeof connector> {
    formProps: any
}

const CompanyLoader: React.FC<Props> = (props) => {
    const { resourceTypes, formProps, ...restProps } = props;

    return (
        <>
            <Loader
                requirements={[
                    {
                        action: actions.FETCH_RESOURCE_TYPES.request(),
                        data: resourceTypes,
                    },
                ]}
            >
                <ResourceGroupForm {...formProps} {...restProps} />
            </Loader>
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    const { resourceTypes } = state;

    return {
        resourceTypes,
    };
};

const connector = connect(mapStateToProps)

export default compose(
    connector,
    withLocalize,
    withRouter
)(CompanyLoader) as React.FC;
