import { createSelector } from 'reselect';
import reduceReducers from 'reduce-reducers';
import { createListLoadingReducer } from './utils';
import * as actions from '../actions';


export default reduceReducers<any>(
    {},
    createListLoadingReducer('PROMO_CODES_TRANSACTIONS'),
    (state, action) => {
        switch (action.type) {
 
            case actions.EXPORT_PROMO_CODES.REQUEST: {
                return {
                    ...state,
                    exporting: true
                }
            }
            case actions.EXPORT_PROMO_CODES.SUCCESS: {
                return {
                    ...state,
                    exporting: false
                }
            }
            case actions.EXPORT_PROMO_CODES.FAILURE: {
                return {
                    ...state,
                    exporting: false
                }
            }
            default:
                return state;
        }
    }
);
