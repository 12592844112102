import {
    createSlice,
    createListenerMiddleware,
    isAnyOf,
    TypedStartListening,
    isRejected,
} from '@reduxjs/toolkit';
import * as actions from '../actions';
import { ApplicationDispatch, ApplicationState } from '@/store';

export const correlationIdListener = createListenerMiddleware();

export type EaccountingStartListening = TypedStartListening<ApplicationState, ApplicationDispatch>;

const startCorrelationIdListening = correlationIdListener.startListening as EaccountingStartListening;

export interface InitialState {
    entity: {
        text: string;
        correlationId?: string;
        endpoint?: { url: string; method: string };
        responseData?: any;
        requestData?: any;
    }[];
    modal: boolean;
}

const correlationIdSlice = createSlice({
    name: 'correlationId',
    initialState: {
        entity: [],
        modal: false,
    } as InitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(actions.addBlockingError.match, (state, { payload }) => {
            state.entity.push({ text: payload.text });
            state.modal = true;
        });

        builder.addMatcher(actions.addInformativeError.match, (state, { payload }) => {
            state.entity.push({ text: payload.text, correlationId: payload.correlationId });
        });

        builder.addMatcher(actions.addNonBlockingError.match, (state, { payload }) => {
            const text = payload.responseData?.ResponseStatus?.Message ||  payload.text;
            state.entity.push({
                text,
                correlationId: payload.correlationId,
                endpoint: payload.endpoint,
                requestData: payload.requestData,
                responseData: payload.responseData,
            });
        });

        builder.addMatcher(actions.hideCorrelationIdModal.match, (state) => {
            state.entity = [];
            state.modal = false;
        });
    },
});

startCorrelationIdListening({
    matcher: isAnyOf(
        isRejected
    ),
    effect: async (action, api) => {
        if (action?.payload?.correlationId) {
            api.dispatch(
                actions.addNonBlockingError({
                    message: action.error.message,
                    method: action.payload.method,
                    url: action.payload.url,
                    requestData: action.payload.requestData,
                    responseData: action.payload.data,
                    correlationId: action.payload.correlationId,
                })
            );
        }
    },
});

export default correlationIdSlice;
