import { RecurringSchedule, DateSchedule } from '@/types';

export const sortRecurringSchedules = (a: RecurringSchedule, b: RecurringSchedule) => {
    if (a.ScheduleDates?.length > 0 && b.ScheduleDates?.length > 0) {
        const aFirstDate = new Date(a.ScheduleDates[0]?.Date);
        const bFirstDate = new Date(b.ScheduleDates[0]?.Date);

        if (aFirstDate.getTime() < bFirstDate.getTime()) {
            return -1;
        } else if (aFirstDate.getTime() > bFirstDate.getTime()) {
            return 1;
        }

        return 0;
    }

    if (a.DaysOfWeek[0] < b.DaysOfWeek[0]) {
        return -1;
    } else if (a.DaysOfWeek[0] > b.DaysOfWeek[0]) {
        return 1;
    } else {
        if (a.StartTime < b.StartTime) {
            return -1;
        } else if (a.StartTime > b.StartTime) {
            return 1;
        }
    }

    return 0;
};

export const sortDateSchedules = (a: DateSchedule, b: DateSchedule) => {
    if (a.ScheduleDates?.length > 0 && b.ScheduleDates?.length > 0) {
        const aFirstDate = new Date(a.ScheduleDates[0]?.From);
        const bFirstDate = new Date(b.ScheduleDates[0]?.From);

        if (aFirstDate.getTime() < bFirstDate.getTime()) {
            return -1;
        } else if (aFirstDate.getTime() > bFirstDate.getTime()) {
            return 1;
        }
    }
    return 0;
};
