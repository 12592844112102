import { combineReducers, Reducer, AnyAction } from 'redux';
import { createListLoadingReducer, createLoadingReducer } from './utils';
import * as actions from '../actions';

export const invoices = createListLoadingReducer('INVOICES');
export const methods = createListLoadingReducer('BILLING_METHODS');
export const token = createLoadingReducer('BILLING_TOKEN');
export const settings = createLoadingReducer('BILLING_SETTINGS');

const exportInvoice: Reducer<any, AnyAction> = (
    state = { invoiceId: null, isLoading: false },
    { type, payload }
) => {
    switch (type) {
        case actions.FETCH_EXPORT_INVOICE.REQUEST:
            return { invoiceId: payload.Id, isLoading: true };
        case actions.FETCH_EXPORT_INVOICE.FAILURE:
        case actions.FETCH_EXPORT_INVOICE.SUCCESS:
            return { invoiceId: null, isLoading: false };
        default:
            return state;
    }
};

const modalInitialState = {
    show: false,
    data: {},
    status: null,
};

const modal: Reducer<any, AnyAction> = (state = modalInitialState, action) => {
    switch (action.type) {
        case actions.SHOW_BILLING_INFO_MODAL:
            return {
                ...state,
                show: true,
                data: action.payload,
            };
        case actions.HIDE_BILLING_INFO_MODAL:
            return {
                ...state,
                show: false,
                data: modalInitialState.data
            };
        default:
            return state;
    }
};

export default combineReducers({
    invoices,
    methods,
    token,
    exportInvoice,
    settings,
    modal
});
